<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3">
  <div class="d-block mb-4 mb-md-0">
    <h5 class="mb-0">
      <span class="sidebar-icon material-icons-outlined">
        contacts
      </span>
      <span class="heading-text" style="font-size: 20px !important;">Calender</span>
    </h5>
    <p style="font-size: 13px !important;">All your events and meetings.</p>
  </div>
  <div class="btn-toolbar mb-2 mb-md-0">
  </div>
</div>

<div class="" style="border-radius: 0.5rem !important;">
  <div class="p-0 card-body">
    <full-calendar #fullcalendar [options]="calendarOptions"></full-calendar>
  </div>
</div>


<!-- Add event modal -->
<ng-template #addEventModal let-modal>
  <div class="modal-body">
    <form #addNewEventForm="ngForm" id="addNewEventForm">
      <div class="row">
        <div class="col-md-8">
          <div class="mb-3">
            <label for="eventTitle">Event title</label>
            <input type="text" class=" input_custom_design form-control" id="eventTitle" name="eventTitle" ngModel
              required>
          </div>
        </div>
        <div class="col-md-4">
          <div class=" mb-3">
            <label for="eventColor">Event color</label>
            <select name="eventColor" class="input_custom_design form-control" [(ngModel)]='eventColor'
              style="height: auto !important;" [class]='eventColor' ngModel required>
              <option class="bg-danger" value="bg-danger">Red</option>
              <option class="bg-warning" value="bg-warning">Yellow</option>
              <option class="bg-info" value="bg-info">Blue</option>
              <option class="bg-purple" value="bg-purple">Purple</option>
              <option class="bg-success" value="bg-success">Green</option>
              <option class="bg-white" value="bg-white">White</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="mb-4">
            <label for="dateStart">Select start date</label>
            <div class="input-group">
              <input class="input_custom_design form-control" id="dateStart" type="date" name="dateStart"
                [(ngModel)]='dateStartAdd' ngModel placeholder="dd/mm/yyyy" required>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="mb-2">
            <label for="dateEnd">Select end date</label>
            <div class="input-group">
              <input class="input_custom_design form-control" id="dateEnd" type="date" name="dateEnd" ngModel
                [(ngModel)]='dateEndAdd' placeholder="dd/mm/yyyy" required>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-gray-300 ms-auto" (click)="modal.dismiss('Cross click')">Close</button>
    <button type="submit" class="btn btn-dark" id="addNewEvent" [disabled]="!addNewEventForm.valid"
      (click)="modal.close(addNewEventForm.value)">Add new
      event</button>
  </div>
</ng-template>

<!-- Edit event modal -->
<ng-template #editEventModal let-modal>
  <div class="modal-body">
    <form #editEventForm="ngForm" id="editEventForm">
      <div class="row">
        <div class="col-md-8">
          <div class="mb-4">
            <label for="eventTitleEdit">Event title</label>
            <input type="text" class="input_custom_design form-control" id="eventTitleEdit" [(ngModel)]='eventTitleEdit'
              name="eventTitleEdit" ngModel required>
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-4">
            <label for="eventColor">Event color</label>
            <select name="eventColor" style="height: auto !important;" class="input_custom_design form-control"
              [(ngModel)]='eventColor' [class]='eventColor' ngModel required>
              <option class="bg-danger" value="bg-danger">Red</option>
              <option class="bg-warning" value="bg-warning">Yellow</option>
              <option class="bg-info" value="bg-info">Blue</option>
              <option class="bg-purple" value="bg-purple">Purple</option>
              <option class="bg-success" value="bg-success">Green</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="">
            <label for="dateStartEdit">Select start date</label>
            <div class="input-group">
              <input data-datepicker="" class="input_custom_design form-control" id="dateStartEdit" type="date"
                [(ngModel)]='dateStartEdit' name="dateStartEdit" ngModel placeholder="dd/mm/yyyy" required>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="mb-2">
            <label for="dateEndEdit">Select end date</label>
            <div class="input-group">
              <input data-datepicker="" class="input_custom_design form-control" id="dateEndEdit" type="date"
                [(ngModel)]='dateEndEdit' name="dateEndEdit" ngModel placeholder="dd/mm/yyyy" required>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-gray-300 ms-auto" (click)="modal.dismiss('Cross click')">Close</button>
    <button type="submit" class="btn btn-danger" id="deleteEvent" (click)="modal.close('DELETE')">Delete
      event</button>
    <button type="submit" class="btn btn-dark me-2" id="editEvent" (click)="modal.close(editEventForm.value)"
      [disabled]="!editEventForm.valid">Update
      event</button>
  </div>
</ng-template>