import { Component, OnInit } from '@angular/core';
import { Contactdoc } from 'src/app/shared/models/contactdoc.model';
import { Contacts } from 'src/app/shared/models/contacts.model';
import { CommService } from 'src/app/shared/services/comm.service';
import { ContactsService } from 'src/app/shared/services/contacts.service';
import { NotyfService } from 'src/app/shared/services/notyf.service';
import { SwalService } from 'src/app/shared/services/swal.service';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  public contacts: Contactdoc[] = [];
  public contactFilter = '';
  public groupFilter = '';
  public startIndex = 0;
  public pageEntry = 1;
  public activePage = 1;
  public numberOfEntries = 10;
  public filteredArrayLength = 0;
  public contactModalButton = 'Add contact';

  // Add or Edit Contact data
  public docId = '';
  public name = '';
  public phoneNumber = '';
  public email = '';
  public group = '';
  public address = '';

  constructor(private contactService: ContactsService,
    private notyf: NotyfService,
    private comm: CommService,
    private swalService: SwalService) { }

  ngOnInit(): void {
    this.contactService.getContacts().subscribe(
      (value) => {
        this.contacts = value;
      }
    );
    this.comm.contactFilteredLength.subscribe((value) => {
      this.filteredArrayLength = value;
      if (this.numberOfEntries >= value) {
        this.startIndex = 0;
        this.pageEntry = 1;
        this.activePage = 1;
      }
    });
  }

  displayEndIndexValue(): number {
    const x = this.startIndex + this.numberOfEntries;
    return (this.filteredArrayLength <= (x)) ? this.filteredArrayLength : x;
  }

  nextButtonDisable(): boolean {
    return (this.filteredArrayLength <= (this.startIndex + this.numberOfEntries)) ? true : false;
  }

  prevButtonClicked(): void {
    this.startIndex -= this.numberOfEntries;
    this.activePage--;
    if ((this.pageEntry - 1) >= 1) {
      this.pageEntry--;
    }
  }

  nextButtonClicked(): void {
    this.startIndex += this.numberOfEntries;
    if (this.activePage == (this.pageEntry + 4)) {
      this.pageEntry++;
    }
    this.activePage++;
  }

  pageEntryDisable(value): boolean {
    return (this.filteredArrayLength <= (this.numberOfEntries * value)) ? false : true;
  }

  pageEntryClicked(value): void {
    this.startIndex = this.numberOfEntries * (value - 1);
    this.activePage = value;
    if (this.activePage == (this.pageEntry + 4)) {
      this.pageEntry++;
    } else if (this.activePage == (this.pageEntry + 1) && this.activePage > 2) {
      this.pageEntry--;
    }
  }

  openNewContactModel(): void {
    this.docId = '';
    this.name = '';
    this.phoneNumber = '';
    this.email = '';
    this.group = '';
    this.address = '';
    this.contactModalButton = 'Add new contact';
  }

  openEditContactModel(val: Contacts, docId): void {
    this.docId = docId;
    this.name = val.name;
    this.phoneNumber = val.phoneNumber;
    this.email = val.email;
    this.group = val.group;
    this.address = val.address;
    this.contactModalButton = 'Edit contact data';
  }

  deleteContact(docId): void {
    this.swalService.foocontactdeleteswal(
      (val: boolean) => {
        this.contactService.removeContact(docId);
      }
    );
  }

  addOrEditContact(): void {

    this.contactService.updateContact(this.docId, {
      userId: '',
      address: this.address,
      email: this.email,
      group: this.group,
      name: this.name,
      phoneNumber: this.phoneNumber
    })
      .then(() => {
        if (this.contactModalButton == 'Add contact') {
          this.notyf.success('Contact created');
        }
        else {
          this.notyf.success('Contact updated');
        }
      })
      .catch((error) => {
        this.notyf.error(error);
      });
  }

  setContactAsStarred(val: Contactdoc): void {
    const temp = val;
    temp.contactData.group = 'Starred';
    this.contactService.updateContact(temp.docId, temp.contactData)
      .then(() => {
        this.notyf.info('Contact ' + val.contactData.name + ' is starred');
      })
      .catch((error) => {
        this.notyf.error(error);
      });
  }

}
