import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgFilter'
})
export class ImgFilterPipe implements PipeTransform {

  transform(value: any[]): any[] {
    return value.filter(
      (data) => {
        if (data.status != 'hidden'){
          return data;
        }
      }
    );
  }

}
