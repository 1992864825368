import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomFont } from 'src/app/shared/models/custom-font.model';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
import { FontManagerService } from 'src/app/shared/services/font-manager.service';
import { PostTypesManagerService } from 'src/app/shared/services/post-types-manager.service';
import { SettingsService } from '../../shared/services/settings.service';
@Component({
  selector: 'app-template-model',
  templateUrl: './template-model.component.html',
  styleUrls: ['./template-model.component.scss'],
})
export class TemplateModelComponent implements OnInit {
  @ViewChild('addFontModal') addFontModal: TemplateRef<any>;
  private modalRef: NgbModalRef = null;

  private postTypes: CustomPostType[] = [];
  public fontsData: CustomFont[] = [];

  url: string;
  thumbnail: string;

  @ViewChild('photoCanvas') canvasArtboard: ElementRef;
  canvasWidth = 0;
  canvasHeight = 0;
  showText = false;
  showImage = false;

  selectedImage: ImageData;
  selectedText: TextData;

  images: any[] = [];
  texts: any[] = [];
  settings: any[] = [];

  uniqueId: string;
  addType: string;
  type: string;
  text: string;
  label: string;
  tColor: string;
  bColor: string;

  isTemplate: boolean;
  // isBold = false;
  isUpper = false;
  isLower = false;
  isItalic = false;
  isOverLined = undefined;
  isStrikeThrough = undefined;
  isUnderLined = undefined;
  letterSpacing = undefined;
  lineHeight = undefined;
  isEditing: boolean;

  fontName: any;
  // fontUrl: string;
  // fontFamily: string;
  alignIndex: number;
  sizeIndex: number;
  size: number;
  width: number;
  height: number;
  left: number;
  top: number;
  currentIndex: number;
  currentUid: string;

  imageUrl: string;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private dbService: SettingsService,
    private postTypesManager: PostTypesManagerService,
    private fontsManager: FontManagerService
  ) {
    this.uniqueId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.postTypesManager.getPostTypes().subscribe((value) => {
      this.postTypes = value;
      this.loadData();
    });

    this.fontsManager.getFonts().subscribe((value) => {
      this.fontsData = value;
      for (const item of this.fontsData) {
        this.addFont(item.Url);
      }
    });
  }
  addTemplate(type) {
    this.isTemplate = true;
    this.addType = type;
    this.isEditing = false;
  }
  editSettings(item, index, type) {
    // this.uniqueId=item.uniqueId;
    this.type = item.type;
    this.text = item.text;
    this.label = item.label;
    // this.fontUrl = item.fontUrl ? item.fontUrl : '';
    // this.fontFamily = item.fontFamily ? item.fontFamily : '';
    this.alignIndex = item.alignIndex;
    this.sizeIndex = item.sizeIndex;
    // this.isBold = item.isBold;
    this.fontName = item.fontName ? item.fontName : '';
    this.isUpper = item.textT == 'uppercase' ? true : false;
    this.isLower = item.textT == 'lowercase' ? true : false;
    this.isItalic = item.isItalic;
    this.isOverLined = item.isOverLined ? item.isOverLined : false;
    this.isStrikeThrough = item.isStrikeThrough ? item.isStrikeThrough : false;
    this.isUnderLined = item.isUnderLined ? item.isUnderLined : false;
    this.letterSpacing = item.letterSpacing ? item.letterSpacing : '';
    this.lineHeight = item.lineHeight ? item.lineHeight : '';
    this.tColor = item.tColor;
    this.bColor = item.bColor;
    this.size = this.calcPxToPt(item.size);
    this.width = item.width;
    this.height = item.height;
    this.left = item.left;
    this.top = item.top;
    this.currentIndex = index;
    this.currentUid = item.uniqueId;
    this.isEditing = true;
    this.isTemplate = true;
    this.addType = type;
    if (type == 'Image') {
      this.imageUrl = item.selectedFile;
    }
  }
  duplicateSettings(item, index, type) {
    // this.uniqueId=item.uniqueId;
    this.type = item.type;
    this.text = item.text;
    this.label = item.label;
    // this.fontUrl = item.fontUrl ? item.fontUrl : '';
    // this.fontFamily = item.fontFamily ? item.fontFamily : '';
    this.alignIndex = item.alignIndex;
    this.sizeIndex = item.sizeIndex;
    // this.isBold = item.isBold;
    this.fontName = item.fontName ? item.fontName : '';
    this.isUpper = item.textT == 'uppercase' ? true : false;
    this.isLower = item.textT == 'lowercase' ? true : false;
    this.isItalic = item.isItalic;
    this.isOverLined = item.isOverLined ? item.isOverLined : false;
    this.isStrikeThrough = item.isStrikeThrough ? item.isStrikeThrough : false;
    this.isUnderLined = item.isUnderLined ? item.isUnderLined : false;
    this.letterSpacing = item.letterSpacing ? item.letterSpacing : '';
    this.lineHeight = item.lineHeight ? item.lineHeight : '';
    this.tColor = item.tColor;
    this.bColor = item.bColor;
    this.size = this.calcPxToPt(item.size);
    this.width = item.width;
    this.height = item.height;
    this.left = item.left;
    this.top = item.top;
    this.currentIndex = index;
    this.currentUid = item.uniqueId;
    this.isEditing = false;
    this.isTemplate = true;
    this.addType = type;
    if (type == 'Image') {
      this.imageUrl = item.selectedFile;
    }
  }
  deleteSettings(index, type) {
    const data = {
      isDeleted: true,
      index,
      updatedDate: Date.now(),
    };
    this.dbService.mapModels(data, this.uniqueId, type, true);
    this.loadData();
  }
  clearImageDetailsField() {
    this.isTemplate = false;
    this.type = '';
    this.text = '';
    this.label = '';
    this.fontName = '';
    // this.fontUrl = '';
    // this.fontFamily = '';
    this.alignIndex = 0;
    this.sizeIndex = 0;
    // this.isBold = true;
    this.isUpper = false;
    this.isItalic = false;
    this.isOverLined = false;
    this.isStrikeThrough = false;
    this.isUnderLined = false;
    this.letterSpacing = '';
    this.lineHeight = '';
    this.tColor = '';
    this.bColor = '';
    this.size = 0;
    this.width = 0;
    this.height = 0;
    this.left = 0;
    this.top = 0;
    this.isEditing = false;
    this.currentIndex = 0;
    this.currentUid = '';
    this.showImage = false;
    this.showText = false;
  }
  saveTemplate() {
    /**
     * `x` will contain the text-transform value
     *
     * possible values `none`, `uppercase`, `lowercase`
     */
    let x = 'none';
    if (this.isUpper) {
      x = 'uppercase';
    } else if (this.isLower) {
      x = 'lowercase';
    }
    if (this.addType != 'Image') {
      const idx = this.isEditing
        ? this.currentIndex
        : this.texts
        ? this.texts.length
        : 0;
      const uid = this.isEditing
        ? this.currentUid
        : this.uniqueId + 'txt' + idx;
      const data = {
        uniqueId: uid,
        type: this.type,
        text: this.text,
        label: this.label,
        // fontUrl: this.fontUrl,
        // fontFamily: this.fontFamily,
        fontName: this.fontName,
        colorIndex: 0,
        alignIndex: Number(this.alignIndex),
        sizeIndex: Number(this.sizeIndex),
        // isBold: this.isBold,
        textT: x,
        isItalic: this.isItalic,
        isOverLined: this.isOverLined,
        isStrikeThrough: this.isStrikeThrough,
        isUnderLined: this.isUnderLined,
        letterSpacing: this.letterSpacing,
        lineHeight: this.lineHeight,
        tColor: this.tColor,
        bColor: this.bColor,
        size: this.calcPtToPx(this.size),
        width: this.width,
        height: this.height,
        left: this.left,
        top: this.top,
        index: idx,
        createdDate: Date.now(),
        isSelected: false,
        isDeleted: false,
        updatedDate: this.isEditing ? Date.now() : '',
      };

      this.dbService.mapModels(
        data,
        this.uniqueId,
        this.addType,
        this.isEditing
      );
    } else {
      const idx = this.isEditing
        ? this.currentIndex
        : this.images
        ? this.images.length
        : 0;
      const uid = this.isEditing
        ? this.currentUid
        : this.uniqueId + 'img' + idx;
      const data = {
        uniqueId: uid,
        type: this.type,
        text: this.text,
        width: this.width,
        height: this.height,
        left: this.left,
        top: this.top,
        index: idx,
        createdDate: Date.now(),
        selectedFile:
          this.type !== 'photo'
            ? this.type == 'house'
              ? '../assets/img/house.jpg'
              : this.imageUrl
            : '../assets/img/person.png',
        radius: 0,
        isGraphicHidden: false,
        isSelected: false,
        isDeleted: false,
        updatedDate: '',
      };
      this.dbService.mapModels(
        data,
        this.uniqueId,
        this.addType,
        this.isEditing
      );
    }
    this.clearImageDetailsField();
    this.loadData();
  }
  loadData() {
    this.dbService.getTemplateById(this.uniqueId).then((result) => {
      this.settings = result.payload.val();
      if (this.settings) {
        this.images =
          result.payload.val().Image !== 'undefined'
            ? result.payload.val().Image
            : [];
        this.texts =
          result.payload.val().Text !== 'undefined'
            ? result.payload.val().Text
            : [];
      }
    });
    this.dbService.getSettings().then((result) => {
      const canvasSettings = result.payload.val();
      /* const maxCanvasWidth = 500;

      // Loading canvas
      canvasSettings.selectedImageUniqueId = this.uniqueId;
      const position = canvasSettings.imageSettings.images.filter(
          (x: any) => x.uniqueId == canvasSettings.selectedImageUniqueId)[0].selectedSizeIndex;
      canvasSettings.selectedSizeIndex = position;
      const ctx = this.canvasArtboard.nativeElement.getContext('2d');
      this.canvasHeight = canvasSettings.sizeSettings.sizes[position].Height;
      this.canvasWidth = canvasSettings.sizeSettings.sizes[position].Width; */

      // Finding image
      for (const img of canvasSettings.imageSettings.images) {
        if (img.uniqueId == this.uniqueId) {
          this.url = img.url;
          this.thumbnail = img.thumbnail;

          const ctx = this.canvasArtboard.nativeElement.getContext('2d');
          const ptValue = this.getPostData(img.type);

          this.canvasHeight = ptValue.Height;
          this.canvasWidth = ptValue.Width;

          const image = new Image();
          image.src = this.url;
          image.crossOrigin = 'Anonymous';
          image.onload = () => {
            ctx.drawImage(image, 0, 0, this.canvasWidth, this.canvasHeight);
          };
        }
      }
    });
  }

  getPostData(name: string): CustomPostType {
    for (const item of this.postTypes) {
      if (item.Name == name) {
        return item;
      }
    }
  }

  calcPtToPx(val) {
    return Number(val * 1.3333333);
  }
  calcPxToPt(val) {
    return Math.round(Number(val / 1.3333));
  }

  renderText() {
    this.setPrevText({
      alignIndex: this.alignIndex,
      bColor: this.bColor,
      fontName: this.fontName,
      // fontFamily: this.fontFamily,
      height: this.height,
      // isBold: this.isBold,
      isItalic: this.isItalic,
      isLower: this.isLower,
      isUpper: this.isUpper,
      isOverLined: this.isOverLined ? this.isOverLined : false,
      isStrikeThrough: this.isStrikeThrough ? this.isStrikeThrough : false,
      isUnderLined: this.isUnderLined ? this.isUnderLined : false,
      letterSpacing: this.letterSpacing ? this.letterSpacing : '',
      lineHeight: this.lineHeight ? this.lineHeight : '',
      left: this.left,
      size: this.size,
      // sizeIndex: this.sizeIndex,
      tColor: this.tColor,
      top: this.top,
      width: this.width,
      text: this.text,
      // fontUrl: this.fontUrl,
      label: this.label,
    });
    this.showText = true;
  }

  hideText() {
    this.showText = false;
  }

  renderImage() {
    this.setPrevImage({
      height: this.height,
      left: this.left,
      top: this.top,
      width: this.width,
      selectedFile:
        this.type.trim().toLowerCase() != 'photo'
          ? this.type.trim().toLowerCase() == 'house'
            ? '../assets/img/house.jpg'
            : this.imageUrl
          : '../assets/img/person.png',
    });
    this.showImage = true;
  }

  hideImage() {
    this.showImage = false;
  }

  getFont(data: any): CustomFont {
    for (const f of this.fontsData) {
      if (f.Name == data.fontName) {
        return f;
      }
    }
    return this.fontsData[0];
  }

  addFont(fontUrl: string, callback?) {
    if (fontUrl) {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';
      try {
        document.body.appendChild(link).onload = () => {
          if (callback) {
            callback();
          }
        };
      } catch (error) {
        // //console.log(error);
      }
    }
  }

  setPrevImage(data: ImageData): void {
    this.selectedImage = data;
  }

  setPrevText(data: TextData): void {
    this.selectedText = data;
  }

  getPrevImage(data: any): ImageData {
    return {
      height: data.height,
      left: data.left,
      top: data.top,
      width: data.width,
      selectedFile: data.selectedFile,
    };
  }

  getPreviewText(data: any): TextData | void {
    return {
      alignIndex: data.alignIndex,
      bColor: data.bColor,
      fontName: data.fontName,
      // fontFamily: data.fontFamily,
      height: data.height,
      // isBold: data.isBold,
      isItalic: data.isItalic,
      isLower: data.textT == 'lowercase' ? true : false,
      isUpper: data.textT == 'uppercase' ? true : false,
      left: data.left,
      size: this.calcPxToPt(data.size),
      // sizeIndex: data.sizeIndex,
      tColor: data.tColor,
      top: data.top,
      width: data.width,
      text: data.text,
      // fontUrl: data.fontUrl,
      label: data.label,
      isOverLined: data.isOverLined ? data.isOverLined : false,
      isStrikeThrough: data.isStrikeThrough ? data.isStrikeThrough : false,
      isUnderLined: data.isUnderLined ? data.isUnderLined : false,
      letterSpacing: data.letterSpacing ? data.letterSpacing : '',
      lineHeight: data.lineHeight ? data.lineHeight : '',
    };
  }

  fileUpload(event) {
    const file = event.srcElement.files[0];
    if (
      this.imageUrl &&
      this.imageUrl != '../assets/img/person.png' &&
      this.imageUrl != '../assets/img/house.jpg'
    ) {
      if (
        confirm(
          'This will delete the existing image in the firebase. Do you want to continue?' +
            'If you do, then please SUBMIT after this or it will cause errors'
        )
      ) {
        this.dbService
          .deleteFile(this.imageUrl)
          .then((result) => {
            this.imageUrl = '';
            this.dbService
              .uploadOverlayImage(file)
              .then((value) => {
                this.imageUrl = value;
              })
              .catch((reason) => {
                this.type = 'photo';
                this.imageUrl = '';
                // //console.log(reason);
              });
          })
          .catch((reason) => {
            this.type = 'photo';
            this.imageUrl = '';
            // //console.log(reason);
          });
      }
    } else {
      this.dbService
        .uploadOverlayImage(file)
        .then((value) => {
          this.imageUrl = value;
        })
        .catch((reason) => {
          this.type = 'photo';
          this.imageUrl = '';
          // //console.log(reason);
        });
    }
  }

  editIndex(fType: string): number {
    if (this.isEditing && fType == this.addType) {
      return this.currentIndex;
    }
    return -1;
  }

  openAddFontModal() {
    this.modalRef = this.modalService.open(this.addFontModal);
  }

  fontConfigModeChanged(mode: 'CREATE' | 'EDIT' | 'VIEW'): void {
    this.modalRef.close();
  }

  fontOutputData(value: { docId: number; data: CustomFont }): void {
    this.modalRef.close();

    this.fontsManager
      .addOrUpdateFont(value.docId, value.data)
      .then(() => {
        alert('Font added');
      })
      .catch((reason) => {
        // //console.log(reason);
      });
  }
}
// Data models
export interface TextData {
  left: any;
  top: any;
  height: any;
  width: any;
  bColor: any;
  size: any;
  fontName: any;
  // isBold: any;
  isItalic: any;
  alignIndex: any;
  // sizeIndex: any;
  tColor: any;
  text: any;
  // fontFamily: any;
  isUpper: any;
  isLower: any;
  // fontUrl: any;
  label: any;
  isOverLined: any;
  isStrikeThrough: any;
  isUnderLined: any;
  lineHeight: any;
  letterSpacing: any;
}

export interface ImageData {
  left: any;
  top: any;
  height: any;
  width: any;
  selectedFile: any;
}
