import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';

import { SelectableDirective } from '../shared/directives/selectable.directive';
import { MoveClampedToParentDirective } from '../shared/directives/move-clamped-to-parent.directive';

import { CanvasSelectComponent } from './main/canvas-select/canvas-select.component';
import { EditTextComponent } from './main/edit-text/edit-text.component';
import { ImageSelectComponent } from './main/image-select/image-select.component';
import { LogoSelectComponent } from './main/logo-select/logo-select.component';
import { SizesSelectComponent } from './main/sizes-select/sizes-select.component';
import { TextSelectComponent } from './main/text-select/text-select.component';
import { ControlPanelComponent } from './main/control-panel/control-panel.component';
import { OverlayLogoComponent } from './main/overlay-logo/overlay-logo.component';
import { OverlayTextsComponent } from './main/overlay-texts/overlay-texts.component';
import { EditableTextComponent } from './main/editable-text/editable-text.component';
import { MainComponent } from './main/main.component';
import { ImageFilterService } from '../shared/services/image-filter.service';

import { LeftSideBarComponent } from '../shared/left-side-bar/left-side-bar.component';
import { HeaderComponent } from '../shared/header/header.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { IndexOfPipe } from '../shared/pipes/index-of.pipe';
import { TemplateComponent } from './main/template/template.component';

import { FormFieldsModule } from '../home/main/form-fields/form-fields.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TaskOverviewComponent } from './task-overview/task-overview.component';
import { TaskKanbanComponent } from './task-kanban/task-kanban.component';
import { MessagesComponent } from './messages/messages.component';
import { CalendarComponent } from './calendar/calendar.component';
import { UserListComponent } from './user-list/user-list.component';
import { TaskIndexingPipe } from '../shared/pipes/task-indexing.pipe';
import { ContactIndexingPipe } from '../shared/pipes/contact-indexing.pipe';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { IsDeletedPipe } from '../shared/pipes/is-deleted.pipe';
import { ImgFilterPipe } from '../shared/pipes/img-filter.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { ImageOverlayComponent } from './main/image-overlay/image-overlay.component';
FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CanvaComponent } from './main/canva/canva.component';
import { Canva2Component } from './main/canva2/canva2.component';

@NgModule({
  declarations: [
    MainComponent,
    CanvasSelectComponent,
    EditTextComponent,
    ImageSelectComponent,
    SelectableDirective,
    MoveClampedToParentDirective,
    LogoSelectComponent,
    SizesSelectComponent,
    TextSelectComponent,
    ControlPanelComponent,
    OverlayLogoComponent,
    OverlayTextsComponent,
    EditableTextComponent,
    LeftSideBarComponent,
    HeaderComponent,
    MyProfileComponent,
    IndexOfPipe,
    TaskIndexingPipe,
    ContactIndexingPipe,
    TemplateComponent,
    TaskOverviewComponent,
    TaskKanbanComponent,
    MessagesComponent,
    CalendarComponent,
    UserListComponent,
    IsDeletedPipe,
    ImgFilterPipe,
    ImageOverlayComponent,
    CanvaComponent,
    Canva2Component,
  ],
  imports: [
    IvyCarouselModule,
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    NgbModule,
    ColorPickerModule,
    ImageCropperModule,
    ReactiveFormsModule,
    FormFieldsModule,
    NgxSliderModule,
    FullCalendarModule,
    DragDropModule,
    NgxSkeletonLoaderModule,
    NgxMaskModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ImageFilterService, DatePipe],
})
export class HomeModule {}
