<div #box class="resizable-draggable editable-text" [style.background-color]="model.bColor"
  [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)"
  (window:mouseup)="setStatus($event, 0)" [style.left.px]="model.left ? model.left : ''"
  [style.top.px]="model.top ? model.top : ''" [style.height.px]="model.height ? model.height : ''"
  [style.width.px]="model.width ? model.width : ''" [style.font-size.px]="model.size ? model.size : ''">
  <!-- TODO: line height 85% is needed to keep it inside the box. But if we change that, it will break outside the whole box -->
  <!-- <div #textInput (click)="openFontEditor()"
    [ngClass]="{
      'isItalic': model.isItalic,
      'text-left': model.alignIndex === 0,
      'text-center': model.alignIndex === 1,
      'text-right': model.alignIndex === 2,
      'over-line': model.isOverLined? model.isOverLined : false,
      'strike-through': model.isStrikeThrough? model.isStrikeThrough : false,
      'under-line': model.isUnderLined? model.isUnderLined : false}"
    [style.font-size.px]="model.size"
    [style.color]="model.tColor"
    [style.font-family]="font.FClass? font.FClass : ''"
    [style.font-weight]="font.Weight? font.Weight : 400"
    [style.line-height]="model.lineHeight ? model.lineHeight + 'pt' : 'normal'"
    [style.letter-spacing]="model.letterSpacing ? PSLetterSpacing(model.letterSpacing) + 'pt' : 'normal'"
    [style.text-transform]="model.textT"> -->
  <div #textInput (click)="openFontEditor()" [ngClass]="{
      'isItalic': model.isItalic,
      'text-left': model.alignIndex === 0,
      'text-center': model.alignIndex === 1,
      'text-right': model.alignIndex === 2,
      'over-line': model.isOverLined? model.isOverLined : false,
      'strike-through': model.isStrikeThrough? model.isStrikeThrough : false,
      'under-line': model.isUnderLined? model.isUnderLined : false}" [style.font-size.px]="model.size"
    [style.color]="model.tColor" [style.font-family]="font.FClass? font.FClass : ''"
    [style.font-weight]="font.Weight? font.Weight : 400"
    [style.letter-spacing]="model.letterSpacing ? PSLetterSpacing(model.letterSpacing) + 'em' : 'normal'"
    [style.text-transform]="model.textT" title="double click to edit Font Size">
    <ng-container *ngIf="this.model.label.toLowerCase() == 'phone'; else
          normalText">{{model.text | mask: '(000) 000-0000'}}</ng-container>
    <ng-template #normalText>{{model.text}}</ng-template>
  </div>

  <div class="editable-text resize-action" [hidden]="isEditing" (mousedown)="setStatus($event, 1)"></div>
</div>
<!-- TODO: line height 85% is needed to keep it inside the box. But if we change that, it will break outside the whole box -->
<!-- <span #dummytext style="visibility: hidden; line-height: 85%;white-space: pre-line;"
  [style.font-size.px]="model.size ? model.size : ''"
  [ngClass]="{ 'isItalic': model.isItalic, 'text-left': model.alignIndex === 0, 'text-center': model.alignIndex === 1,
  'text-right': model.alignIndex === 2, 'size-normal': model.sizeIndex === 0,
  'over-line': model.isOverLined? model.isOverLined : false,
  'strike-through': model.isStrikeThrough? model.isStrikeThrough : false,
  'under-line': model.isUnderLined? model.isUnderLined : false}"
  [style.font-family]="font.FClass? font.FClass : ''" [style.font-weight]="font.Weight? font.Weight : 400"
  [style.line-height]="model.lineHeight ? model.lineHeight + 'pt' : 'normal'"
  [style.letter-spacing]="model.letterSpacing ? PSLetterSpacing(model.letterSpacing) + 'em': 'normal'"
  [style.text-transform]="model.textT">{{textInput.innerText}}</span> -->
<span #dummytext style="visibility: hidden; line-height: 2rem;white-space: pre-line;"
  [style.font-size.px]="model.size ? model.size : ''" [ngClass]="{ 'isItalic': model.isItalic, 'text-left': model.alignIndex === 0, 'text-center': model.alignIndex === 1,
  'text-right': model.alignIndex === 2, 'size-normal': model.sizeIndex === 0,
  'over-line': model.isOverLined? model.isOverLined : false,
  'strike-through': model.isStrikeThrough? model.isStrikeThrough : false,
  'under-line': model.isUnderLined? model.isUnderLined : false}" [style.font-family]="font.FClass? font.FClass : ''"
  [style.font-weight]="font.Weight? font.Weight : 400"
  [style.letter-spacing]="model.letterSpacing ? PSLetterSpacing(model.letterSpacing) + 'em' : 'normal'"
  [style.text-transform]="model.textT">{{textInput.innerText}}</span>

<!-- <ng-template #content let-modal>

      <div class="moda">
        <form>
          <div class="col-md-12">
            <form accept-charset="UTF-8" method="POST" target="_blank">
              <ng-container>
                <div class="row">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Image Editor</h4>
                      <div>
                        <button type="button" class="btn" aria-label="Close" (click)="onCloseHandled()">
                          <span aria-hidden="true">X</span>
                        </button>
                      </div>
                    </div>
                    <div class="modal-body">
                      <h6>Preview Text</h6>
                       <h4 style="line-height: 85%;"
                       [ngClass]="{
                         'isItalic': model.isItalic,
                         'text-left': model.alignIndex === 0,
                         'text-center': model.alignIndex === 1,
                         'text-right': model.alignIndex === 2,
                         'over-line': model.isOverLined? model.isOverLined : false,
                         'strike-through': model.isStrikeThrough? model.isStrikeThrough : false,
                         'under-line': model.isUnderLined? model.isUnderLined : false}"
                         [style.font-size.px]="model.size"
                         [style.font-family]="font.FClass? font.FClass : ''"
                         [style.font-weight]="font.Weight? font.Weight : 400"
                         [style.letter-spacing]="model.letterSpacing ? PSLetterSpacing(model.letterSpacing) + 'em' : 'normal'"
                         [style.text-transform]="model.textT">
                       <ng-container *ngIf="this.model.label.toLowerCase() == 'phone'; else
                             ithVere">{{model.text | mask: '(000) 000-0000'}}</ng-container>
                       <ng-template #ithVere>{{model.text}}</ng-template>
                     </h4>
                      <div class="row">
                        <div class="col-md-4"><button class="btn btn-primary btn-icon-only" (click)="reduceFont()" [disabled]="loopExecuting != null || model.size <= 1">-1</button></div>
                        <div class="col-md-4"><input class="form-input" type="number" [value]="model.size" (change)="fontChangedEvent($event)" [readonly]="loopExecuting != null" min="0"></div>
                        <div class="col-md-4"><button class="btn btn-primary btn-icon-only" (click)="increaseFont()" [disabled]="loopExecuting != null">+1</button></div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button style="margin-right: 10px;" type="button" class="btn float-right btn-secondary"
                        (click)="resetToDefault()" [disabled]="loopExecuting != null">Reset to Default</button>
                      <button *ngIf="loopExecuting == null" type="button" class="btn btn-tertiary float-right"
                        (click)="startAutoAdjustment()">Start Auto-Adjust</button>
                      <button *ngIf="loopExecuting != null" type="button" class="btn btn-primary float-right"
                          (click)="stopAutoAdjustment()">Stop Adjustment</button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </form>
          </div>
        </form>
      </div>
    </ng-template> -->