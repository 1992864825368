<div class="resizable-draggable overlays-container editable-text" [style.background-color]="data.bColor"
  [style.left.px]="data.left ? (data.left) : 0" [style.top.px]="data.top ? (data.top) : 0"
  [style.height.px]="data.height ? (data.height) : 0" [style.width.px]="data.width ? (data.width) : 0"
  [style.font-size.px]="data.size ? calcPtToPx(data.size) : 0">
  <!-- TODO: line height 85% is needed to keep it inside the box. But if we change that, it will break outside the whole box -->
  <!-- <div
    [ngClass]="{
      'isItalic': data.isItalic,
      'text-left': data.alignIndex === 0,
      'text-center': data.alignIndex === 1,
      'text-right': data.alignIndex === 2,
      'over-line': data.isOverLined? data.isOverLined : false,
      'strike-through': data.isStrikeThrough? data.isStrikeThrough : false,
      'under-line': data.isUnderLined? data.isUnderLined : false}"
    [style.font-size.%]="100"
    [style.color]="data.tColor"
    [style.font-family]="font?.FClass? font.FClass : ''"
    [style.font-weight]="font?.Weight? font.Weight : 400"
    [style.line-height]="data.lineHeight ? data.lineHeight + 'pt' : 'normal'"
    [style.letter-spacing]="data.letterSpacing ? PSLetterSpacing(data.letterSpacing) + 'em' : 'normal'"
    [style.text-transform]="data.isUpper ? 'uppercase' : data.isLower ? 'lowercase' : ''"> -->
  <div [ngClass]="{
      'isItalic': data.isItalic,
      'text-left': data.alignIndex === 0,
      'text-center': data.alignIndex === 1,
      'text-right': data.alignIndex === 2,
      'over-line': data.isOverLined? data.isOverLined : false,
      'strike-through': data.isStrikeThrough? data.isStrikeThrough : false,
      'under-line': data.isUnderLined? data.isUnderLined : false}" [style.font-size.%]="100"
    [style.color]="data.tColor" [style.font-family]="font?.FClass? font.FClass : ''"
    [style.font-weight]="font?.Weight? font.Weight : 400"
    [style.letter-spacing]="data.letterSpacing ? PSLetterSpacing(data.letterSpacing) + 'em' : 'normal'"
    [style.text-transform]="data.isUpper ? 'uppercase' : data.isLower ? 'lowercase' : ''">
    <ng-container *ngIf="data.label.toLowerCase() == 'phone'; else
          normalText">{{data.text | mask: '(000)-000-0000'}}</ng-container>
    <ng-template #normalText>{{data.text}}</ng-template>
  </div>
</div>