import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { NotyfService } from '../shared/services/notyf.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  registeredEmails: string[] = [];
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
  });
  invalidEmail = false;

  hint = false;

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private notyf: NotyfService,
    public router: Router
  ) {
    this.renderer.addClass(document.body, 'side_menu_hide');
    this.renderer.removeClass(document.body, 'side_menu_show');
  }

  ngOnInit(): void {
    this.authService
      .getAllEmails()
      .subscribe((value) => (this.registeredEmails = value));

    this.Email.valueChanges.subscribe((value) => {
      this.invalidEmail = !this.checkIfValidEmail(value);
      if (this.invalidEmail) {
        this.Email.setErrors({ notUnique: true });
      }
    });
  }

  submitData() {
    if (this.Email.value.length > 0) {
      this.hint = true;
      this.authService
        .sendPasswordReset(this.Email.value)
        .then(() => {
          this.notyf.success(
            'Please check your email for the password reset mail'
          );
          setTimeout(() => {
            this.notyf.info('Redirecting to Login page');
            this.router.navigateByUrl('/login');
          }, 3000);
        })
        .catch((reason) => {
          this.notyf.error(reason);
        });
    } else {
      this.notyf.error('Please enter valid email address');
    }
  }

  public get Email() {
    return this.loginForm.get('email');
  }

  checkIfValidEmail(value): boolean {
    for (const email of this.registeredEmails) {
      if (email == value) {
        return true;
      }
    }

    return false;
  }
}
