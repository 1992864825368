import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-canva2',
  templateUrl: './canva2.component.html',
  styleUrls: ['./canva2.component.scss'],
})
export class Canva2Component implements OnInit {
  @ViewChild('canvas') canvas;

  top: Number = 0;
  bg =
    'https://firebasestorage.googleapis.com/v0/b/homelifev1.appspot.com/o/images%2FHL%20Post%2011?alt=media&token=3a71ba13-3423-4939-a4a5-58f198511a70';

  details;

  constructor() {}

  ngOnInit(): void {
    this.details = {
      Image: [
        {
          createdDate: 1628526240654,
          height: 295,
          index: 0,
          isDeleted: false,
          isGraphicHidden: false,
          isSelected: false,
          left: 45,
          radius: 0,
          selectedFile: '../assets/img/person.png',
          text: 'photo',
          top: 1090,
          type: 'photo',
          uniqueId: 'img38img0',
          updatedDate: '',
          width: 238,
        },
        {
          createdDate: 1623153622660,
          height: 774.7,
          index: 1,
          isDeleted: false,
          isGraphicHidden: false,
          isSelected: false,
          left: '101',
          radius: 0,
          selectedFile: '../assets/img/house.jpg',
          text: 'house',
          top: '0',
          type: 'house',
          uniqueId: 'img38img1',
          updatedDate: '',
          width: 1303,
        },
      ],
      Text: [
        {
          alignIndex: 0,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526132640,
          fontFamily: "'Montserrat', sans-serif",
          fontName: 'Montserrat - Extra - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap',
          height: '65.06',
          index: 0,
          isBold: true,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'phone',
          left: '320.02',
          size: 47.9999988,
          sizeIndex: 0,
          tColor: 'White',
          text: '(994) 723-1010',
          textT: 'none',
          top: '1266',
          type: '(994) 723-1010',
          Weight: '800',
          uniqueId: 'img38txt0',
          updatedDate: 1628526132640,
          width: '430.83',
        },
        {
          alignIndex: 0,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526156419,
          fontFamily: "'Montserrat', sans-serif",
          fontName: 'Montserrat - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap',
          height: '34.34',
          index: 1,
          isBold: false,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'email',
          left: '319.52',
          size: 27.999999300000002,
          sizeIndex: 0,
          tColor: 'White',
          text: 'rogerjordan@gmail.com',
          textT: 'lowercase',
          top: '1338',
          type: 'rogerjordan@gmail.com',
          uniqueId: 'img38txt1',
          updatedDate: 1628526156419,
          width: '394.71',
          Weight: '500',
        },
        {
          alignIndex: 1,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526192249,
          fontFamily: "'Montserrat', sans-serif",
          fontName: 'Montserrat - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap',
          height: '22.71',
          index: 2,
          isBold: false,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'Designation',
          left: '39.54',
          size: 19.9999995,
          sizeIndex: 0,
          tColor: 'White',
          text: 'SALESPERSON',
          textT: 'uppercase',
          top: '1448',
          type: 'SALESPERSON',
          uniqueId: 'img38txt2',
          updatedDate: 1628526192249,
          width: '678.23',
        },
        {
          alignIndex: 1,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526180049,
          fontFamily: "'Montserrat', sans-serif",
          fontName: 'Montserrat - Extra - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap',
          height: '36.97',
          index: 3,
          isBold: true,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'Name',
          left: '41.29',
          size: 30.6666659,
          sizeIndex: 0,
          tColor: 'White',
          text: 'ABEL HARPER',
          textT: 'uppercase',
          top: '1408.02',
          type: 'ABEL HARPER',
          uniqueId: 'img38txt3',
          updatedDate: 1628526180049,
          width: '678.23',
          Weight: '800',
        },
        {
          alignIndex: 0,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526464580,
          fontFamily: "'Quicksand', sans-serif",
          fontName: 'Montserrat - Extra - Light',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap',
          height: '56',
          index: 4,
          isBold: false,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'price',
          left: '390',
          size: 73.3333315,
          sizeIndex: 0,
          tColor: '#240c50',
          text: '1500000',
          textT: 'none',
          top: '994',
          type: '1500000',
          uniqueId: 'img38txt4',
          updatedDate: 1628526464580,
          width: '327',
        },
        {
          alignIndex: 0,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526491755,
          fontFamily: "'Bebas Neue', cursive",
          fontName: 'Aleo - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
          height: '59.26',
          index: 5,
          isBold: false,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'total sq',
          left: '1227.83',
          size: 54.666665300000005,
          sizeIndex: 0,
          tColor: 'Black',
          text: '2,000',
          textT: 'none',
          top: '1050.31',
          type: '2,000',
          uniqueId: 'img38txt5',
          updatedDate: 1628526491755,
          width: '200',
        },
        {
          alignIndex: 0,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526558203,
          fontFamily: "'Bebas Neue', cursive",
          fontName: 'Aleo - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
          height: '59.26',
          index: 6,
          isBold: false,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'bath',
          left: '1040',
          size: 54.666665300000005,
          sizeIndex: 0,
          tColor: 'Black',
          text: '2',
          textT: 'none',
          top: '1050.31',
          type: '2',
          uniqueId: 'img38txt6',
          updatedDate: 1628526558203,
          width: '75',
        },
        {
          alignIndex: 0,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526637038,
          fontFamily: "'Bebas Neue', cursive",
          fontName: 'Aleo - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
          height: '59.26',
          index: 7,
          isBold: false,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'bath',
          left: '875',
          size: 54.666665300000005,
          sizeIndex: 0,
          tColor: 'Black',
          text: '4',
          textT: 'none',
          top: '1050.31',
          type: '4',
          uniqueId: 'img38txt7',
          updatedDate: 1628526637038,
          width: '70',
        },
        {
          alignIndex: 0,
          bColor: '',
          colorIndex: 0,
          createdDate: 1628526170355,
          fontFamily: "'Montserrat', sans-serif",
          fontName: 'Montserrat - Bold',
          fontUrl:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap',
          height: '35.68',
          index: 8,
          isBold: true,
          isDeleted: false,
          isItalic: false,
          isSelected: false,
          label: 'Address',
          left: '287.16',
          size: 35.999999100000004,
          sizeIndex: 0,
          tColor: 'White',
          text: 'Suite#1 New Avenue, NY - 012',
          textT: 'none',
          top: '920.8',
          type: 'Suite#1 New Avenue, NY - 012',
          uniqueId: 'img38txt8',
          updatedDate: 1628526170355,
          width: '1100.22',
        },
      ],
    };
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log(this.canvas);
    this.top = this.canvas.nativeElement.offsetTop;
    this.top = this.canvas.nativeElement.offsetTop;
    setTimeout(() => {
      this.details.Image.forEach((element) => {
        element.top = parseFloat(element.top + '') + parseFloat(this.top + '');
        element.left =
          parseFloat(element.left + '') +
          parseFloat(this.canvas.nativeElement.offsetLeft + '');
      });

      this.details.Text.forEach((element) => {
        element.top = parseFloat(element.top + '') + parseFloat(this.top + '');
        element.left =
          parseFloat(element.left + '') +
          parseFloat(this.canvas.nativeElement.offsetLeft + '');
      });
    }, 0);

    // this.details = this.details;
    // debugger;
  }
}
