import { Options } from '@angular-slider/ngx-slider';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CropperPosition,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { MoveClampedToParentDirective } from 'src/app/shared/directives/move-clamped-to-parent.directive';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EditSettingsService } from 'src/app/shared/services/edit-settings.service';

const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2,
}

@Component({
  selector: 'app-image-overlay',
  templateUrl: './image-overlay.component.html',
  styleUrls: ['./image-overlay.component.scss'],
})
export class ImageOverlayComponent implements OnInit {
  public postTypes: CustomPostType[] = [];
  public sizeData: CustomPostType = null;

  public userImgUrl = null;

  @ViewChild(MoveClampedToParentDirective) logo: MoveClampedToParentDirective;

  @Input() settings: any;
  @Input() layer: any;
  @Input() i: number;

  @Input('width') public width: number;
  @Input('height') public height: number;
  @Input('left') public left: number;
  @Input('top') public top: number;
  @ViewChild('imgLay') public box: ElementRef;
  private boxPosition: { left: number; top: number };
  private containerPos: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  };
  public mouse: { x: number; y: number };
  public status: Status = Status.OFF;
  public uniqueId;
  ratio = 4 / 2;
  private mouseClick: { x: number; y: number; left: number; top: number };

  selectedRatio: number = 5 / 1;
  public isSelected = false;

  templateModel: any;
  currentImageUniqueId = '';
  public currentimgWidth = 0;
  transform: ImageTransform = {};
  rotateValue = 0;
  rotateSliderOptions: Options = {
    floor: 1,
    ceil: 360,
  };
  zoomValue = 0;
  zoomSliderOptions: Options = {
    floor: 1,
    ceil: 10,
  };
  display = 'none';
  public originalImage: string;
  public croppedImage = '';

  public isMAR = false;
  public imageChanged = false;

  public cropperPos: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
  public initialPos: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
  public prevCropperPos: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };
  public currentCropperPos: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };

  constructor(
    private editSettingsService: EditSettingsService,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private authService: AuthService
  ) {
    this.el = el.nativeElement;

    this.authService.getUsers().then((result) => {
      this.getBase64ImageFromUrl(result.payload.val().imageData).then(
        (data: any) => {
          this.userImgUrl = data;
          this.originalImage = data;
        }
      );
    });
  }

  ngOnInit() {
    // ////console.log(this.settings);
    // subscribe
    this.editSettingsService.storeOverlays.subscribe((isClear) =>
      this.onUpdateOverlays(isClear)
    );
    if (this.settings) {
      this.postTypes = this.settings.sizeSettings.sizes;
      const imgData = this.settings.imageSettings.images.filter(
        (x: any) => x.uniqueId == this.settings.selectedImageUniqueId
      );
      this.sizeData = this.getPostData(imgData.type);
    }
  }
  ngAfterViewInit() {
    this.loadBox();
    this.loadContainer();

    this.cd.detectChanges();
  }

  onUpdateOverlays(isClear: boolean) {
    // update
    if (this.logo) {
      this.logo.update();
    }

    // clear currently selected controls
    if (isClear) {
      this.isSelected = false;
    }
  }

  onSelect() {
    // only one selected overlay at a time
    this.editSettingsService.updateOverlays(true);

    // update
    this.isSelected = true;
  }

  private loadBox() {
    if (this.box != undefined) {
      const { left, top } = this.box.nativeElement.getBoundingClientRect();
      const e = this.box.nativeElement.getBoundingClientRect();
      const ls = Number(e.left) / 2;
      const tf = Number(e.top) / 2;
      this.boxPosition = { left, top };
      this.boxPosition.left = ls;
      this.boxPosition.top = tf;
    }
  }

  private loadContainer() {
    if (this.box != undefined) {
      const left = Number(this.boxPosition.left - this.left) / 2;
      const top = Number(this.boxPosition.top - this.top) / 2;
      const right = Number(left + this.width) / 2;
      const bottom = Number(top + this.height) / 2;
      this.containerPos = { left, top, right, bottom };
    }
  }

  setStatus(event: MouseEvent, status: number, id: number) {
    if (status === 1) {
      event.stopPropagation();
    } else if (status === 2) {
      this.mouseClick = {
        x: event.clientX,
        y: event.clientY,
        left: this.left,
        top: this.top,
      };
    } else {
      this.loadBox();
    }
    this.status = status;
    this.uniqueId = id;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = { x: event.clientX, y: event.clientY };
    if (this.status === Status.RESIZE) {
      this.resize();
    }
  }

  private resize() {
    this.settings.logoSettings.images[this.uniqueId].width = Number(
      this.mouse.x > this.boxPosition.left
    )
      ? this.mouse.x - this.boxPosition.left
      : 0;
    this.settings.logoSettings.images[this.uniqueId].height = Number(
      this.mouse.y > this.boxPosition.top
    )
      ? this.mouse.y - this.boxPosition.top
      : 0;
  }

  private resizeCondMeet() {
    return (
      Number(this.mouse.x) / 2 < this.containerPos.right &&
      Number(this.mouse.y) / 2 < this.containerPos.bottom
    );
  }

  onUpload(e) {
    const reader = new FileReader();
    this.imageChanged = true;
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.originalImage = reader.result as string;
      };
    }
  }
  cropImage(content, id) {
    this.ratio =
      parseInt(this.settings.logoSettings.images[id].width) /
      parseInt(this.settings.logoSettings.images[id].height);
    this.currentImageUniqueId = this.settings.logoSettings.images[id].uniqueId;
    this.display = 'block';
    // this.transform = {};
    if (this.layer.type == 'photo' && this.originalImage == this.userImgUrl) {
      this.getBase64ImageFromUrl(
        this.settings.logoSettings.images[id].selectedFile
      ).then((data: any) => {
        ////console.log(this.transform);
        this.croppedImage = data;
        this.currentimgWidth = this.settings.logoSettings.images[id].width;
        this.templateModel = this.modalService.open(content, {
          ariaLabelledBy: 'modal-basic-title',
          backdrop: 'static',
          size: 'md',
        });
      });
    } else {
      if (this.layer.type != 'photo' && this.originalImage == this.userImgUrl) {
        this.originalImage = this.layer.selectedFile;
      }
      this.croppedImage = this.layer.selectedFile;
      this.currentimgWidth = this.settings.logoSettings.images[id].width;
      this.templateModel = this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
        size: 'md',
      });
    }
    this.cd.detectChanges();
  }

  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.currentCropperPos = event.cropperPosition;
    ////console.log(this.currentCropperPos);

    if (
      (this.sameCropperPositions(this.prevCropperPos, {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
      }) ||
        this.imageChanged) &&
      this.currentCropperPos.x1 >= 0 &&
      this.currentCropperPos.y1 >= 0
    ) {
      this.imageChanged = false;
      this.initialPos = {
        x1: this.currentCropperPos.x1,
        x2: this.currentCropperPos.x2,
        y1: this.currentCropperPos.y1,
        y2: this.currentCropperPos.y2,
      };
      this.prevCropperPos = {
        x1: this.currentCropperPos.x1,
        x2: this.currentCropperPos.x2,
        y1: this.currentCropperPos.y1,
        y2: this.currentCropperPos.y2,
      };
    }

    // this.settings.logosettings = this.settings.logoSettings.splice();
  }
  transformImage(prop) {
    if (prop === 1) {
      this.rotateValue += 90;
      this.rotateValue = this.rotateValue == 360 ? 0 : this.rotateValue;
      this.transform = {
        ...this.transform,
        rotate: this.rotateValue,
      };
    } else if (prop === 2) {
      this.transform = {
        ...this.transform,
        scale: this.zoomValue,
      };
    }
  }
  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }
  onCloseHandled() {
    this.templateModel.close();
    this.settings.logoSettings.images.forEach((imgModels, index) => {
      if (imgModels.uniqueId === this.currentImageUniqueId) {
        imgModels.selectedFile = this.croppedImage;
      }
    });
    this.prevCropperPos = this.currentCropperPos;
  }

  fitFullImage(): void {
    this.isMAR = this.isMAR ? false : true;
    this.imageChanged = true;
    const x = this.originalImage;
    this.originalImage = '';
    setTimeout(() => {
      this.originalImage = x;
    }, 100);
  }

  getImageFit(): string {
    // return 'cover'
    return this.isMAR ? 'cover' : 'fill';
  }

  getImageUrl(layer: any) {
    if (
      layer.type == 'photo' &&
      this.userImgUrl &&
      layer.selectedFile == '../assets/img/person.png'
    ) {
      layer.selectedFile = this.userImgUrl;
      this.cd.detectChanges();
    }
    return layer.selectedFile;
  }

  get DefaultDP() {
    if (this.layer.type == 'photo') {
      if (this.userImgUrl) {
        return this.userImgUrl;
      } else {
        return '../assets/img/person.png';
      }
    }
  }

  setDPasPic() {
    this.originalImage = this.DefaultDP;
    this.imageChanged = true;
  }

  getPostData(name: string): CustomPostType {
    for (const item of this.postTypes) {
      if (item.Name == name) {
        return item;
      }
    }
  }

  cropperReady() {
    if (!this.sameCropperPositions(this.cropperPos, this.prevCropperPos)) {
      ////console.log('Oombaava Aambal aambal');
      ////console.log(this.prevCropperPos);

      this.cropperPos = {
        x1: this.prevCropperPos.x1,
        x2: this.prevCropperPos.x2,
        y1: this.prevCropperPos.y1,
        y2: this.prevCropperPos.y2,
      };
    }
  }

  sameCropperPositions(a: CropperPosition, b: CropperPosition): boolean {
    if (a.x1 == b.x1 && a.x2 == b.x2 && a.y1 == b.y1 && a.y2 == b.y2) {
      return true;
    }
    return false;
  }

  resetTransformations() {
    this.transform = {};
    this.prevCropperPos = {
      x1: this.initialPos.x1,
      x2: this.initialPos.x2,
      y1: this.initialPos.y1,
      y2: this.initialPos.y2,
    };

    this.cropperPos = {
      x1: this.prevCropperPos.x1,
      x2: this.prevCropperPos.x2,
      y1: this.prevCropperPos.y1,
      y2: this.prevCropperPos.y2,
    };
  }
}
