import { Inject, Injectable } from '@angular/core';
import { Notyf } from 'notyf';
import { NOTYF } from '../utils/notyf.token';

@Injectable({
  providedIn: 'root'
})
export class NotyfService {

  constructor(@Inject(NOTYF) private notyf: Notyf) { }

  error(msg){
    this.notyf = new Notyf({
      position: {
        x: 'right',
        y: 'bottom',
      },
      types: [{
        type: 'error',
        background: '#FA5252',
        icon: {
          className: 'fas fa-times',
          tagName: 'span',
          color: '#fff'
        },
        dismissible: false
      }]
    });
    this.notyf.open({
      type: 'error',
      message: msg
    });
  }

  success(msg){
    this.notyf = new Notyf({
      position: {
        x: 'right',
        y: 'bottom',
      },
      types: [{
        type: 'info',
        background: '#0948B3',
        icon: {
          className: 'fas fa-success',
          tagName: 'span',
          color: '#fff'
        },
        dismissible: false
      }]
    });
    this.notyf.open({
      type: 'success',
      message: msg
    });
  }

  info(msg){
    this.notyf = new Notyf({
      position: {
        x: 'right',
        y: 'bottom',
      },
      types: [{
        type: 'info',
        background: '#0948B3',
        icon: {
          className: 'fas fa-info-circle',
          tagName: 'span',
          color: '#fff'
        },
        dismissible: false
      }]
    });
    this.notyf.open({
      type: 'info',
      message: msg
    });
  }

  warning(msg){
    this.notyf = new Notyf({
      position: {
        x: 'right',
        y: 'bottom',
      },
      types: [{
        type: 'warning',
        background: '#F5B759',
        icon: {
          className: 'fas fa-exclamation-triangle',
          tagName: 'span',
          color: '#fff'
        },
        dismissible: false
      }]
    });
    this.notyf.open({
      type: 'warning',
      message: msg
    });
  }
}
