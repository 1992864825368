// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAwsvY0yqwL07Pe7PB4E6OolJli2CtxlQk',
    authDomain: 'homelifev1.firebaseapp.com',
    databaseURL: 'https://sl-pro-homelife-db.firebaseio.com',
    projectId: 'homelifev1',
    storageBucket: 'homelifev1.appspot.com',
    messagingSenderId: '104250755814',
    appId: '1:104250755814:web:a3beae1d929aac0f0b4927',
  },

  testDb: {
    apiKey: 'AIzaSyAwsvY0yqwL07Pe7PB4E6OolJli2CtxlQk',
    authDomain: 'homelifev1.firebaseapp.com',
    databaseURL: 'https://sp-homelife-test-db.firebaseio.com',
    projectId: 'homelifev1',
    storageBucket: 'homelifev1.appspot.com',
    messagingSenderId: '104250755814',
    appId: '1:104250755814:web:a3beae1d929aac0f0b4927',
  },

  //////////////////////////////////////////////////////////////
  users: 'users',
  userDetails: 'userDetailes',
  settings: {
    fontSettings: 'templateSettings/fontSettings',
    sizeSettings: 'templateSettings/sizeSettings',
    catgSettings: 'templateSettings/catgSettings',
  },
  imgThum: 'templateImages/thumbnails/', //storage
  imgBg: 'templateImages/backgrounds/', //storage
  templates: 'templates',
  templateDesign: 'templateDetails',
  templateDetailsText: 'templateDetails/text',
  templateDetailsImage: 'templateDetails/images',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
