import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EditSettingsService } from 'src/app/shared/services/edit-settings.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Options } from '@angular-slider/ngx-slider';
import { TagsManagerService } from 'src/app/shared/services/tags-manager.service';
import { CustomTags } from 'src/app/shared/models/custom-tags.model';
import { PostTypesManagerService } from 'src/app/shared/services/post-types-manager.service';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
import { SettingsService } from 'src/app/shared/services/settings.service';
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  loading = false;

  sizeDrop = '';
  headline = 'All Templates';

  activeIndex = 'All';

  coming = [
    { img: 'assets/img/dummy/Design 7.1.jpg' },
    { img: 'assets/img/dummy/Design 10.jpg' },
    { img: 'assets/img/dummy/Design 14.jpg' },
    { img: 'assets/img/dummy/Design 15.jpg' },
  ];

  rotateValue = 0;
  rotateSliderOptions: Options = {
    floor: 0,
    ceil: 360,
  };
  zoomValue = 0;
  zoomSliderOptions: Options = {
    floor: 0,
    ceil: 10,
  };
  display = 'none';
  @Input() settings: any;
  @Output() imageSettingsChange: EventEmitter<any> = new EventEmitter();
  @Output() textSettingsChange: EventEmitter<any> = new EventEmitter();
  @Output() sizeSettingsChange: EventEmitter<any> = new EventEmitter();
  @Output() logoSettingsChange: EventEmitter<any> = new EventEmitter();

  // @Output() template_click: EventEmitter<any> = new EventEmitter();

  @Input() templateSettings: any;
  @Output() templateSet: EventEmitter<any> = new EventEmitter();

  public fields: any[] = [];

  public originalImage: string;
  public croppedImage = '';
  public currentId = '';
  public uploadedFiles: any[] = [];
  public form: FormGroup;
  public currentimgWidth = 0;

  closeResult = '';
  name = 'Your Name';
  footerCopy = 'footer text';
  companyName = 'My Company';
  address = 'Some Details';
  message = 'wishing you a';
  companyLogo = '';
  photo = '';
  phoneNUmber = '(555) 555-1234';
  templateModel: any;
  isClicked = false;
  imageSettings = {
    images: [],
    filterQuery: '',
  };
  settingsForTemplate;
  imageChangedEvent: any = '';
  transform: ImageTransform = {};

  tags: any = [];
  temp: any = [];
  randomNumber;

  thumps: any = [];

  tagData!: Array<CustomTags>;
  sizeData!: Array<CustomPostType>;
  constructor(
    private editSettingsService: EditSettingsService,
    private authService: AuthService,
    private modalService: NgbModal,
    private Tagservice: TagsManagerService,
    private SizeService: PostTypesManagerService,
    private Settings: SettingsService
  ) {
    // //console.log(this.settings);
    this.getUserData();

    setTimeout(() => {
      this.loading = true;
    }, 1500);

    this.Tagservice.getTags().subscribe((response: any) => {
      // //console.log(response);
      this.tagData = response;
    });

    this.SizeService.getPostTypes().subscribe((response: any) => {
      this.sizeData = response;

      this.sizeData.forEach((element, index) => {
        this.Settings.getImagesBySize(index).subscribe((response: any) => {
          console.log(response);
          this.thumps[index] = response;
        });
      });

      console.log(this.thumps);
    });

    // this.Settings.getImagesBySize('post').subscribe((res: any) => {
    //console.log(res);
    // });
  }

  ngOnInit(): void {
    // //console.log(this.settings);

    // this.addText();
    this.settings.isWkFlow = true;
    let i = 0;
    // We find the groups over here.
    for (i = 0; i < this.settings.imageSettings.images.length; i++) {
      // const uid = this.settings.imageSettings.images[i].uniqueId;
      // if (this.templateSettings[uid]) {
      // this.imageSettings.images.push(this.settings.imageSettings.images[i]);
      let hint = false;
      for (const element of this.tags) {
        hint = false;
        if (element.group_name === this.settings.imageSettings.images[i].type) {
          hint = true;
          break;
        }
      }
      if (!hint) {
        this.tags.push({
          group_name: this.settings.imageSettings.images[i].type,
          images: [],
        });
      }
      // }
    }
    // We add the non deleted images to the correct groups over here
    for (i = 0; i < this.settings.imageSettings.images.length; i++) {
      // const uid = this.settings.imageSettings.images[i].uniqueId;
      // if (this.templateSettings[uid]) {
      if (!this.settings.imageSettings.images[i].isDeleted) {
        this.imageSettings.images.push(this.settings.imageSettings.images[i]);
        this.tags.forEach((element) => {
          if (
            element.group_name === this.settings.imageSettings.images[i].type
          ) {
            element.images.push(this.settings.imageSettings.images[i]);
          }
        });
      }
      // }
    }
    this.imageSettings.images = this.shuffle(this.imageSettings.images);
    this.temp = this.imageSettings;
    this.tags.forEach((element) => {
      element.images = this.shuffle(element.images);
    });
    // ////console.log(this.imageSettings.images)
  }

  getThumpnails(index) {
    let thums: any = [];
    this.Settings.getImagesBySize(index).subscribe((response: any) => {
      console.log(response);
      thums = response;
    });
    return thums;
  }

  getHeight(Height, Width) {
    let x = 20000 / parseFloat(Width);
    let y = Height * (x / 100);
    return y;
  }

  trim(data: string) {
    if (data.length != 0) {
      let temp: string = '';

      for (var i = 0; i < data.length; i++) {
        if (data[i] != ' ') temp = temp + data[i];
      }
      return temp.toLowerCase();
    } else return '';
  }

  getUserData() {
    this.authService.getUsers().then((result) => {
      this.name = result.payload.val().displayName;
      this.phoneNUmber = result.payload.val().phoneNo;
      this.photo = result.payload.val().imageData;
      this.footerCopy = result.payload.val().designation;
      this.address = result.payload.val().address;
    });
  }

  setUserData(type, text) {
    switch (type) {
      case 'name':
        return this.name;
      case 'footercopy':
        return this.footerCopy;
      case 'photo':
        return this.photo;
      case 'address':
        return this.address;
      case 'phonenumber':
        return this.phoneNUmber;
      default:
        return text;
    }
  }

  onCloseHandled() {
    this.display = 'none';
  }
  open(content, uniqueId) {
    debugger;
    this.getUserData();
    this.uploadedFiles = [];
    this.originalImage = '';

    this.settings.selectedImageUniqueId = uniqueId;
    this.settingsForTemplate =
      this.templateSettings[this.settings.selectedImageUniqueId];
    this.fields = [];
    const txts = this.settingsForTemplate.Text;
    if (txts.length > 0) {
      for (let i = 0; i < txts.length; i++) {
        this.settings.textSettings.models.push(txts[i]);
        const fieldConfig = {
          value: this.setUserData(txts[i].type, txts[i].text),
          key: txts[i].type,
          uniqueId: txts[i].uniqueId,
          label: txts[i].label,
          type: 'text',
        };
        this.fields.push(fieldConfig);
      }
    }
    const imgs = this.settingsForTemplate.Image;
    if (imgs.length > 0) {
      for (let i = 0; i < imgs.length; i++) {
        this.settings.logoSettings.images.push(imgs[i]);
        const fieldConfig = {
          value: '',
          key: imgs[i].type,
          uniqueId: imgs[i].uniqueId,
          label: imgs[i].text,
          type: 'file',
          width: imgs[i].width,
          onUpload: this.onUpload.bind(this),
        };
        this.fields.push(fieldConfig);
        const file = {
          imgid: imgs[i].uniqueId,
          label: imgs[i].text,
          width: imgs[i].width,
          imgValue: this.setUserData(imgs[i].type, ''),
        };
        if (file.imgValue.length > 0) {
          this.uploadedFiles.push(file);
        }
      }
    }
    if (imgs.length == 1) {
      this.currentId = imgs[0].uniqueId;
      this.currentimgWidth = imgs[0].width;
    }
    const fieldsCtrls = {};
    for (const f of this.fields) {
      fieldsCtrls[f.uniqueId] = new FormControl(f.value || '');
    }
    this.form = new FormGroup(fieldsCtrls);

    this.settings.logoSettings.images =
      this.settings.logoSettings.images.splice(
        this.settings.logoSettings.images.length
      );
    this.settings.textSettings.models =
      this.settings.textSettings.models.splice(
        this.settings.textSettings.models.length
      );
    debugger;
    this.logoSettingsChange.emit(this.settings.logoSettings);
    this.textSettingsChange.emit(this.settings.textSettings);
    this.imageSettingsChange.emit(this.settings.imageSettings);
    this.addText();
  }

  shuffle(array) {
    // let m = array.length, t, i;

    // // While there remain elements to shuffle…
    // while (m) {

    //   // Pick a remaining element…
    //   i = Math.floor(Math.random() * m--);

    //   // And swap it with the current element.
    //   t = array[m];
    //   array[m] = array[i];
    //   array[i] = t;
    // }

    return array;
  }

  setPreview(filter) {
    this.imageSettings.images = [];
    if (filter === 'all') {
      for (let i = 0; i < this.settings.imageSettings.images.length; i++) {
        const uid = this.settings.imageSettings.images[i].uniqueId;
        if (this.templateSettings[uid]) {
          this.imageSettings.images.push(this.settings.imageSettings.images[i]);
        }
      }
    } else {
      this.headline = filter;
      for (let i = 0; i < this.settings.imageSettings.images.length; i++) {
        const uid = this.settings.imageSettings.images[i].uniqueId;
        if (
          this.templateSettings[uid] &&
          this.settings.imageSettings.images[i].tags === filter
        ) {
          this.imageSettings.images.push(this.settings.imageSettings.images[i]);
        }
      }
    }

    this.imageSettings.images = this.shuffle(this.imageSettings.images);
  }

  onZoom(event) {}

  onUpload(e, id, text, width) {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = reader.result;
        this.form.get(id).setValue(img);
        const item = this.uploadedFiles.findIndex((e) => e.imgid === id);
        if (item === -1) {
          const file = {
            imgid: id,
            label: text,
            width,
            imgValue: img,
          };
          this.uploadedFiles.push(file);
        } else {
          this.uploadedFiles[item].imgValue = img;
        }
      };
    }
  }

  cropImage(id) {
    const cc = this.uploadedFiles.filter((e) => e.imgid === id);
    this.originalImage = cc[0].imgValue;
    this.currentId = id;
    this.currentimgWidth = cc[0].width;
    this.display = 'block';
    this.transform = {};
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const index = this.uploadedFiles.findIndex(
      (e) => e.imgid === this.currentId
    );
    this.uploadedFiles[index].imgValue = this.croppedImage;
  }

  processImgUrl(url) {
    return this.editSettingsService.processImgUrl(url, 200, 100);
  }

  addText() {
    // this.isClicked = !this.isClicked;
    const data = this.form.value;
    this.settingsForTemplate.Text.forEach((txtModel, index) => {
      if (!txtModel.isDeleted) {
        txtModel.text = data[txtModel.uniqueId];
        txtModel.isSelected = true;
        this.settings.textSettings.models.push(txtModel);
      }
    });
    this.settingsForTemplate.Image.forEach((imgModels, index) => {
      if (!imgModels.isDeleted) {
        this.settings.logoSettings.images.push(imgModels);
      }
    });

    // update required to trigger change detection
    this.settings.textSettings.models =
      this.settings.textSettings.models.slice();
    this.settings.logoSettings.images =
      this.settings.logoSettings.images.slice();
    // notify
    this.logoSettingsChange.emit(this.settings.logoSettings);
    this.textSettingsChange.emit(this.settings.textSettings);

    this.getUserData();
    this.uploadedFiles = [];
    /* this.photo='';
    this.name='';
    this.footerCopy=''; */
    // this.templateModel.close();
  }

  onSelectFileChange($event) {
    this.imageChangedEvent = $event;
    // cancel check
    const files = $event.srcElement.files;
    if (files.length > 0) {
      const inputName = $event.currentTarget.name;
      // base64 encode file (TODO extract to a service)
      const reader = new FileReader();
      reader.onload = (e) => {
        const url = (e.target as FileReader).result;
        const file = { url, name: 'Uploaded Image' };
        // this.logoSettings.selectedFile = file;
        const uniqueId = this.settings.logoSettings.images.length;
        const img = new Image();
        let height = 0;
        let width = 0;
        img.src = e.target.result as string;
        img.onload = (e: any) => {
          height = e.path[0].height;
          width = e.path[0].width;
          this.settingsForTemplate[0].imgModels[0].selectedFile = url;
          // this.settings.logoSettings.images.push(this.settingsForTemplate[0].imgModels[0]);

          // update required to trigger change detection
          this.settings.logoSettings.images =
            this.settings.logoSettings.images.slice();
        };
        // add
      };
      reader.readAsDataURL($event.srcElement.files[0]);
    }
  }
  /* imageCropped(event: ImageCroppedEvent) {
    const img= new Image();
        let height =0;
        let width =0;
        img.src =event.base64 as string;
        img.onload = (e: any) => {
          height =e.path[0].height;
          width = e.path[0].width;
          this.settingsForTemplate[0].imgModels[0].selectedFile = event.base64;
          this.settings.logoSettings.images.push(this.settingsForTemplate[0].imgModels[0]);

          //update required to trigger change detection
          this.settings.logoSettings.images = this.settings.logoSettings.images.slice();

        }
  }
   */
  onIsSelectedChange($uniqueId) {
    if (this.settings.textSettings.models.length < 6) {
      this.addText();
      this.templateSet.emit(true);

      this.imageSettingsChange.emit(this.settings.imageSettings);

      this.settings.sizeSettings.selectedSizeIndex = 0;
      this.sizeSettingsChange.emit(this.settings.sizeSettings);
    }
  }

  transformImage(prop) {
    if (prop === 1) {
      this.transform = {
        ...this.transform,
        rotate: this.rotateValue,
      };
    } else if (prop === 2) {
      this.transform = {
        ...this.transform,
        scale: this.zoomValue,
      };
    }
  }
  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  onKeyUp($event) {
    this.imageSettings.filterQuery = $event.target.value;
  }

  check(items: String) {
    console.log(this.sizeDrop.toLowerCase(), items.toLowerCase());
    debugger;
    if (this.sizeDrop.toLowerCase() === items.toLowerCase()) {
      return false;
    } else {
      return true;
    }
  }
}
