import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuard } from '../shared/guard/auth.guard';

import { CanvaComponent } from './main/canva/canva.component';
import { Canva2Component } from './main/canva2/canva2.component';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: MainComponent, canActivate: [AuthGuard] },
  { path: 'canva', component: CanvaComponent },
  { path: 'canva2', component: Canva2Component },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
})
export class HomeRoutingModule {}
