import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";


@Injectable({
	providedIn: 'root'
})
export class GenerateImageService {

	hint = 'png';
	quality = 1;
	constructor() { }
	private service: any = new Subject();
	public store: Observable<any> = this.service.asObservable();

	private service2: any = new Subject();
	public status: Observable<any> = this.service2.asObservable();

	public generateImage(hint, quality) {
		this.hint = hint;
		this.quality = quality
		this.service.next({ hint: this.hint, quality: this.quality });
	}

	public imageGenerationStatus(status) {
		this.service2.next(status);
	}
}
