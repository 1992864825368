<nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none">
  <a class="navbar-brand me-lg-5" href="../../index.html">
    <img class="navbar-brand-dark" src="../../assets/img/brand/light.svg" alt="Volt logo" /> <img
      class="navbar-brand-light" src="../../assets/img/brand/dark.svg" alt="Volt logo" />
  </a>
  <div class="d-flex align-items-center">
    <button class="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse"
      data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
</nav>
<nav id="sidebarMenu" style="background-color: #ffffff !important;border-right: 1px solid #f3f3f3 !important;"
  class="sidebar d-md-block bg-dark text-white collapse" data-simplebar>
  <div class="pt-3">
    <div class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
      <!-- <div class="d-flex align-items-center">
        <div class="user-avatar lg-avatar me-4">
          <img src="../../assets/img/team/profile-picture-3.jpg" style="object-fit: cover;"
            class="card-img-top rounded-circle border-white">
        </div>
        <div class="d-block">
          <h2 class="h6">Hi, {{userDetails?.name}}</h2>
          <a href="javascript: void()" (click)="authService.SignOut()" class="btn btn-secondary text-dark btn-xs"><span
              class="me-2"><span class="fas fa-sign-out-alt"></span></span>Sign Out</a>
        </div>
      </div> -->
      <div class="collapse-close d-md-none">
        <a href="#sidebarMenu" class="fas fa-times" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu" aria-expanded="true" aria-label="Toggle navigation">
        </a>
      </div>
    </div>
    <ul class="nav flex-column pt-3 pt-md-0">
      <li class="nav-item" style="margin-bottom: 15px;">
        <a href="javascript: void()" class="nav-link d-flex align-items-center">
          <span class="sidebar-icon">
            <img src="../../assets/img/brand/light.svg" height="20" width="20" alt="Volt Logo">
          </span>
          <span class="mt-1 sidebar-text">HOMELIFE APP</span>
        </a>
      </li>

      <li class="nav-item " [ngClass]="{'active': activeTabId == 'template'}">
        <a href="javascript: void()" (click)="activeTabId='template';commService.sourceChanged(true);editing_hint=false"
          class="nav-link" [attr.data-bs-toggle]="forMobileCodeFlag? 'collapse' : null"
          [attr.data-bs-target]="forMobileCodeFlag? '#sidebarMenu' : null"
          [attr.aria-controls]="forMobileCodeFlag? 'sidebarMenu' : null"
          [attr.aria-expanded]="forMobileCodeFlag? 'false' : null">
          <span class="sidebar-icon material-icons-outlined">
            design_services
          </span>
          <span class="sidebar-text">Designs</span>
        </a>
      </li>


      <li class="nav-item">
        <span class="nav-link  collapsed  d-flex justify-content-between align-items-center" data-bs-toggle="collapse"
          data-bs-target="#submenu-app">
          <span>
            <span class="sidebar-icon material-icons-outlined">
              task
            </span>
            <span class="sidebar-text">Task List</span>
          </span>
          <span class="link-arrow"><span class="fas fa-chevron-right"></span></span>
        </span>
        <div class="multi-level collapse " role="list" id="submenu-app" aria-expanded="false">
          <ul class="flex-column nav">
            <li class="nav-item " [ngClass]="{'active': activeTabId == 'task overview'}">
              <a href="javascript: void()" (click)="activeTabId='task overview';commService.sourceChanged(false)"
                class="nav-link" [attr.data-bs-toggle]="forMobileCodeFlag? 'collapse' : null"
                [attr.data-bs-target]="forMobileCodeFlag? '#sidebarMenu' : null"
                [attr.aria-controls]="forMobileCodeFlag? 'sidebarMenu' : null"
                [attr.aria-expanded]="forMobileCodeFlag? 'false' : null">
                <span class="sidebar-icon material-icons-outlined">
                  summarize
                </span>
                <span class="sidebar-text">Overview</span>
              </a>
            </li>
            <li class="nav-item " [ngClass]="{'active': activeTabId == 'task kanban'}">
              <a href="javascript: void()" (click)="activeTabId='task kanban';commService.sourceChanged(false)"
                class="nav-link d-flex align-items-center justify-content-between"
                [attr.data-bs-toggle]="forMobileCodeFlag? 'collapse' : null"
                [attr.data-bs-target]="forMobileCodeFlag? '#sidebarMenu' : null"
                [attr.aria-controls]="forMobileCodeFlag? 'sidebarMenu' : null"
                [attr.aria-expanded]="forMobileCodeFlag? 'false' : null">
                <span>
                  <span class="sidebar-icon material-icons-outlined" style="vertical-align: bottom;">
                    splitscreen
                  </span>
                  <span class="sidebar-text">Kanban</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </li>



      <li class="nav-item " [ngClass]="{'active': activeTabId == 'user list'}">
        <a href="javascript: void()" (click)="activeTabId='user list';commService.sourceChanged(false)" class="nav-link"
          [attr.data-bs-toggle]="forMobileCodeFlag? 'collapse' : null"
          [attr.data-bs-target]="forMobileCodeFlag? '#sidebarMenu' : null"
          [attr.aria-controls]="forMobileCodeFlag? 'sidebarMenu' : null"
          [attr.aria-expanded]="forMobileCodeFlag? 'false' : null">
          <span class="sidebar-icon material-icons-outlined" style="vertical-align: bottom;">
            contacts
          </span>
          <span class="sidebar-text">Contacts</span>
        </a>
      </li>


      <li class="nav-item " [ngClass]="{'active': activeTabId == 'calendar'}">
        <a href="javascript: void()" (click)="activeTabId='calendar';commService.sourceChanged(false)" class="nav-link"
          [attr.data-bs-toggle]="forMobileCodeFlag? 'collapse' : null"
          [attr.data-bs-target]="forMobileCodeFlag? '#sidebarMenu' : null"
          [attr.aria-controls]="forMobileCodeFlag? 'sidebarMenu' : null"
          [attr.aria-expanded]="forMobileCodeFlag? 'false' : null">
          <span class="sidebar-icon material-icons-outlined" style="vertical-align: bottom;">
            event
          </span>
          <span class="sidebar-text">Calendar</span>
        </a>
      </li>

      <li class="nav-item " [ngClass]="{'active': activeTabId == 'profile'}">
        <a href="javascript: void()" (click)="activeTabId='profile';commService.sourceChanged(false)" class="nav-link"
          [attr.data-bs-toggle]="forMobileCodeFlag? 'collapse' : null"
          [attr.data-bs-target]="forMobileCodeFlag? '#sidebarMenu' : null"
          [attr.aria-controls]="forMobileCodeFlag? 'sidebarMenu' : null"
          [attr.aria-expanded]="forMobileCodeFlag? 'false' : null">
          <span class="sidebar-icon material-icons-outlined" style="vertical-align: bottom;">
            settings
          </span>
          <span class="sidebar-text">Settings</span>
        </a>
      </li>
    </ul>
  </div>
</nav>


<main class="content" style="padding:0px !important;">
  <app-header></app-header>

  <ng-container [ngSwitch]="activeTabId" *ngIf="settings && templateSettings">

    <ng-container *ngSwitchCase="'template'">
      <div class="row" style="margin: 0px !important;">
        <div class="col-md-12" [hidden]="editing_hint">
          <app-template [templateSettings]="templateSettings" [settings]="settings"
            (sizeSettingsChange)="onSizeSettingsChange($event)" (textSettingsChange)="onTextSettingsChange($event)"
            (logoSettingsChange)="onLogoSettingsChange($event)" (imageSettingsChange)="onImageSettingsChange($event)">
          </app-template>
        </div>
        <div class="col-md-12" [hidden]="!editing_hint">
          <div class="poster-container">

            <div class="row"
              style="background-color: #ffffff;border-radius: 0px; padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid #f3f4f6;">
              <div class="col-md-6" style="display: flex;">
                <div style="margin-right: 10px !important;">
                  <button class="btn" type="button" (click)="backButtonClicked()" style="font-size: 14px;
                  color: #434444;">
                    <span class="fas fa-arrow-alt-circle-left"></span> BACK
                  </button>
                </div>
              </div>
              <div class="col-md-6" style="text-align: right;">
                <div *ngIf="showDownload" style="margin-right: 10px;">

                  <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="true" [disabled]="disa_btn"
                    [hidden]="loading_btn"
                    style="font-size: 13px; background-color: bisque;  border-radius: 10px; color: #434444;">
                    <i class="fas fa-download"></i> DOWNLOAD YOUR DESIGN
                  </button>

                  <div class="dropdown-menu dashboard-dropdown dropdown-menu-end mt-2 py-0" style="
                      padding: 10px;
                      position: absolute;
                      inset: 0px auto auto 0px;
                      margin: 0px;
                      transform: translate(1224px, 121px);
                      width: 290px;">
                    <div class="input_style" style="margin-top: 10px;">
                      <label class="myLa">Quality</label>
                      <select class="form-select mb-2" #quality>
                        <option value="1" selected>1x</option>
                        <option value="2">2x</option>
                        <option value="3">3x</option>
                      </select>
                    </div>
                    <div class="input_style">
                      <label class="myLa">File Formate</label>
                      <select class="form-select mb-3" #fileFormate>
                        <option value="png" selected>PNG format</option>
                        <option value="pdf">PDF format</option>
                      </select>
                    </div>
                    <!-- <div role="separator" class="dropdown-divider my-0"></div> -->
                    <button class="btn btn-secondary mb-3" type="button"
                      (click)="onDownload(fileFormate.value,quality.value)" [disabled]="disa_btn" [hidden]="loading_btn"
                      style="font-size: 14px;color: #434444;width: 100%;">
                      <span class="far fa-file-pdf"></span> DOWNLOAD {{fileFormate.value.toUpperCase()}}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row  mt-4 justify-content-center">
              <div class="col-md-12  align-items-center">
                <ng-container *ngIf="settings">
                  <div class="editor-box" [style.zoom]="sizeData?.Width > 550 ? zoomValue === '' ? '50%' : zoomValue : zoomValue === '' ?
                    '100%' : zoomValue">
                    <app-canvas-select [settings]="settings" (loadingChange)="loadingChange($event)">
                    </app-canvas-select>

                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="tools">
            <div ngbDropdown placement="top-left" display="static">
              <ng-container *ngIf="settings">
                <button class="btn btn-outline-primary" id="dropdownConfig" ngbDropdownToggle>{{
                  sizeData?.Width > 550 ? zoomValue === '' ? '50%' :
                  zoomValue
                  : zoomValue === '' ? '100%' : zoomValue }}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownConfig">
                  <button ngbDropdownItem *ngFor="let zoomText of zoom"
                    (click)="zoomImage(zoomText);">{{zoomText}}</button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="container">
      <app-messages *ngSwitchCase="'message'"></app-messages>
      <app-my-profile *ngSwitchCase="'profile'"></app-my-profile>
      <app-calendar *ngSwitchCase="'calendar'"></app-calendar>
      <app-task-kanban *ngSwitchCase="'task kanban'"></app-task-kanban>
      <app-task-overview *ngSwitchCase="'task overview'"></app-task-overview>
      <app-user-list *ngSwitchCase="'user list'"></app-user-list>
    </div>
  </ng-container>
</main>