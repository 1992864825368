import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { UserDetails } from '../models/user-details.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  constructor(private db: AngularFireDatabase) {}

  getDetails() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    //debugger
    return this.db
      .list('/userDetails', (ref) =>
        ref.orderByChild('userId').equalTo(user.uid)
      )
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((snap) => {
            return {
              docId: snap.payload.key,
              userData: snap.payload.val(),
            } as {
              docId: string;
              userData: UserDetails;
            };
          });
        })
      );
  }

  updateDetails(docId, value: UserDetails) {
    if (docId) {
      return this.db.object('/userDetails/' + docId).update(value);
    } else {
      const user = JSON.parse(sessionStorage.getItem('user'));
      value.userId = user.uid;
      return this.db
        .object('/userDetails/' + this.db.createPushId())
        .update(value);
    }
  }

  getnewreq() {
    return this.db
      .list('/users', (ref) => ref.orderByChild('verify').equalTo(false))
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((snap) => {
            return {
              docId: snap.payload.key,
              userData: snap.payload.val(),
            } as {
              docId: string;
              userData: any;
            };
          });
        })
      );
  }

  verification(docId, value1, value2) {
    return this.db
      .object('/users/' + docId)
      .update({ verify: value1, status: value2 });
  }

  getAllUsers() {
    // return this.db
    //   .list('/users', (ref) => ref.orderByValue().limitToFirst(10))
    //   .valueChanges();

    return this.db
      .list('/users')
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((snap) => {
            return {
              docId: snap.payload.key,
              userData: snap.payload.val(),
            } as {
              docId: string;
              userData: any;
            };
          });
        })
      );
  }

  UpdateUserData(docId, userData) {
    return this.db.object('/users/' + docId).update(userData);
  }
}
