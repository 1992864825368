<ng-container *ngIf="MODE == 'VIEW'">
  <div class="row mb-2">
    <div class="col-md-4">
      <input #search type="text" class="input_custom_design form-control" placeholder="Search"
        (input)="filter = search.value">
    </div>
    <div class="col-md-2">
      <button class="btn btn-sm px-3 btn-secondary ms-3 add_btn_style" (click)="newPostType()">Add New Post
        Type</button>
    </div>
  </div>
  <div class="card">
    <div class="table-responsive">
      <table class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th>#</th>
            <th>Post Type</th>
            <th>Height</th>
            <th>Width</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of postTypesData | filterBy : filter; let i = index;">
            <td>{{i+1}}</td>
            <td>{{item.Name}}</td>
            <td>{{item.Height}}</td>
            <td>{{item.Width}}</td>
            <td>{{item.Status}}</td>
            <td><button class="btn btn-primary btn-xs edit_btn_style" (click)="editPostType(item,i)">Edit</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="MODE == 'CREATE' || MODE == 'EDIT'">
  <div>
    <div class="mb-3">
      <p class="mb-0">Add New Template size Details</p>
      <small>Enter all fields for template sizeing</small>
    </div>
    <form [formGroup]="postTypesForm" (submit)="submitData()">
      <div class="row">
        <div class=" col-md-3 mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Post Type</label>
          <input type="text" class="form-control input_custom_design"
            [class.is-invalid]="PostType.touched && PostType.invalid" [class.is-valid]="PostType.valid"
            formControlName="postType" required>
          <div *ngIf="PostType.touched && PostType.invalid;else validEntry" class="invalid-feedback">
            Ivalid data
          </div>
          <ng-template #validEntry>
            <div *ngIf="PostType.touched" class="valid-feedback">
              Looks good!
            </div>
          </ng-template>
        </div>
        <div class="col-md-3 mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Height</label>
          <input type="number" class="form-control input_custom_design"
            [class.is-invalid]="PostTypeHeight.touched && PostTypeHeight.invalid"
            [class.is-valid]="PostTypeHeight.valid" formControlName="postTypeHeight" required>
        </div>
        <div class=" col-md-3 mb-4">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Width</label>
          <input type="number" class="form-control input_custom_design"
            [class.is-invalid]="PostTypeWidth.touched && PostTypeWidth.invalid" [class.is-valid]="PostTypeWidth.valid"
            formControlName="postTypeWidth" required>
        </div>
        <div class="col-md-3 mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Status</label>
          <select type="text" class="form-control input_custom_design" style="height: 45px;" formControlName="status"
            required>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        <div class="col-md-6 mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Description</label>
          <input type="text" class="form-control input_custom_design"
            [class.is-invalid]="PostTypeWidth.touched && PostTypeWidth.invalid" [class.is-valid]="PostTypeWidth.valid"
            formControlName="description" required>
        </div>
        <div class="col-md-12 mb-2">
          <button class="btn btn-primary  mr-2" type="submit" style="border-radius: 5px;
          line-height: 1rem;" [disabled]="postTypesForm.invalid">{{submitButtonText}}</button>
          <button class="btn btn-danger  mr-2" type="button" style="border-radius: 5px;
          line-height: 1rem;" (click)="viewPostTypes()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</ng-container>