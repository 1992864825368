<div class="card-body">

  <div class="row" [hidden]="loading">
    <div class="col-md-2 p-1" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12]">
      <ngx-skeleton-loader count="1" [theme]="{
            'height.px': 200,
            'background-color': 'rgb(243 245 255);'
          }">
      </ngx-skeleton-loader>
    </div>
  </div>

  <div class="row" [hidden]="!loading" *ngIf="imageSettings?.images.length > 0">
    <div class="row">
      <div class="col-md-6 d-block mb-4 mb-md-0">
        <h5 class="mb-0">
          <span class="sidebar-icon material-icons-outlined">
            design_services
          </span>
          <span class="heading-text" style="font-size: 20px !important;">Awsome Designs For You</span>
        </h5>
        <p style="font-size: 13px !important;">
          Choose from hundreds of free ready-to-use templates for your next
          post
        </p>
      </div>
      <div class="col-lg-3 d-flex">
        <!-- <div class="input-group">
          <input type="text" style="height: 45px;" class="input_custom_design form-control" placeholder="Search">
        </div> -->
      </div>
      <div class="col-lg-3 d-flex">
        <select class="input_custom_design form-select " style="height: 45px;">
          <option value='' selected>Size</option>
          <option [value]="item" *ngFor="let item of sizeData" (click)="sizeDrop = item.Name"
            style="text-transform: capitalize;">
            {{item.Name}}
          </option>
        </select>
      </div>
    </div>

    <div style="display: flex; flex-wrap: wrap;padding: 0px;">
      <div class="mb-3" (click)="activeIndex = 'All'">
        <span class="tagStyle " [ngClass]="{'active__': activeIndex == 'All'}">ALL</span>
      </div>
      <div class="mb-3" *ngFor="let items of tagData; let i = index" (click)="activeIndex = items.Name">
        <span class="tagStyle" [ngClass]="{'active__': activeIndex == items.Name}">{{items.Name}}
        </span>
      </div>
    </div>

    <!-- //////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- 
    <div class="col-md-12 p-2" *ngFor="let items of sizeData; let index = index">
      <div class="row" *ngIf="items.Status === 'active'">
        <div class="col-md-12">
          <h6 class="mb-0" style="text-transform: uppercase;font-weight: 800;">{{items?.Name}}</h6>
          <p style="font-size: 13px !important;">
            {{items?.Description}}
          </p>
        </div>
        <div class="col-md-2 p-2">
          <carousel style="width: 82vw;" [height]="getHeight(items?.Height,items?.Width)" [autoplay]="false"
            [loop]="true">
            <div class="carousel-cell" *ngFor="let items of thumps[index]">
              <div class="ribbon-wrapper" *ngIf="items.status == 'coming soon'">
                <div class="glow">&nbsp;</div>
                <div class="ribbon-front">
                  Coming Soon
                </div>
                <div class="ribbon-edge-topleft"></div>
                <div class="ribbon-edge-topright"></div>
                <div class="ribbon-edge-bottomleft"></div>
                <div class="ribbon-edge-bottomright"></div>
              </div>
              <div class="shadow-sm">
                <img [src]="items.thumbnail" [alt]="items.name" class=" border-light"
                  [ngClass]="{ selected: items.uniqueId === settings.selectedImageUniqueId }" appSelectable
                  (click)="items.status == 'coming soon' ?'' :open(content,items.uniqueId)"
                  [ngClass]="{'opacity_': items.status == 'coming soon'}">
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div> -->

    <div class="col-md-12 p-2" *ngFor="let items of tags; let index = index" [hidden]="check(items.group_name)">
      <div class="row" *ngIf="items.images.length > 0">
        <div class="col-md-12">
          <h6 class="mb-0" style="text-transform: uppercase;font-weight: 800;font-size: 20px;">{{items.group_name}}</h6>
          <p style="font-size: 13px !important;">
            Choose from hundreds of free ready-to-use templates for your next
            post
          </p>
        </div>
        <div class="col-md-2 p-2 " *ngFor="let img of items.images | imgFilter"
          [hidden]="activeIndex != 'All' ? trim(img.tags) != trim(activeIndex) : null">
          <!-- <carousel style="width: 82vw;" [autoplay]="false" [loop]="true"> -->
          <div>
            <div class="ribbon-wrapper" *ngIf="img.status == 'coming soon'">
              <div class="glow">&nbsp;</div>
              <div class="ribbon-front">
                Coming Soon
              </div>
              <div class="ribbon-edge-topleft"></div>
              <div class="ribbon-edge-topright"></div>
              <div class="ribbon-edge-bottomleft"></div>
              <div class="ribbon-edge-bottomright"></div>
            </div>
            <div class="shadow-sm">
              <img [src]="img.thumbnail" [alt]="img.name" class=" border-light"
                [ngClass]="{ selected: img.uniqueId === settings.selectedImageUniqueId }" appSelectable
                (click)="img.status == 'coming soon' ?'' :open(content,img.uniqueId)"
                [ngClass]="{'opacity_': img.status == 'coming soon'}">
            </div>
          </div>
          <!-- </carousel> -->
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="mt-5">
        <div class="col-md-12">
            <h5 class="mb-0">Upcoming Templates</h5>
            <p style="font-size: 13px !important;">New upcoming template designs
            </p>
        </div>
        <div class="row ml-2" *ngIf="imageSettings?.images.length > 0">
            <div class="col-md-2 p-2 " *ngFor="let img of coming">
                <div class=" shadow-sm ">
                    <img [src]="processImgUrl(img.img)" class=" border-light">
                </div>
            </div>
        </div>
    </div> -->

</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Birthday Template</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="col-md-12">
        <form [formGroup]="form" accept-charset="UTF-8" method="POST" target="_blank">
          <ng-container>
            <div class="row">
              <div class="col-sm-6" *ngFor="let field of fields">
                <div class="form-group">
                  <app-form-fields [options]="field" [form]="form"></app-form-fields>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row" *ngIf="uploadedFiles.length >0">
            <div class="upload-container">
              <div *ngFor="let item of uploadedFiles" class="preview-box">
                <label>{{item.label}}</label>
                <span (click)="cropImage(item.imgid)">
                  <img [src]="item.imgValue" class="img-fluid" />
                </span>
              </div>
            </div>
            <div class="row">
              <div class="modal-backdrop show" [ngStyle]="{'display':display}"></div>
              <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Adjust Image</h4>
                      <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()"><span
                          aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-8">
                          <image-cropper [imageBase64]="originalImage" [maintainAspectRatio]="true"
                            [containWithinAspectRatio]="true" [resizeToWidth]="currentimgWidth"
                            [cropperMinWidth]="currentimgWidth" [onlyScaleDown]="true" [roundCropper]="false"
                            [canvasRotation]="0" [transform]="transform" [alignImage]="'left'" [style.display]="null"
                            format="png" (imageCropped)="imageCropped($event)" [transform]="transform">
                          </image-cropper>
                        </div>
                        <div class="col-sm-4 crop-controls">
                          <div class="row mb-3">
                            <label>Rotate Image</label>
                            <ngx-slider [(value)]="rotateValue" (valueChange)=transformImage(1)
                              [options]="rotateSliderOptions"></ngx-slider>
                          </div>
                          <div class="row mb-3">
                            <label>Flip Image</label>
                            <div class="btn-group" role="group" aria-label="Basic example">
                              <button type="button" class="button btn-primary"
                                (click)="flipHorizontal()">Horizontal</button>
                              <button type="button" class="button btn-primary"
                                (click)="flipVertical()">Vertical</button>
                            </div>
                          </div>
                          <div class="row">
                            <label>Zoom Image</label>
                            <ngx-slider [(value)]="zoomValue" (valueChange)=transformImage(2)
                              [options]="zoomSliderOptions"></ngx-slider>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="button float-right btn-secondary" (click)="transform = {}">Reset
                        Image</button>
                      <button type="button" class="button float-right btn-primary" (click)="onCloseHandled()">Save &
                        Close</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <hr>
          <button type="submit" class="button float-right btn-primary" (click)="addText()">Apply
            Changes</button>
        </form>

        <!--  <div *ngIf="croppedImage.length >0">
                    <img [src]="croppedImage" height="200px" width="200px" class="img-list" />
                </div> -->
      </div>
    </form>
  </div>
</ng-template>