import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { CustomTags } from '../models/custom-tags.model';

@Injectable({
  providedIn: 'root'
})
export class TagsManagerService {

  constructor(private db: AngularFireDatabase) { }

  getTags() {
    return this.db.list<CustomTags>('/customTags')
      .snapshotChanges()
      .pipe(map(snaps => {
        return snaps.map(snap => {
          return snap.payload.val();/* {
            docId: snap.payload.key,
            data: snap.payload.val()
          } as {
            docId: string;
            data: CustomTags
          }; */
        });
      }));
  }

  addOrUpdateTag(docId, value: CustomTags) {
    return this.db.object('/customTags/' + docId).update(value);
  }
}
