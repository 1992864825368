import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  fullurl = window.location.href;
  active_nav = this.fullurl.substr(this.fullurl.indexOf('/#/') + 13);
  constructor() {}

  ngOnInit(): void {
    // this.active_nav =
    ////console.log(this.fullurl.substr(this.fullurl.indexOf("/#/") + 13))
  }
}
