import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { AdminLoginComponent } from '../admin/admin-login/admin-login.component';
import { ImageConfigComponent } from '../admin/image-config/image-config.component';
import { TemplateModelComponent } from '../admin/template-model/template-model.component';
import { AuthGuard } from '../shared/guard/auth.guard';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';


import { AccessReqComponent } from './modules/access-req/access-req.component';
import { UsersListComponent } from './modules/users-list/users-list.component';
import { ConfigComponent } from './modules/config/config.component';
import { SupportComponent } from './modules/support/support.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: AdminLoginComponent, canActivate: [AuthGuard] },
  {
    path: 'dashboard', component: AdminDashboardComponent,
    children: [
      { path: '', redirectTo: 'ImageSettings', pathMatch: 'full' },
      { path: 'ImageSettings', component: ImageConfigComponent, canActivate: [AuthGuard] },
      { path: 'template-model/:id', component: TemplateModelComponent, canActivate: [AuthGuard] },
      { path: 'template-model/:id/back', redirectTo: 'ImageSettings' },

      { path: 'accessreq', component: AccessReqComponent, canActivate: [AuthGuard] },
      { path: 'users', component: UsersListComponent, canActivate: [AuthGuard] },
      { path: 'config', component: ConfigComponent, canActivate: [AuthGuard] },
      { path: 'support', component: SupportComponent, canActivate: [AuthGuard] },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
