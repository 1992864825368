import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Router } from '@angular/router';
import { User } from './user';
import { NotyfService } from './notyf.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserDetails } from '../models/user-details.model';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any;
  todos$: AngularFireList<any[]>;
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public afs: AngularFirestore,
    private db: AngularFireDatabase,
    private notyf: NotyfService
  ) {
    /* this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        sessionStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(sessionStorage.getItem('user'));
      }
    }); */
  }
  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signUp(value: { email; password }) {
    return this.afAuth.createUserWithEmailAndPassword(
      value.email,
      value.password
    );
  }

  deleteAccount(email, password) {
    this.SignIn(email, password)
      .then((value) => {
        value.user
          .delete()
          .then((result) => {
            ////console.log('User deleted');
          })
          .catch((reason) => {
            ////console.log('User delete aayilla');
            ////console.log(reason);
          });
      })
      .catch((reason) => {
        ////console.log('User login polum aayilla');
        ////console.log(reason);
      });
  }

  createUser(
    uid,
    user: {
      displayName;
      emailId;
      imageData;
      phoneNo;
      designation;
      treb;
      reco;
      verify;
      status;
    }
  ) {
    return this.db.object('/users/' + uid).set(user);
  }
  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user'));
    /* if (user === null) {
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.userData = user;
          sessionStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(sessionStorage.getItem('user'));
          return (user) ? true : false;
        }
      })
    } */
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return user !== null ? true : false;
  }
  get isAdminLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('admin'));
    /* if (user === null) {
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.userData = user;
          sessionStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(sessionStorage.getItem('user'));
          return (user) ? true : false;
        }
      })
    } */
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return user !== null ? true : false;
  }
  UpdateUserData(displayName, photoUrl, phoneNo, designation) {
    const user = localStorage.getItem('uid');
    const tutRef = this.db
      .object('/users/' + user)
      .update({ displayName, imageData: photoUrl, phoneNo, designation });
  }
  getUsers() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.db
            .object('/users/' + user.uid)
            .snapshotChanges()
            .subscribe((snapshots) => {
              resolve(snapshots);
            });
        }
      });
    });
  }

  SetUserData(user) {
    ////console.log(user)
    /* const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);*/
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      phoneNumber: user.phoneNumber,
      verify: user.verify,
      status: user.status,
    };
    sessionStorage.setItem('user', JSON.stringify(userData));

    return new Promise((resolve, reject) => {
      // sessionStorage.setItem('user', JSON.stringify(userData));
      this.db
        .object('/users/' + user.uid)
        .valueChanges()
        .subscribe((response) => {
          response['uid'] = user.id;
          resolve(response);
        });
    });
    /* return userRef.set(userData, {
      merge: true
    }); */
  }

  checkAdminData(user) {
    //debugger
    return new Promise((resolve, reject) => {
      this.db
        .list<any>('users', (ref) => ref.orderByChild('isAdmin').equalTo(true))
        .valueChanges()
        .subscribe((value) => {
          if (value.length > 0) {
            for (const u of value) {
              if (u.emailId == user.email) {
                const userData: User = {
                  uid: user.uid,
                  email: user.email,
                  displayName: user.displayName,
                  photoURL: user.photoURL,
                  emailVerified: user.emailVerified,
                  phoneNumber: user.phoneNumber,
                  verify: user.verify,
                  status: user.status,
                };
                sessionStorage.setItem('admin', JSON.stringify(userData));
                resolve(userData);
              }
            }
            reject('No such admin found');
          } else {
            reject('No admins found');
          }
        });
    });
  }
  SignOut() {
    return this.afAuth.signOut().then(() => {
      sessionStorage.removeItem('user');
      this.router.navigateByUrl('login');
      this.notyf.warning('Logged Out');
    });
  }

  getAllEmails(): Observable<string[]> {
    return this.db
      .list<any>('users')
      .valueChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((snap) => {
            return snap.emailId;
          });
        })
      );
  }

  sendPasswordReset(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }
}
