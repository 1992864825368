import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { SignUpComponent } from './sign-up/sign-up.component';

const routes: Routes = [
  // { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'sign-up', component: SignUpComponent, canActivate: [AuthGuard] },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import(`./modules/client/client.module`).then((m) => m.ClientModule),
  },
  // {
  //   path: 'admin',
  //   loadChildren: () =>
  //     import(`./admin/admin.module`).then((m) => m.AdminModule),
  // },
  {
    path: 'home',
    loadChildren: () => import(`./home/home.module`).then((m) => m.HomeModule),
  },
  // { path: 'canva', component: CanvaComponent },

  /////////////////////////////////////////////////////////
  {
    path: 'admin',
    loadChildren: () =>
      import(`./modules/admin/admin.module`).then((m) => m.AdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
