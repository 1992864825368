<!-- <div class="overlay" [style.width.px]="sizeSettings.sizes[sizeSettings.selectedSizeIndex].width" [style.height.px]="sizeSettings.sizes[sizeSettings.selectedSizeIndex].height" [hidden]="logoSettings.isGraphicHidden">
    <div class="overlay-bounds" *ngIf="logoSettings.selectedFile" appMoveClampedToParent [style.width.px]="logoSettings.size" [style.height.px]="logoSettings.size" [ngClass]="{ selected: isSelected }" (mousedown)="onSelect()">
        <div class="logo" [style.width.px]="logoSettings.size" [style.height.px]="logoSettings.size" [style.border-radius.%]="logoSettings.radius/2" [ngStyle]="{'background-image': 'url(' + logoSettings.selectedFile.url + ')', 'background-size': logoSettings.size + 'px'}">
        </div>
    </div>
</div> -->

<!-- <div class="overlay" *ngFor="let layer of settings.logoSettings.images; let i=index;" [style.width.px]="sizeData?.Width"
  [style.height.px]="sizeData?.Height" [hidden]="layer.isGraphicHidden"> -->
  <div class="overlay-bounds" *ngIf="layer.selectedFile" [appMoveClampedToParent]="settings.isWkFlow"
    [style.width.px]="layer.width" [style.height.px]="layer.height" [style.top.px]="layer.top"
    [style.left.px]="layer.left" [ngClass]="{ selected: isSelected }" (mousedown)="onSelect()"
    (click)="cropImage(content,i)">
    <!-- <div #imgLay class="layer resizable-draggable" [style.width.px]="layer.width" [style.height.px]="layer.height"
      [ngStyle]="{'background-image': 'url(' + layer.selectedFile + ')', 'background-size': 'cover', 'background-position': 'center center', 'background-repeat':'no-repeat'}"
      [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2,layer.uniqueId)"
      (window:mouseup)="setStatus($event, 0,layer.uniqueId)">
    </div> -->
    <img #imgLay class="layer resizable-draggable" [style.width.px]="layer.width" [style.height.px]="layer.height"
      [src]="getImageUrl(layer)" [style.object-fit]="getImageFit()" [class.active]="status === 1 || status === 2"
      (mousedown)="setStatus($event, 2,layer.uniqueId)" (window:mouseup)="setStatus($event, 0,layer.uniqueId)">
    <div class="resize-action" (mousedown)="setStatus($event, 1,layer.uniqueId)"></div>
  </div>
<!-- </div> -->
<ng-template #content let-modal>

  <div class="moda">
    <form>
      <div class="col-md-12">
        <form accept-charset="UTF-8" method="POST" target="_blank">
          <ng-container>
            <div class="row">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Image Editor</h4>
                  <div>
                    <button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">X</span>
                    </button>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-4">
                      <!-- <label style="font-size: 12px;">Select Image</label> -->
                      <input type="file" accept="image/*" #file (change)="onUpload($event)">
                      <!-- <button style="margin-right: 10px;" (click)="file.click()" type="button"
                        class="btn btn-secondary">
                        Select Image
                      </button> -->
                    </div>
                    <div class="col-md-8 fliping" style="    text-align: right;">
                      <!-- <label style="font-size: 12px;">Flip</label> -->
                      <div>
                        <img *ngIf="layer.type == 'photo'" [src]="DefaultDP" (click)="setDPasPic()"
                          style="background-color: rgb(248, 248, 248); width: 40px; padding: 7px; margin-right: 10px ;"
                          title="Click here to apply your DP as Image">
                        <img src="../../../../assets/img/brand/left.png" (click)="flipHorizontal()" style="background-color: rgb(248, 248, 248);
                        width: 40px; padding: 7px; margin-right: 10px ;">
                        <img src="../../../../assets/img/brand/flip.png" (click)="flipVertical()" style="background-color: rgb(248, 248, 248);
                      width: 40px;padding: 7px;  margin-right: 10px ;">
                        <img src="../../../../assets/img/brand/phone.png" (click)="transformImage(1)"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;  margin-right: 10px ;">
                        <img *ngIf="isMAR" src="../../../../assets/img/brand/expand-screen.png" (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;  margin-right: 10px ;">
                        <img *ngIf="!isMAR" src="../../../../assets/img/brand/minimize-arrows.png"
                          (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;  margin-right: 10px ;">
                      </div>
                      <!-- <div class="col-12" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-primary me-1"
                          (click)="flipHorizontal()">Horizontal</button>
                        <button type="button" class="btn btn-primary" (click)="flipVertical()">Vertical</button>
                      </div> -->
                    </div>
                    <!-- <div class="col-md-2  rotate"> -->
                    <!-- <label style="font-size: 12px;">Rotate</label> -->
                    <!-- <ngx-slider [(value)]="rotateValue" (valueChange)=transformImage(1)
                        [options]="rotateSliderOptions"></ngx-slider> -->
                    <!-- <div>
                        <img src="../../../../assets/img/brand/phone.png" (click)="transformImage(1)"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                      </div>
                    </div>
                    <div class="col-md-2  rotate"> -->
                    <!-- <label style="font-size: 12px;">Fit Full Image</label> -->
                    <!-- <ngx-slider [(value)]="rotateValue" (valueChange)=transformImage(1)
                        [options]="rotateSliderOptions"></ngx-slider> -->
                    <!-- <div>
                        <img *ngIf="isMAR" src="../../../../assets/img/brand/expand-screen.png" (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                        <img *ngIf="!isMAR" src="../../../../assets/img/brand/minimize-arrows.png"
                          (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                      </div> -->
                    <!-- </div> -->
                    <div class="col-md-12">
                      <label style="font-size: 12px;">Zoom Image</label>
                      <ngx-slider [(value)]="zoomValue" (valueChange)=transformImage(2) [options]="zoomSliderOptions">
                      </ngx-slider>
                    </div>
                    <div class="col-md-12">
                      <image-cropper [imageBase64]="originalImage" [maintainAspectRatio]="isMAR"
                        [containWithinAspectRatio]="false" [aspectRatio]="ratio" [onlyScaleDown]="true"
                        [roundCropper]="false" [canvasRotation]="0" [transform]="transform" [alignImage]="'center'"
                        [style.display]="null" format="png" (imageCropped)="imageCropped($event)" [cropper]="cropperPos"
                        (cropperReady)="cropperReady()">
                      </image-cropper>
                    </div>


                  </div>
                </div>
                <div class="modal-footer">
                  <button style="margin-right: 10px;" type="button" class="btn float-right btn-secondary"
                    (click)="resetTransformations()">Reset
                    Image</button>
                  <button type="button" class="btn btn-primary float-right" (click)="onCloseHandled()">Save &
                    Close</button>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </form>
  </div>
</ng-template>
