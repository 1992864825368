import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  AfterViewInit,
  HostListener,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { CustomFont } from 'src/app/shared/models/custom-font.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommService } from 'src/app/shared/services/comm.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { EditTextComponent } from '../edit-text/edit-text.component';
const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2,
}

@Component({
  selector: 'app-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.css'],
})
export class EditableTextComponent implements AfterViewInit {
  public userDetails;
  public address = '';
  public loopExecuting = null;
  private changeFactor = 1;

  @ViewChild('textInput', { read: ElementRef }) textInput;
  @ViewChild('dummytext') dummytext: ElementRef;

  @Input() model: any;
  @Input() options: any;
  @Input() elementId: number;
  @Input() font: CustomFont;

  @Input('width') public width: number;
  @Input('height') public height: number;
  @Input('left') public left: number;
  @Input('top') public top: number;
  @ViewChild('box') public box: ElementRef;
  @Output() onResized: EventEmitter<any> = new EventEmitter<any>();
  private boxPosition: { left: number; top: number };
  private containerPos: {
    left: number;
    top: number;
    right: number;
    bottom: number;
  };
  public mouse: { x: number; y: number };
  public status: Status = Status.OFF;
  private mouseClick: { x: number; y: number; left: number; top: number };

  isEditing: boolean;

  textEditorEnabled = false;
  DefaultFontSize = 0;
  DefaultBoxWidth = 0;
  DefaultBoxTop = 0;
  DefaultBoxLeft = 0;

  constructor(
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private comm: CommService,
    private el: ElementRef,
    private authService: AuthService,
    private udService: UserDetailsService
  ) {
    this.el = el.nativeElement;
    this.authService.getUsers().then((result) => {
      this.userDetails = {
        name: result.payload.val().displayName,
        designation: result.payload.val().designation,
        email: result.payload.val().emailId,
        phone: result.payload.val().phoneNo,
        photoUrl: result.payload.val().imageData,
      };
      this.udService.getDetails().subscribe((value) => {
        if (value.length == 1) {
          this.address = value[0].userData.address;
        }
        this.addUserData();
      });
    });
  }

  ngAfterViewInit() {
    this.loadBox();
    this.loadContainer();
    setTimeout(() => {
      if (this.model.size) {
        this.DefaultFontSize = this.model.size * 1 + 1 - 1;
        this.DefaultBoxWidth = this.model.width * 1 + 1 - 1;
        this.DefaultBoxTop = this.model.top * 1 + 1 - 1;
        this.DefaultBoxLeft = this.model.left * 1 + 1 - 1;
      }
    }, 500);

    this.cd.detectChanges();
    // Incoming Text Edit Data to check if Editor is still needed
    this.comm.textEditDataTransfered.subscribe((value) => {
      if (value.elementId != this.elementId) {
        this.textEditorEnabled = false;
      }
    });
    // Incoming Font Size Data
    this.comm.fontSizeValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        if (this.loopExecuting == null) {
          this.fontSizeChanged(value.data.size);
        }
      }
    });
    // Incoming Font Size Function Calls
    this.comm.fontIncreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.increaseFontSize();
      }
    });
    this.comm.fontDecreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.reduceFontSize();
      }
    });
    this.comm.fontValueResetData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.resetFontToDefault();
      }
    });
    // Incoming Font Auto Adjustment Function Calls
    this.comm.fontStartAutoAdjustData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.startAutoAdjustment();
      }
    });
    this.comm.fontStopAutoAdjustData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.stopAutoAdjustment();
      }
    });
    // Incoming Text Change Value
    this.comm.textData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.textValueChanged(value.data.text);
      }
    });
    // Incoming Box Top Data
    this.comm.boxTopValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.topChangedEvent(value.data.top);
      }
    });
    // Incoming Box Top Function Calls
    this.comm.boxTopIncreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.increaseTop();
      }
    });
    this.comm.boxTopDecreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.reduceTop();
      }
    });
    this.comm.boxTopValueResetData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.resetTopToDefault();
      }
    });
    // Incoming Box Left Data
    this.comm.boxLeftValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.leftChangedEvent(value.data.left);
      }
    });
    // Incoming Box Left Function Calls
    this.comm.boxLeftIncreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.increaseLeft();
      }
    });
    this.comm.boxLeftDecreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.reduceLeft();
      }
    });
    this.comm.boxLeftValueResetData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.resetLeftToDefault();
      }
    });
    // Incoming Box Width Data
    this.comm.boxWidthtValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        if (this.loopExecuting == null) {
          this.widthChangedEvent(value.data.width);
        }
      }
    });
    // Incoming Box Width Function Calls
    this.comm.boxWidthIncreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.increaseWidth();
      }
    });
    this.comm.boxWidthDecreaseValueData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.reduceWidth();
      }
    });
    this.comm.boxWidthValueResetData.subscribe((value) => {
      if (this.textEditorEnabled && value.elementId == this.elementId) {
        this.resetWidthToDefault();
      }
    });
  }

  correctFontSize(loopTime) {
    if (this.loopExecuting != null && this.loopExecuting == loopTime) {
      let flag = false;
      const textWidth = this.dummytext.nativeElement.offsetWidth;
      const textHeight = this.dummytext.nativeElement.offsetHeight;
      const heightAdjust =
        this.model.label.toLowerCase() == 'email'
          ? 0
          : this.heightAdjuster(this.model.height);
      const threshold = 4;
      ////console.log('%c For ' + this.model.text, 'color: red; font-weight: bold; background-color: white;');
      ////console.log('WIDTH');
      ////console.log('model width = ' + this.model.width);
      ////console.log('text width = ' + textWidth);
      ////console.log('HEIGHT');
      ////console.log('model height = ' + this.model.height);
      ////console.log('text height = ' + textHeight);
      ////console.log('height adjust = ' + heightAdjust);
      ////console.log('Before Font Size = ' + this.model.size);
      if (textHeight > 0 && textWidth > 0) {
        const x = this.model.height * 1 + heightAdjust * 1;
        if (this.model.width <= textWidth || x <= textHeight) {
          ////console.log('Size reduced');
          this.modifyChangeFactor(textHeight - x, textWidth - this.model.width);
          this.model.size = this.model.size - this.changeFactor;
          flag = true;
        } else if (
          this.model.width > textWidth + threshold &&
          x > textHeight + threshold
        ) {
          ////console.log('Size increased');

          this.modifyChangeFactor(textHeight - x, textWidth - this.model.width);
          this.model.size = this.model.size + this.changeFactor;
          flag = true;
        }
        ////console.log('After Font Size = ' + this.model.size);
        this.comm.setFontSize(this.elementId, this.model.size);

        if (flag) {
          setTimeout(() => {
            this.correctFontSize(this.loopExecuting);
          }, 100);
        } else {
          this.loopExecuting = null;
          this.comm.setFontAjdustLoopValue(this.elementId, this.loopExecuting);
          this.changeFactor = 1;
        }
      } /*  else {
        this.loopExecuting = Date.now();
        setTimeout(() => {
          this.correctFontSize(this.loopExecuting);
        }, 500);
      } */
    }
  }

  heightAdjuster(value: number): number {
    if (value > 80) {
      return value * 0.45;
    } else {
      return value * 0.4;
    }
  }

  startAutoAdjustment(): void {
    this.loopExecuting = Date.now();
    this.comm.setFontAjdustLoopValue(this.elementId, this.loopExecuting);
    this.correctFontSize(this.loopExecuting);
  }

  stopAutoAdjustment(): void {
    this.loopExecuting = null;
    this.comm.setFontAjdustLoopValue(this.elementId, this.loopExecuting);
  }

  modifyChangeFactor(heightDifference: number, widthDifference: number) {
    heightDifference =
      heightDifference < 0 ? heightDifference * -1 : heightDifference;
    widthDifference =
      widthDifference < 0 ? widthDifference * -1 : widthDifference;
    const keyDifference =
      heightDifference < widthDifference ? heightDifference : widthDifference;
    if (keyDifference <= 10) {
      this.changeFactor = 0.02;
    } else if (keyDifference <= 20) {
      this.changeFactor = 0.1;
    } else {
      this.changeFactor = 0.15;
    }
    ////console.log(this.changeFactor);
  }

  get LatestDate() {
    return Date.now();
  }
  private loadBox() {
    const { left, top } = this.box.nativeElement.getBoundingClientRect();
    const e = this.box.nativeElement.getBoundingClientRect();
    const ls = Number(e.left) / 2;
    const tf = Number(e.top) / 2;
    this.boxPosition = { left, top };
    this.boxPosition.left = ls;
    this.boxPosition.top = tf;
  }

  private loadContainer() {
    const left = Number(this.boxPosition.left - this.left) / 2;
    const top = Number(this.boxPosition.top - this.top) / 2;
    const right = Number(left + this.width) / 2;
    const bottom = Number(top + this.height) / 2;
    this.containerPos = { left, top, right, bottom };
  }
  public reset() {
    // update edit flag
    this.isEditing = false;
  }

  public addEditTextControls(editTextComponent: EditTextComponent) {
    // this.renderer.invokeElementMethod(this.el, 'appendChild', [editTextComponent.el]);
    // this.renderer.appendChild(this.el,editTextComponent.el);
  }

  onDoubleClick($event: any) {
    // update edit flag
    this.isEditing = true;

    // update textInput
    // this.renderer.invokeElementMethod(this.textInput.nativeElement, 'focus', []);
    this.renderer.selectRootElement(this.textInput.nativeElement).focus(); // ['focus'].apply(this.textInput.nativeElement);
    // this.renderer.invokeElementMethod(this.textInput.nativeElement, 'select', []);
    // this.renderer['select'].apply(this.textInput.nativeElement);
    this.renderer.selectRootElement(this.textInput.nativeElement).select();
  }
  setStatus(event: MouseEvent, status: number) {
    if (status === 1) {
      event.stopPropagation();
    } else if (status === 2) {
      this.mouseClick = {
        x: event.clientX,
        y: event.clientY,
        left: this.left,
        top: this.top,
      };
    } else {
      this.loadBox();
    }
    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = { x: event.clientX, y: event.clientY };
    if (this.status === Status.RESIZE) {
      this.resize();
    }
  }

  private resize() {
    this.width = Number(this.mouse.x > this.boxPosition.left)
      ? this.mouse.x - this.boxPosition.left
      : 0;
    this.height = Number(this.mouse.y > this.boxPosition.top)
      ? this.mouse.y - this.boxPosition.top
      : 0;
    this.onResized.emit({
      width: this.width,
      height: this.height,
      uniqueId: this.model.uniqueId,
    });
  }

  private resizeCondMeet() {
    return (
      Number(this.mouse.x) / 2 < this.containerPos.right &&
      Number(this.mouse.y) / 2 < this.containerPos.bottom
    );
  }

  addUserData() {
    switch (this.model.label.toLowerCase()) {
      case 'name':
        if (this.userDetails?.name) {
          this.model.text = this.userDetails.name;
        }
        break;
      case 'address':
        if (this.address) {
          this.model.text = this.address;
        }
        break;
      case 'designation':
        if (this.userDetails?.designation) {
          this.model.text = this.userDetails.designation;
        }
        break;
      case 'email':
        if (this.userDetails?.email) {
          this.model.text = this.userDetails.email;
        }
        break;
      case 'phone':
        if (this.userDetails?.phone) {
          this.model.text = this.userDetails.phone;
        }
        break;
    }

    this.cd.detectChanges();
  }

  PSLetterSpacing(value): number {
    return value / 1000;
  }

  openFontEditor() {
    if (this.textEditorEnabled) {
      // Close Editor
      this.textEditorEnabled = false;
      this.comm.sendTextEditData(-1, null);
    } else {
      // Open Editor
      this.textEditorEnabled = true;
      this.comm.sendTextEditData(this.elementId, {
        text: this.model.text,
        size: this.model.size,
        loopExecuting: this.loopExecuting,
        top: this.model.top,
        left: this.model.left,
        width: this.model.width,
      });
    }
  }
  // Font Size Funtions
  resetFontToDefault() {
    this.model.size = this.DefaultFontSize * 1 + 1 - 1;
    this.comm.setFontSize(this.elementId, this.model.size);
  }

  reduceFontSize() {
    this.model.size--;
    this.comm.setFontSize(this.elementId, this.model.size);
  }

  increaseFontSize() {
    this.model.size++;
    this.comm.setFontSize(this.elementId, this.model.size);
  }

  fontSizeChanged(value) {
    this.model.size = value;
  }
  // Text Function
  textValueChanged(value) {
    this.model.text = value;
  }
  // Box Top Functions
  resetTopToDefault() {
    this.model.top = this.DefaultBoxTop * 1 + 1 - 1;
    this.comm.setBoxTop(this.elementId, this.model.top);
  }

  reduceTop() {
    this.model.top--;
    this.comm.setBoxTop(this.elementId, this.model.top);
  }

  increaseTop() {
    this.model.top++;
    this.comm.setBoxTop(this.elementId, this.model.top);
  }

  topChangedEvent(value) {
    this.model.top = value;
  }
  // Box Left Functions
  resetLeftToDefault() {
    this.model.left = this.DefaultBoxLeft * 1 + 1 - 1;
    this.comm.setBoxLeft(this.elementId, this.model.left);
  }

  reduceLeft() {
    this.model.left--;
    this.comm.setBoxLeft(this.elementId, this.model.left);
  }

  increaseLeft() {
    this.model.left++;
    this.comm.setBoxLeft(this.elementId, this.model.left);
  }

  leftChangedEvent(value) {
    this.model.left = value;
  }
  // Box Width Functions
  resetWidthToDefault() {
    this.model.width = this.DefaultBoxWidth * 1 + 1 - 1;
    this.comm.setBoxWidth(this.elementId, this.model.width);
  }

  reduceWidth() {
    this.model.width--;
    this.comm.setBoxWidth(this.elementId, this.model.width);
  }

  increaseWidth() {
    this.model.width++;
    this.comm.setBoxWidth(this.elementId, this.model.width);
  }

  widthChangedEvent(value) {
    this.model.width = value;
  }
}
