<div class="container">
  <div class="row">
    <div class="mb-3 mt-3">
      <p class="mb-0">
        <span class="sidebar-icon material-icons-outlined" routerLink="back">
          arrow_back
        </span>
        Template Details
      </p>
      <small>Make / change template details</small>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col-md-6">
        <ng-container *ngIf="!isTemplate">
          <div class="row">
            <div class="col-md-6">
              <p class="mb-0" style="font-size: 20px;font-weight: 600;">
                Images
              </p>
              <small>add template images</small>
            </div>
            <div class="col-md-6" style="text-align:right">
              <button type="button" class="btn btn-primary mb-2 edit_btn_style" (click)="addTemplate('Image')">Add New
                Image</button>
            </div>
          </div>
          <div class="table-responsive-lg">
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">x</th>
                  <th scope="col">y</th>
                  <th scope="col">w</th>
                  <th scope="col">h</th>
                  <th scope="col">Action</th>
                  <!--   <th scope="col">Duplicate</th>
                  <th scope="col">Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of images; let i =index">
                  <ng-container *ngIf="!item.isDeleted">
                    <th scope="row">{{i+1}}</th>
                    <td scope="col">{{item.text}}</td>
                    <td scope="col">{{item.left}} px</td>
                    <td scope="col">{{item.top}} px</td>
                    <td scope="col">{{item.width}} px</td>
                    <td scope="col">{{item.height}} px</td>
                    <!-- <td scope="col"><button class="btn btn-primary btn-xs"
                        (click)="editSettings(item,i,'Image')">Edit</button></td>
                    <td scope="col"><button class="btn btn-warning btn-xs"
                        (click)="duplicateSettings(item,i,'Image')">Duplicate</button></td>-->
                    <!--  <td>
                      <button class="btn btn-danger btn-xs"
                        (click)="deleteSettings(i,'Image')">Delete</button>
                    </td>-->

                    <td scope="col">
                      <div class="dropdown">
                        <button type="button" class="btn btn-sm fs-6 px-1 py-0 dropdown-toggle" id="dropdownMenuLink"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <svg class="icon icon-xs" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z">
                            </path>
                          </svg>
                        </button>
                        <div class="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1" style="">

                          <a (click)="editSettings(item,i,'Image')" class="dropdown-item d-flex align-items-center">
                            <svg class="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z">
                              </path>
                            </svg>
                            Edit
                          </a>

                          <a (click)="duplicateSettings(item,i,'Image')"
                            class="dropdown-item d-flex align-items-center">
                            <svg class="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                              <path
                                d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z">
                              </path>
                            </svg>
                            Duplicate
                          </a>
                          <div role="separator" class="dropdown-divider my-1"></div>
                          <a (click)="deleteSettings(i,'Image')" class="dropdown-item d-flex align-items-center">
                            <svg class="dropdown-icon text-danger me-2" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clip-rule="evenodd"></path>
                            </svg>
                            Remove
                          </a>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>

        <ng-container *ngIf="!isTemplate">
          <div class="row">
            <div class="col-md-6">
              <p class="mb-0" style="font-size: 20px;font-weight: 600;">
                Texts
              </p>
              <small>add template text fields</small>
            </div>
            <div class="col-md-6" style="text-align:right">
              <button type="button" class="btn btn-primary mb-2 edit_btn_style" (click)="addTemplate('Text')">Add New
                Text</button>
            </div>
          </div>
          <!-- <div class="row">
            <h3>Texts</h3>
            <div class="col-6">
              <button type="button" class="btn btn-primary mb-2" (click)="addTemplate('Text')">Add</button>
            </div>
          </div> -->
          <div class="table-responsive-lg">
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">x</th>
                  <th scope="col">y</th>
                  <th scope="col">w</th>
                  <th scope="col">h</th>
                  <th scope="col">Actions</th>
                  <!-- <th scope="col">Duplicate</th>
                  <th scope="col">Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of texts; let i =index">
                  <ng-container *ngIf="!item.isDeleted">
                    <th scope="row">{{i+1}}</th>
                    <td scope="col">{{item.text.substring(0,25) }}</td>
                    <td scope="col">{{item.left}} px</td>
                    <td scope="col">{{item.top}} px</td>
                    <td scope="col">{{item.width}} px</td>
                    <td scope="col">{{item.height}} px</td>
                    <!-- <td scope="col"><button class="btn btn-primary btn-xs"
                        (click)="editSettings(item,i,'Text')">Edit</button></td>
                    <td scope="col"><button class="btn btn-warning btn-xs"
                        (click)="duplicateSettings(item,i,'Text')">Duplicate</button></td>
                    <td scope="col"><button class="btn btn-danger btn-xs"
                        (click)="deleteSettings(i,'Text')">Delete</button>
                    </td> -->
                    <td scope="col">
                      <div class="dropdown">
                        <button type="button" class="btn btn-sm fs-6 px-1 py-0 dropdown-toggle" id="dropdownMenuLink"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <svg class="icon icon-xs" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z">
                            </path>
                          </svg>
                        </button>
                        <div class="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1" style="">

                          <a (click)="editSettings(item,i,'Text')" class="dropdown-item d-flex align-items-center">
                            <svg class="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z">
                              </path>
                            </svg>
                            Edit
                          </a>

                          <a (click)="duplicateSettings(item,i,'Text')" class="dropdown-item d-flex align-items-center">
                            <svg class="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                              <path
                                d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z">
                              </path>
                            </svg>
                            Duplicate
                          </a>
                          <div role="separator" class="dropdown-divider my-1"></div>
                          <a (click)="deleteSettings(i,'Text')" class="dropdown-item d-flex align-items-center">
                            <svg class="dropdown-icon text-danger me-2" fill="currentColor" viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clip-rule="evenodd"></path>
                            </svg>
                            Remove
                          </a>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>

        <ng-container *ngIf="isTemplate">
          <form #addForm="ngForm" class=" mt-3 ml-0" (ngSubmit)="saveTemplate()">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{this.addType}} Type</label>
                  <input type="text" class="form-control input_custom_design" [(ngModel)]="type"
                    (keyup)="hideText(); hideImage()" name="type" />
                </div>
              </div>
              <div class="col-md-6"
                *ngIf="addType == 'Image' && type?.trim().toLowerCase() != 'photo' && type?.trim().toLowerCase() != 'house'">
                <div class="form-group">
                  <label>Add File</label>
                  <input type="text" accept="image/*" [(ngModel)]="imageUrl" name="selectedFile"
                    class="form-control input_custom_design" />
                  <!-- <input type="file" accept="image/*" class="form-control"
                    (change)="fileUpload($event); hideText(); hideImage()" required /> -->
                </div>
              </div>
              <div class="col-md-6"
                *ngIf="addType == 'Image' && type?.trim().toLowerCase() != 'photo' && type?.trim().toLowerCase() != 'house'">
                <div class="form-group">
                  <label>Add File</label>
                  <!-- <input type="text" accept="image/*" [(ngModel)]="imageUrl" name="selectedFile"
                    class="form-control input_custom_design" /> -->
                  <input type="file" accept="image/*" (change)="fileUpload($event); hideText(); hideImage()" required />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Text</label>
                  <input type="text" class="form-control input_custom_design" [(ngModel)]="text"
                    (keyup)="hideText(); hideImage()" name="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Label</label>
                  <input type="text" class="form-control input_custom_design" [(ngModel)]="label"
                    (keyup)="hideText(); hideImage()" name="label" />
                </div>
              </div>

              <div class="row" *ngIf="addType!=='Image'">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Font</label>
                    <select class="form-control input_custom_design" required [(ngModel)]="fontName" name="fontName"
                      (change)="hideText()">
                      <option value="">Select Font</option>
                      <option *ngFor="let f of fontsData" [value]="f.Name">{{f.Name}}</option>
                    </select>
                  </div>
                  <div><button style="padding: 0px 10px;
                    border: none;float: right;
                    font-size: 12px;" class="" type="button" (click)="openAddFontModal()">Add new
                      font</button>
                  </div>
                  <!-- <div><button class="btn btn-outline-secondary" type="button" (click)="openAddFontModal()">Add</button>
                  </div> -->
                </div>
                <!-- <div class="col">
                <div class="form-group">
                  <label>Font Family</label>
                  <input type="text" class="form-control" [(ngModel)]="fontFamily" (keyup)="hideText()"
                    name="fontFamily" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Size</label>
                  <select class="form-control" [(ngModel)]="sizeIndex" (change)="hideText()" name="sizeIndex">
                    <option value=-1>Choose</option>
                    <option value=0>Normal</option>
                    <option value=1>Large</option>
                    <option value=2>Largest</option>
                  </select>
                </div>
              </div> -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Align</label>
                    <select class="form-control input_custom_design" [(ngModel)]="alignIndex" (change)="hideText()"
                      name="alignIndex">
                      <option value=-1>Choose</option>
                      <option value=0>Left</option>
                      <option value=1>Center</option>
                      <option value=2>Right</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-12 row" *ngIf="addType!=='Image'">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Text Color</label>
                    <input type="text" class="form-control input_custom_design" [(ngModel)]="tColor"
                      (keyup)="hideText()" name="tColor" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>B Color</label>
                    <input type="text" class="form-control input_custom_design" [(ngModel)]="bColor"
                      (keyup)="hideText()" name="bColor" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Font Size</label>
                    <input type="text" class="form-control input_custom_design" [(ngModel)]="size" (keyup)="hideText()"
                      name="size" />
                  </div>
                </div>
              </div>
              <!-- <div class="row"> -->
              <div class="col-md-3">
                <div class="form-group">
                  <label>x-axis</label>
                  <input type="text" class="form-control input_custom_design" [(ngModel)]="left"
                    (keyup)="hideText(); hideImage()" name="left" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>y-axis</label>
                  <input type="text" class="form-control input_custom_design" [(ngModel)]="top"
                    (keyup)="hideText(); hideImage()" name="top" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Width</label>
                  <input type="text" class="form-control input_custom_design" [(ngModel)]="width"
                    (keyup)="hideText(); hideImage()" name="width" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Height</label>
                  <input type="text" class="form-control input_custom_design" [(ngModel)]="height"
                    (keyup)="hideText(); hideImage()" name="height" />
                </div>
              </div>
              <!-- </div> -->
              <div class="col-md-12  mb-3" *ngIf="addType!=='Image'">
                <div class="row">
                  <!-- <div class="form-check">
                  <label class="form-check-label">Bold</label>
                  <input type="checkbox" class="form-check-input" [(ngModel)]="isBold" (change)="hideText()"
                    name="isBold" />
                </div> -->
                  <div class=" col-md-4 form-check pl-0">
                    <label class="form-check-label">UpperCase</label>
                    <input type="checkbox" style="    margin-left: 7px;
                  margin-top: 7px;" class="form-check-input " [(ngModel)]="isUpper" (change)="hideText()"
                      name="isUpper" />
                  </div>
                  <div class="col-md-4 form-check pl-0">
                    <label class="form-check-label">LowerCase</label>
                    <input type="checkbox" style="    margin-left: 7px;
                  margin-top: 7px;" class="form-check-input " [(ngModel)]="isLower" (change)="hideText()"
                      name="isLower" />
                  </div>
                  <div class="col-md-4 form-check pl-0">
                    <label class="form-check-label">Italic</label>
                    <input type="checkbox" style="    margin-left: 7px;
                  margin-top: 7px;" class="form-check-input " [(ngModel)]="isItalic" (change)="hideText()"
                      name="isItalic" />
                  </div>
                </div>
                <div class="row">
                  <div class=" col-md-4 form-check pl-0">
                    <label class="form-check-label">Underline</label>
                    <input type="checkbox" style="    margin-left: 7px;
                  margin-top: 7px;" class="form-check-input " [(ngModel)]="isUnderLined" (change)="hideText()"
                      name="isUnderLined" />
                  </div>
                  <div class="col-md-4 form-check pl-0">
                    <label class="form-check-label">Strikethrough</label>
                    <input type="checkbox" style="    margin-left: 7px;
                  margin-top: 7px;" class="form-check-input " [(ngModel)]="isStrikeThrough" (change)="hideText()"
                      name="isStrikeThrough" />
                  </div>
                  <div class="col-md-4 form-check pl-0">
                    <label class="form-check-label">Overline</label>
                    <input type="checkbox" style="    margin-left: 7px;
                  margin-top: 7px;" class="form-check-input " [(ngModel)]="isOverLined" (change)="hideText()"
                      name="isOverLined" />
                  </div>
                </div>
                <div class="row">
                  <!-- TODO: line height 85% is needed to keep it inside the box. But if we change that, it will break outside the whole box -->
                  <!-- <div class="col-md-4">
                    <div class="form-group">
                      <label>Line Height</label>
                      <input type="text" class="form-control input_custom_design" [(ngModel)]="lineHeight"
                        (keyup)="hideText()" name="tColor" />
                    </div>
                  </div> -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Letter Spacing</label>
                      <input type="text" class="form-control input_custom_design" [(ngModel)]="letterSpacing"
                        (keyup)="hideText()" name="letterSpacing" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-md-6">
                  <button class="btn btn-danger edit_btn_style  mr-2" (click)="clearImageDetailsField()">Cancel</button>
                  <button type="submit" class="btn btn-success mr-2 edit_btn_style"
                    [disabled]="addForm.invalid ">Submit</button>
                </div>
                <div class="col-md-6 text-right">
                  <button *ngIf="addType!=='Image'" class="btn btn-info" type="button" (click)="renderText()"
                    [disabled]="addForm.invalid">Render
                    TextField</button>

                  <button *ngIf="addType =='Image'" class="btn btn-info edit_btn_style" type="button"
                    (click)="renderImage()" [disabled]="addForm.invalid">Render
                    ImageField</button>
                </div>
              </div>
              <!-- <div class="text-right">
                <input type="submit" class="btn btn-success" [disabled]="addForm.invalid" value="Submit" />
              <button class="btn btn-danger" type="button" (click)="clearImageDetailsField()">Cancel</button>
              <button *ngIf="addType!=='Image'" class="btn btn-info" type="button" (click)="renderText()"
                [disabled]="addForm.invalid">Render
                TextField</button>
              <button *ngIf="addType =='Image'" class="btn btn-info" type="button" (click)="renderImage()"
                [disabled]="addForm.invalid">Render
                ImageField</button>
            </div> -->
            </div>



            <!-- <div class="col-md-6">
              <section class="container  mb-4 p-3 pl-0">
                <div class="row">

                </div>

              </section>
            </div> -->
          </form>
        </ng-container>
      </div>

      <!-- <div class="col-3">
        <p>Thumbnail</p>
        <img class="big-image" [src]="thumbnail">
      </div> -->
      <div class="col-6 mb-5">
        <p>Preview</p>
        <div class="big-image" style="zoom: 30%; position: relative;">
          <canvas #photoCanvas class="shadow" [width]="canvasWidth" [height]="canvasHeight">
          </canvas>
          <ng-container *ngFor="let imgData of images | templateEditFilter : editIndex('Image')">
            <app-image-overlay [data]="getPrevImage(imgData)"></app-image-overlay>
          </ng-container>
          <ng-container *ngFor="let txtData of texts | templateEditFilter : editIndex('Text')">
            <app-text-overlay [data]="getPreviewText(txtData)" [font]="getFont(txtData)"></app-text-overlay>
          </ng-container>
          <ng-container *ngIf="showText">
            <app-text-overlay [data]="selectedText" [font]="getFont(selectedText)"></app-text-overlay>
          </ng-container>
          <ng-container *ngIf="showImage">
            <app-image-overlay [data]="selectedImage"></app-image-overlay>
          </ng-container>
        </div>

        <p>Thumbnail</p>
        <img class="big-image" [src]="thumbnail">
      </div>


    </div>



  </div>
</div>
<ng-template #addFontModal let-modal>
  <app-fonts [MODE]="'CREATE'" [fontsData]="fontsData" (modeChanged)="fontConfigModeChanged($event)"
    (outputData)="fontOutputData($event)"></app-fonts>
</ng-template>