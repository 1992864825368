import { Component, OnInit } from '@angular/core';
import { User } from '../../shared/services/user';
import { AuthService } from '../../shared/services/auth.service';
import { EditSettingsService } from '../../shared/services/edit-settings.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UserDetails } from 'src/app/shared/models/user-details.model';
import { NotyfService } from 'src/app/shared/services/notyf.service';

import { Options } from '@angular-slider/ngx-slider';
import { ElementRef, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { SettingsService } from 'src/app/shared/services/settings.service';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  public email: string;
  public displayName: string;
  public phone: string;
  // public photoURL: string;
  public designation: string;
  public userData: UserDetails = {
    address: '',
    area: '',
    city: '',
    dob: '',
    gender: '',
    state: '',
    userId: '',
    zip: '',
  };
  public userDataDocId = '';
  items: Array<any>;

  public oldPhotoUrl = '../../assets/img/no-image-icon-md.png';
  public photoURL = '../../assets/img/no-image-icon-md.png';
  public photoPIC = '../../assets/img/no-image-icon-md.png';
  transform: ImageTransform = {};
  rotateValue = 0;
  rotateSliderOptions: Options = {
    floor: 1,
    ceil: 360,
  };
  zoomValue = 0;
  zoomSliderOptions: Options = {
    floor: 1,
    ceil: 10,
  };
  ratio = 65 / 78;
  templateModel: NgbModalRef;
  modalShown = false;
  croppedImage;

  public isMAR = true;

  @ViewChild('imageFileInput') imageFileInput: ElementRef;

  hint = false;
  loadingStatus = 'Loading';
  imageChange = false;

  constructor(
    private authService: AuthService,
    private editservice: EditSettingsService,
    private fUp: SettingsService,
    private modalService: NgbModal,
    private userDetails: UserDetailsService,
    private notyf: NotyfService
  ) {}

  ngOnInit(): void {
    this.userDetails.getDetails().subscribe((values) => {
      if (values.length == 1) {
        this.userDataDocId = values[0].docId;
        this.userData = values[0].userData;
      }
    });
    this.displayName = '';
    this.phone = '';
    this.photoURL = '';
    this.email = '';
    this.designation = '';
    this.authService.getUsers().then((result) => {
      this.email = result.payload.val().emailId;
      this.displayName = result.payload.val().displayName;
      this.phone = result.payload.val().phoneNo;
      this.photoURL = result.payload.val().imageData;
      this.oldPhotoUrl = this.photoURL;
      this.designation = result.payload.val().designation;
    });
  }

  updateProfile() {
    if (this.displayName.length > 0) {
      this.hint = true;
      this.deleteOldPic()
        .then(() => {
          this.uploadNewFile()
            .then((newUrl) => {
              this.loadingStatus = 'Saving User data';
              this.authService.UpdateUserData(
                this.displayName,
                newUrl,
                this.phone,
                this.designation
              );
              this.userDetails
                .updateDetails(this.userDataDocId, this.userData)
                .then(() => {
                  this.hint = false;
                  this.notyf.success('User data updated');
                })
                .catch((error) => {
                  this.hint = false;
                  this.notyf.error(error);
                });
            })
            .catch((reason) => {
              this.hint = false;
              ////console.log(reason);
            });
        })
        .catch(() => {
          this.hint = false;
          ////console.log('Unable to delete file');
        });
    }
  }

  deleteOldPic(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.imageChange) {
        this.loadingStatus = 'Deleting Old Picture';
        if (this.oldPhotoUrl != '../../assets/img/no-image-icon-md.png') {
          this.fUp
            .deleteFile(this.oldPhotoUrl)
            .then((val) => resolve())
            .catch((err) => {
              ////console.log(err);
              resolve();
            });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  uploadNewFile(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.imageChange) {
        this.loadingStatus = 'Uploading Picture';
        this.fUp
          .uploadProfilePicture(this.photoURL)
          .then((value) => {
            resolve(value);
          })
          .catch((reason) => {
            ////console.log(reason);

            resolve(this.oldPhotoUrl);
          });
      } else {
        resolve(this.oldPhotoUrl);
      }
    });
  }

  // onSelectFileChange($event) {

  //   // cancel check
  //   const files = $event.srcElement.files;
  //   if (files.length > 0) {

  //     // base64 encode file (TODO extract to a service)
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       this.photoURL = (e.target as FileReader).result as string;
  //     };
  //     reader.readAsDataURL($event.srcElement.files[0]);

  //   }
  // }

  /**
   *
   * @param $event file input event
   * @param content template reference of the modal that is passed via html
   */
  onSelectFileChange($event, content?) {
    // cancel check
    const files = $event.srcElement.files;
    if (files.length > 0) {
      const f: File = files[0];

      // 5242880 is for 5MB
      if (f.size <= 5242880) {
        // base64 encode file (TODO extract to a service)
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageFileInput.nativeElement.value = null;
          this.photoPIC = (e.target as FileReader).result as string;
          if (this.photoURL == '../../assets/img/no-image-icon-md.png') {
            this.photoURL = this.photoPIC;
          }
          this.croppedImage = this.processImgUrl(this.photoPIC);
          if (!this.modalShown && content) {
            this.cropImage(content);
          }
        };
        reader.readAsDataURL($event.srcElement.files[0]);
      } else {
        this.notyf.error('Image is too big');
        setTimeout(() => this.notyf.info('Size should be less than 5MB'), 1500);
      }
    }
  }

  processImgUrl(url) {
    return this.editservice.processImgUrl(url, 200, 100);
  }
  transformImage(prop) {
    if (prop === 1) {
      this.rotateValue += 90;
      this.rotateValue = this.rotateValue == 360 ? 0 : this.rotateValue;
      this.transform = {
        ...this.transform,
        rotate: this.rotateValue,
      };
    } else if (prop === 2) {
      this.transform = {
        ...this.transform,
        scale: this.zoomValue,
      };
    }
  }
  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }
  onCloseHandled() {
    this.templateModel.close();
    this.photoURL = this.croppedImage;
    this.imageChange = true;
  }

  fitFullImage(): void {
    this.isMAR = this.isMAR ? false : true;
    const x = this.photoURL;
    this.photoURL = '';
    setTimeout(() => {
      this.photoURL = x;
    }, 100);
  }

  getImageFit(): string {
    return this.isMAR ? 'cover' : 'fill';
  }
  cropImage(content) {
    this.transform = {};
    this.templateModel = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      size: 'md',
    });

    this.monitorModel();
  }

  imageCropped(event: ImageCroppedEvent) {
    setTimeout(() => {
      this.croppedImage = event.base64;
    }, 100);
  }

  monitorModel() {
    this.templateModel?.shown.subscribe(() => {
      this.modalShown = true;
    });

    this.templateModel?.closed.subscribe((value) => {
      this.modalShown = false;
    });

    this.templateModel?.dismissed.subscribe((value) => {
      this.modalShown = false;
    });
  }

  // processImgUrl(url) {
  //   return this.editservice.processImgUrl(url, 200, 100);
  // }
}
