import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { CalendarDB } from '../models/calendar.db.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private db: AngularFireDatabase) { }

  getEvents() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return this.db.list('/events', ref => ref.orderByChild('userId').equalTo(user.uid))
      .snapshotChanges()
      .pipe(map(snaps => {
        return snaps.map(snap => {
          return {
            docId: snap.payload.key,
            eventData: snap.payload.val()
          } as {
            docId: string;
            eventData: CalendarDB
          };
        });
      }));
  }

  updateEvent(docId, value: CalendarDB) {
    if (docId) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      value.userId = user.uid;
      return this.db.object('/events/' + docId).update(value);
    }
    else {
      const user = JSON.parse(sessionStorage.getItem('user'));
      value.userId = user.uid;
      return this.db.object('/events/' + this.db.createPushId()).update(value);
    }
  }

  removeEvent(docId) {
    return this.db.object('/events/' + docId).remove();
  }
}
