import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface CustomFont {
  fontId: any;
  fontName: string;
  fontClass: string;
  fontWeight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  fontUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class FontManagerService {
  constructor(private db: AngularFireDatabase) {}

  getFonts() {
    return this.db
      .list<CustomFont>('/' + environment.settings.fontSettings)
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((snap) => {
            return snap.payload.val();
          });
        })
      );
  }

  create(params: CustomFont) {
    let id = this.db.createPushId();
    params.fontId = id;
    return this.db
      .list('/' + environment.settings.fontSettings + '/')
      .set(id, params);
  }

  update(params: CustomFont) {
    return this.db
      .list('/' + environment.settings.fontSettings + '/')
      .set(params?.fontId, params);
  }
}
