<ng-container *ngIf="MODE == 'VIEW'">
  <div class="row mb-2">
    <div class="col-md-4">
      <input #search type="text" class="input_custom_design form-control" placeholder="Search"
        (input)="filter = search.value">
    </div>
    <div class="col-md-2">
      <button class="btn btn-sm px-3 btn-secondary ms-3 add_btn_style" (click)="newTag()">Add New Tag</button>
    </div>
  </div>
  <div class="card">
    <div class="table-responsive ">
      <table class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of tagsData | filterBy : filter; let i = index;">
            <td>{{i+1}}</td>
            <td>{{item.Name}}</td>
            <td>{{item.Description}}</td>
            <td>{{item.Status}}</td>
            <td><button class="btn btn-primary btn-xs edit_btn_style" (click)="editTag(item,i)">Edit</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="MODE == 'CREATE' || MODE == 'EDIT'">
  <div>
    <div class="mb-3">
      <p class="mb-0">Add New tag details</p>
      <small>Enter all fields for tag management</small>
    </div>
    <form [formGroup]="tagsForm" (submit)="submitData()">
      <div class="row">
        <div class="col-md-4 mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Tag</label>
          <input type="text" class="form-control input_custom_design"
            [class.is-invalid]="TagName.touched && TagName.invalid" [class.is-valid]="TagName.valid"
            formControlName="tagName" required>
          <div *ngIf="TagName.touched && TagName.invalid;else validEntry" class="invalid-feedback">
            Ivalid data
          </div>
          <ng-template #validEntry>
            <div *ngIf="TagName.touched" class="valid-feedback">
              Looks good!
            </div>
          </ng-template>
        </div>
        <div class="col-md-4 mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Description</label>
          <input type="text" class="form-control input_custom_design" formControlName="tagDescription">
        </div>
        <div class="col-md-4 mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Status</label>
          <select type="text" class="form-control input_custom_design" style="height: 45px;" formControlName="status"
            required>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        <div class="mb-2">
          <button class="btn btn-primary mr-2" type="submit" [disabled]="tagsForm.invalid" style="border-radius: 5px;
        line-height: 1rem;">{{submitButtonText}}</button>
          <button class="btn btn-danger mr-2" type="button" (click)="viewTags()" style="border-radius: 5px;
        line-height: 1rem;">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</ng-container>