<!-- <div class=" shadow-dropped-down" *ngIf="textSettings.selectedModelUniqueId > -1">
    <button class="ctrl-font pull-left" (mouseover)="onShowFontOptions()" (click)="onShowFontOptions()" [style.fontFamily]="textSettings.options.fonts[editableTextComponentCurrent.model.fontIndex].family">{{textSettings.options.fonts[editableTextComponentCurrent.model.fontIndex].name}}</button>
    <button class="pull-right" (mouseover)="onCloseOptions()" (click)="onUpdateAlign()"><img class="svg-icon" [src]="'assets/svg/' + textSettings.options.align[editableTextComponentCurrent.model.alignIndex] + '.svg'"></button>
    <button class="pull-right" (mouseover)="onCloseOptions()" (click)="onToggleItalic()"><em>i</em></button>
    <button class="pull-right" (mouseover)="onCloseOptions()" (click)="onToggleBold()"><strong>B</strong></button> 
    <button class="pull-right" (mouseover)="onCloseOptions()" (colorPickerChange)="onTextColor($event)" [(colorPicker)]="color"><strong [style.color]="color">A</strong></button>    
    <button class="pull-right" (mouseover)="onCloseOptions()" (colorPickerChange)="onBackgroundColor($event)" [style.background-color]="color" [(colorPicker)]="color"><strong >T</strong></button>
    <input class="test" (mouseover)="onCloseOptions()" [(ngModel)]="size" (change)="onUpdateWeight()" maxlength="3" type="text" />
    <div class="color-dropdown" *ngIf="isShowColors">
        <button class="color shadow-dropped-down" *ngFor="let color of textSettings.options.colors; let i = index;" (mouseover)="onUpdateColor(i)">
            <div class="color-tile" [style.backgroundColor]="color"></div>
        </button>
    </div>
    <div class="" *ngIf="isShowFonts">
        <button class="font shadow-dropped-down" *ngFor="let font of textSettings.options.fonts; let i = index;" (mouseover)="onUpdateFont(i)">
            <div class="font-tile font-{{i}}" [style.fontFamily]="font.family">{{font.name}}</div>
        </button>
    </div>
</div> -->
<ng-container *ngIf="settings.selectedModelUniqueId !== '-1'">
    <hr />
    <p class="help-text">Style your text</p>
    <div class="btn-group" role="group" aria-label="Style Groups">
        <button type="button" class="btn transparent-btn btn-secondary"  (mouseover)="onShowFontOptions()" (click)="onShowFontOptions()">{{settings.textSettings.options.fonts[editableTextComponentCurrent.model.fontIndex].name}}</button>
        <button type="button" class="btn transparent-btn btn-secondary"(mouseover)="onCloseOptions()" (click)="onUpdateAlign()">
            <i class="fa fa-{{settings.textSettings.options.align[editableTextComponentCurrent.model.alignIndex]}}"></i></button>
        <button type="button" class="btn transparent-btn btn-secondary" (mouseover)="onCloseOptions()" (click)="onToggleItalic()"><span class="font-italic">i</span></button>
        <button type="button" class="btn transparent-btn btn-secondary" (mouseover)="onCloseOptions()" (click)="onToggleBold()"><span class="font-weight-bold">B</span></button>
        <button type="button" class="btn transparent-btn btn-secondary" (mouseover)="onCloseOptions()" (colorPickerChange)="onTextColor($event)" [(colorPicker)]="color"  [cpPosition]="'bottom-left'" [cpPositionOffset]="'100%'" [cpUseRootViewContainer]="true"  [cpPositionRelativeToArrow]="true" [cpWidth]="'170px'" ><strong [style.color]="textColor">A</strong></button>
        <button type="button" class="btn transparent-btn btn-secondary"(mouseover)="onCloseOptions()" (colorPickerChange)="onBackgroundColor($event)" [(colorPicker)]="color" [cpPosition]="'bottom-left'"  [cpPositionOffset]="'100%'" [cpUseRootViewContainer]="true" [cpPositionRelativeToArrow]="true"  [cpWidth]="'170px'"><i  [style.color]="bColor" class="fas fa-fill"></i></button>
    </div>
    <div class="" *ngIf="isShowFonts">
        <button class="font shadow-dropped-down" *ngFor="let font of settings.textSettings.options.fonts; let i = index;" (mouseover)="onUpdateFont(i)">
            <div class="font-tile font-{{i}}" [style.fontFamily]="font.family">{{font.name}}</div>
        </button>
    </div>

</ng-container>
