import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { Contacts } from '../models/contacts.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private db: AngularFireDatabase) { }

  getContacts() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return this.db.list('/contacts', ref => ref.orderByChild('userId').equalTo(user.uid))
      .snapshotChanges()
      .pipe(map(snaps => {
        return snaps.map(snap => {
          return {
            docId: snap.payload.key,
            contactData: snap.payload.val()
          } as {
            docId: string;
            contactData: Contacts
          };
        });
      }));
  }

  updateContact(docId, value: Contacts) {
    if (docId) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      value.userId = user.uid;
      return this.db.object('/contacts/' + docId).update(value);
    }
    else {
      const user = JSON.parse(sessionStorage.getItem('user'));
      value.userId = user.uid;
      return this.db.object('/contacts/' + this.db.createPushId()).update(value);
    }
  }

  removeContact(docId) {
    return this.db.object('/contacts/' + docId).remove();
  }

}
