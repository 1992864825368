import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NotyfService } from '../services/notyf.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    private notyf: NotyfService,
    public router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (state.url.indexOf('/home') == 0) {
      if (!this.authService.isLoggedIn) {
        return this.router.navigate(['login']);
      } else {
        return true;
      }
    } else if (state.url.indexOf('/admin') == 0) {
      if (!this.authService.isAdminLoggedIn && state.url != '/admin/login') {
        return this.router.navigateByUrl('/admin/login');
      } else {
        return true;
      }
    } else if (state.url.indexOf('/login') == 0) {
      if (this.authService.isLoggedIn) {
        this.notyf.info('User not logged out');
        return this.router.navigateByUrl('/home');
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
