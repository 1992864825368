import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { CustomPostType } from '../models/custom-post-type.model';

@Injectable({
  providedIn: 'root'
})
export class PostTypesManagerService {

  constructor(private db: AngularFireDatabase) { }

  getPostTypes() {
    return this.db.list<CustomPostType>('/settings/sizeSettings/sizes/')
      .snapshotChanges()
      .pipe(map(snaps => {
        return snaps.map(snap => {
          return snap.payload.val();/* {
            docId: snap.payload.key,
            data: snap.payload.val()
          } as {
            docId: string;
            data: CustomPostType
          }; */
        });
      }));
  }

  addOrUpdatePostType(docId, value: CustomPostType) {
    return this.db.object('/settings/sizeSettings/sizes/' + docId).update(value);
  }
}
