<!-- Section -->
<section class="d-flex align-items-center pb-5" style="background-color: white !important;">
  <div class="container">
    <div class="row justify-content-center form-bg-image" style="background: url('assets/img/illustrations/signin.svg') ;
     background-size: 1300px 500px !important; background-attachment: fixed;
     background-position: 109px !important; padding-top: 0px;">
      <p class="text-center pt-2">
        <img src="../../assets/img/brand/img.PNG" style="width: 20%;">
      </p>
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
          <div class="text-center text-md-center mb-4 mt-md-0">
            <h1 class="mb-0 h3">Create an account</h1>
          </div>
          <form [formGroup]="signUpForm" (submit)="onSubmit()">
            <!-- Form -->

            <div class="form-group mb-4 text-md-center">
              <!-- <label>Select profile photo</label> -->
              <!-- Avatar -->
              <div class="center pointer" [ngClass]="{'red_border': check_image()}"
                style="border-radius: 10px;width: 100px;" (click)="imageFileInput.click()">
                <div>
                  <img *ngIf="photoURL" [src]="photoURL" class=" img-responsive" style="border-radius: 10px;">
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="file-field center">
                  <div class="d-flex" style="width: 100%;">
                    <!-- <span class="icon icon-md"><span class="far fa-image me-3"></span></span> -->
                    <input #imageFileInput type="file" accept="image/x-png,image/gif,image/jpeg"
                      (change)="onSelectFileChange($event, content)" name="photoURL" required>
                    <div class="d-md-block text-center">
                      <div class="fw-normal text-dark">Choose Image</div>
                      <div class="text-gray small">JPG or PNG. Max size of 5MB</div>
                      <div *ngIf="photoURL == '../../assets/img/avater__.jpg'" class="invalid-feedback"
                        style="display: initial !important;">
                        Please change image
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-3">
              <label for="email">Your Email <span class="text-danger">*</span></label>
              <div class="input-group">
                <input placeholder="example@company.com" formControlName="email" name="email" id="email" autofocus
                  required type="email" class="input_custom_design form-control" [class.is-valid]="Email.valid"
                  [class.is-invalid]="Email.invalid">
                <div *ngIf="Email.invalid" class="invalid-feedback" style="display: initial !important;">
                  Please enter a valid email address
                </div>
                <!-- <div *ngIf="checkemail(email_add.value) && email_add.value.length > 0" class="valid-feedback"
                  style="display: initial !important;">
                  Looks good!
                </div> -->
              </div>
            </div>
            <!-- End of Form -->
            <div class="form-group">
              <!-- Form -->
              <div class="form-group mb-3">
                <label for="password">
                  Your Password
                  <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <input placeholder="Password" formControlName="password" name="password" id="password" required
                    autocomplete="current-password" type="text" class="input_custom_design form-control"
                    [class.passField]="passwordHide" [class.showPassField]="!passwordHide"
                    [class.is-invalid]="passwordScore < 50"
                    [class.is-valid]="passwordScore > 80 && Password.value.length > 0"
                    [class.info-valid]="passwordScore < 80 && passwordScore > 50 && Password.value.length > 0">
                  <button class="btn btn-icon-only" type="button">
                    <a href="javascript:void()" (click)="passwordHide = !passwordHide"><i class="fa"
                        [class.fa-eye-slash]="passwordHide" [class.fa-eye]="!passwordHide" aria-hidden="true"></i></a>
                  </button>
                  <!-- <div *ngIf="password == confirmPassword" class="valid-feedback">
                    Looks good!
                  </div> -->
                  <div *ngIf="Password.value.length==0" class="invalid-feedback" style="display: initial !important;">
                    Please enter valid data
                  </div>
                  <div *ngIf="passwordScore < 50 && passwordScore > 0 && Password.value.length > 0"
                    class="invalid-feedback" style="display: initial !important;">
                    weak password, please enter password with combination of letters, alphabets, numbers & characters
                  </div>
                  <div *ngIf="passwordScore < 80 && passwordScore> 50 && Password.value.length > 0"
                    class="infovalid-feedback" style="display: initial !important;">
                    Medium password, please enter password with combination of letters, alphabets, numbers & characters
                  </div>
                  <!-- <div *ngIf="checkstrength(pass_va.value) > 80  && password.length > 0" class="valid-feedback"
                    style="display: initial !important;">
                    Strong password, please enter password with combination of letters, alphabets, numbers & characters
                  </div> -->
                  <!-- <div *ngIf="password != confirmPassword" class="invalid-feedback">
                    Doesn't match with the Confirm Password.
                  </div> -->
                </div>
              </div>
              <!-- End of Form -->
              <!-- Form -->
              <div class="form-group mb-3">
                <label for="confirm_password">Confirm Password <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input placeholder="Confirm Password" formControlName="confirmPassword" name="confirm-password"
                    id="confirm_password" required autocomplete="current-password" type="text"
                    class="input_custom_design form-control" [class.passField]="confirmPassHide"
                    [class.showPassField]="!confirmPassHide"
                    [class.is-invalid]="Password.value != ConfirmPassword.value || ConfirmPassword.value.length == 0"
                    [class.is-valid]="Password.value == ConfirmPassword.value && ConfirmPassword.value.length > 0">
                  <button class="btn btn-icon-only" type="button">
                    <a href="javascript:void()" (click)="confirmPassHide = !confirmPassHide"><i class="fa"
                        [class.fa-eye-slash]="confirmPassHide" [class.fa-eye]="!confirmPassHide"
                        aria-hidden="true"></i></a>
                  </button>
                  <!-- <div *ngIf="password == confirmPassword" class="valid-feedback">
                    Looks good!
                  </div> -->
                  <div *ngIf="ConfirmPassword.value.length==0" class="invalid-feedback"
                    style="display: initial !important;">
                    Please enter valid data
                  </div>
                  <div *ngIf="Password.value != ConfirmPassword.value" class="invalid-feedback">
                    Doesn't match with the Password.
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="displayName">Display Name <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input placeholder="Enter your name" formControlName="displayName" name="displayName" required
                    type="text" class="input_custom_design form-control" [class.is-invalid]="DisplayName.invalid"
                    [class.is-valid]="DisplayName.valid">
                  <div *ngIf="DisplayName.invalid" class="invalid-feedback">
                    Please enter valid data.
                  </div>
                </div>
              </div>
              <div class="form-group  mb-3">
                <label for="phoneNumber">Phone <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input [specialCharacters]="[ '(' ,')' , '-' , ' ' ]" mask="(000) 000-0000"
                    placeholder="Enter phone number" formControlName="phoneNumber" name="phoneNumber" required
                    type="tel" class="input_custom_design form-control" [class.is-invalid]="PhoneNumber.invalid"
                    [class.is-valid]="PhoneNumber.valid">
                  <div *ngIf="PhoneNumber.invalid" class="invalid-feedback">
                    Please enter valid phone number.
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="designation">Designation <span class="text-danger">*</span></label>
                <select class="form-select input_custom_design  mb-0" id="designation" formControlName="designation"
                  name="designation" [class.is-invalid]="Designation.invalid" [class.is-valid]="Designation.valid">
                  <option value='' selected>Select</option>
                  <option value="Broker">Broker</option>
                  <option value="Real Estate Broker">Real Estate Broker</option>
                  <option value="Broker Real Estate Agent">Broker Real Estate Agent</option>
                  <option value="REALTOR®️ Broker">REALTOR®️ Broker</option>
                  <option value="REALTOR®️">REALTOR®️</option>
                  <option value="Real Estate Agent">Real Estate Agent</option>
                  <option value="REALTOR®️ Salesperson">REALTOR®️ salesperson</option>
                </select>
                <div *ngIf="Designation.invalid" class="invalid-feedback">
                  Please select your designation
                </div>
              </div>

              <!-- <div class="form-group mb-3">
                <label for="displayName">Access Code<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input placeholder="Enter your access code" formControlName="accesscode" name="displayName" required
                    type="text" class="input_custom_design form-control" [class.is-invalid]="Accesscode.invalid"
                    [class.is-valid]="Accesscode.valid">
                  <div *ngIf="Accesscode.invalid" class="invalid-feedback">
                    Please enter valid data.
                  </div>
                </div>
              </div> -->
              <div class="form-group mb-3">
                <label for="displayName">Reco #<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input placeholder="Enter your access code" formControlName="reco" name="displayName" required
                    type="text" class="input_custom_design form-control" [class.is-invalid]="reco.invalid"
                    [class.is-valid]="reco.valid">
                  <div *ngIf="reco.invalid" class="invalid-feedback">
                    Please enter valid data.
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="displayName">Treb #<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input placeholder="Enter your access code" formControlName="treb" name="displayName" required
                    type="text" class="input_custom_design form-control" [class.is-invalid]="treb.invalid"
                    [class.is-valid]="treb.valid">
                  <div *ngIf="treb.invalid" class="invalid-feedback">
                    Please enter valid data.
                  </div>
                </div>
              </div>

              <!-- End of Form -->
              <div class="form-check mb-4">
                <input class="form-check-input" formControlName="checked" type="checkbox" id="terms" name="checkbox"
                  required [class.is-invalid]="Checked.invalid" [class.is-valid]="Checked.valid">
                <label class="form-check-label" for="terms">
                  I agree to the <a>terms and conditions </a>
                </label>
              </div>

            </div>
            <div class="d-grid">
              <button type="submit" class="btn btn-dark" [disabled]="!signUpForm.valid " [hidden]="hint">Sign
                up</button>
              <button class="btn btn-primary" type="button" disabled [hidden]="!hint">
                <!-- <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> -->
                {{loadingStatus}}
              </button>
            </div>
          </form>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <span class="fw-normal">
              Already have an account?
              <a [routerLink]="[ '/login']">Login here</a>
              <!-- <a routerLink="login" class="fw-bold">Login here</a> -->
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="login_footer">
      <a href="javascript:void(0);" class="link_password">Support &nbsp;/&nbsp;</a>
      <a href="javascript:void(0);" class="link_password">HomeLifeApp.com</a>
      <div class="fill-remaining-space"></div>
    </div> -->
  </div>
</section>
<ng-template #content let-modal>

  <div class="moda">
    <form>
      <div class="col-md-12">
        <form accept-charset="UTF-8" method="POST" target="_blank">
          <ng-container>
            <div class="row">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Image Editor</h4>
                  <div>
                    <button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">X</span>
                    </button>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6">
                      <!-- <label style="font-size: 12px;">Select Image</label> -->
                      <input type="file" accept="image/*" #file (change)="onSelectFileChange($event)">
                      <!-- <button style="margin-right: 10px;" (click)="file.click()" type="button"
                        class="btn btn-secondary">
                        Select Image
                      </button> -->
                    </div>
                    <div class="col-md-6 fliping" style="    text-align: right;">
                      <!-- <label style="font-size: 12px;">Flip</label> -->
                      <div>
                        <img src="../../assets/img/brand/left.png" (click)="flipHorizontal()" style="background-color: rgb(248, 248, 248);
                        width: 40px; padding: 7px; margin-right: 10px ;">
                        <img src="../../assets/img/brand/flip.png" (click)="flipVertical()" style="background-color: rgb(248, 248, 248);
                      width: 40px;padding: 7px;  margin-right: 10px ;">
                        <img src="../../assets/img/brand/phone.png" (click)="transformImage(1)"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;  margin-right: 10px ;">
                        <img *ngIf="isMAR" src="../../assets/img/brand/expand-screen.png" (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;  margin-right: 10px ;">
                        <img *ngIf="!isMAR" src="../../assets/img/brand/minimize-arrows.png" (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;  margin-right: 10px ;">
                      </div>
                      <!-- <div class="col-12" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-primary me-1"
                          (click)="flipHorizontal()">Horizontal</button>
                        <button type="button" class="btn btn-primary" (click)="flipVertical()">Vertical</button>
                      </div> -->
                    </div>
                    <!-- <div class="col-md-2  rotate"> -->
                    <!-- <label style="font-size: 12px;">Rotate</label> -->
                    <!-- <ngx-slider [(value)]="rotateValue" (valueChange)=transformImage(1)
                        [options]="rotateSliderOptions"></ngx-slider> -->
                    <!-- <div>
                        <img src="../../../../assets/img/brand/phone.png" (click)="transformImage(1)"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                      </div>
                    </div>
                    <div class="col-md-2  rotate"> -->
                    <!-- <label style="font-size: 12px;">Fit Full Image</label> -->
                    <!-- <ngx-slider [(value)]="rotateValue" (valueChange)=transformImage(1)
                        [options]="rotateSliderOptions"></ngx-slider> -->
                    <!-- <div>
                        <img *ngIf="isMAR" src="../../../../assets/img/brand/expand-screen.png" (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                        <img *ngIf="!isMAR" src="../../../../assets/img/brand/minimize-arrows.png"
                          (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                      </div> -->
                    <!-- </div> -->
                    <div class="col-md-12">
                      <label style="font-size: 12px;">Zoom Image</label>
                      <ngx-slider [(value)]="zoomValue" (valueChange)=transformImage(2) [options]="zoomSliderOptions">
                      </ngx-slider>
                    </div>
                    <div class="col-md-12">
                      <image-cropper [imageBase64]="photoPIC" [maintainAspectRatio]="isMAR"
                        [containWithinAspectRatio]="false" [aspectRatio]="ratio" [onlyScaleDown]="true"
                        [roundCropper]="false" [canvasRotation]="0" [transform]="transform" [alignImage]="'center'"
                        [style.display]="null" format="png" (imageCropped)="imageCropped($event)"
                        [transform]="transform">
                      </image-cropper>
                    </div>


                  </div>
                </div>
                <div class="modal-footer">
                  <button style="margin-right: 10px;" type="button" class="btn float-right btn-secondary"
                    (click)="transform = {}">Reset
                    Image</button>
                  <button type="button" class="btn btn-primary float-right" (click)="onCloseHandled()">Save &
                    Close</button>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </form>
  </div>
</ng-template>