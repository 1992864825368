import { Pipe, PipeTransform } from '@angular/core';
import { Contactdoc } from '../models/contactdoc.model';
import { CommService } from '../services/comm.service';

@Pipe({
  name: 'contactIndexing'
})
export class ContactIndexingPipe implements PipeTransform {
  constructor(private comm: CommService) { }

  transform(data: Array<Contactdoc>,
            props: Array<string>,
            target: string,
            group: string,
            startIndex: number,
            numberOfEntries: number): Array<Contactdoc> {

    const temp = this.realTransform(data, props, target, group);
    this.comm.setContactFilteredLength(temp.length);
    if (temp.length <= numberOfEntries) {
      return temp;
    }
    else {
      // This is where pagination is done folks
      let endIndex = startIndex + numberOfEntries;
      if (temp.length <= endIndex) {
        endIndex = temp.length;
      }

      return temp.slice(startIndex, endIndex);
    }
  }

  realTransform(data: Array<Contactdoc>,
                props: Array<string>,
                target: string,
                group: string): Array<Contactdoc> {

    return this.groupTransform(data, group).filter((item: Contactdoc) => {
      // one time lowercase
      target = target.toLowerCase();

      // property loop with match escape
      let match: Contactdoc;
      let matchProp;
      let validItem: Contactdoc;
      let validProp: string;
      for (const prop of props) {
        validItem = item;
        validProp = item.contactData[prop];
        matchProp = validProp ? validProp.toLowerCase().indexOf(target) > -1 : -1;
        if (matchProp) {
          match = validItem;
          break;
        }
      }

      // return match
      return match;

    });
  }

  groupTransform(data: Array<Contactdoc>, group: string): Array<Contactdoc> {
    if (group == '') { return data; }
    else {
      return data.filter((item: Contactdoc) => {
        if (item.contactData.group == group) {
          return item;
        }
      });
    }
  }

}
