<div class="container">
    <div class="d-block mb-4 mb-md-0 pt-3">
        <h5 class="mb-0">
            <span class="sidebar-icon material-icons-outlined">
                support_agent
            </span>
            <span class="heading-text" style="font-size: 20px !important;">Docs and Support</span>
        </h5>
        <p style="font-size: 13px !important;">Some faq help you to find out</p>
    </div>

    <div class="accordion acc" id="accordionPricing">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#faq1'" [attr.aria-controls]="'faq1'" aria-expanded="false">
                    <span class="ml-1 mr-1 sidebar-icon material-icons-outlined" style="font-size: 18px;">
                        verified_user
                    </span>
                    How can add design size or template size?
                </button>
            </h2>
            <div id="faq1" class="accordion-collapse collapse pl-3" aria-labelledby="headingOne"
                data-bs-parent="#accordionPricing">
                <div class="accordion-body">
                    <div class="row">
                        <code>  Go to the configuration section from your admin console left navigation</code>
                        <img style="width: 60%;"
                            src="https://t3672254.p.clickup-attachments.com/t3672254/407e67fb-a2ba-4ce0-b865-5b12f8e1ed74/Screenshot%202021-08-20%20095036.png">
                        <code> On the right-side click the template sizes option</code>
                        <img style="width: 60%;"
                            src="https://t3672254.p.clickup-attachments.com/t3672254/5c41f4c6-7a1e-4012-9b8c-051ba9363247/Screenshot%202021-08-20%20095530.png">

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>