import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomFont } from 'src/app/shared/models/custom-font.model';

@Component({
  selector: 'app-fonts',
  templateUrl: './fonts.component.html',
  styleUrls: ['./fonts.component.scss']
})
export class FontsComponent implements OnInit,OnChanges {
  @Input() MODE: 'CREATE' | 'EDIT' | 'VIEW';
  @Input() editData: {docId: number;
    data: CustomFont;
  };
  @Input() fontsData: CustomFont[] = [];

  @Output() modeChanged = new EventEmitter<'CREATE' | 'EDIT' | 'VIEW'>();
  @Output() outputData = new EventEmitter<{
    docId: number;
    data: CustomFont;
  }>();

  public previewFont: CustomFont = null;
  public showPreview = false;
  public fontWeights: number[] = [100, 200, 300, 400, 500, 600, 700, 800, 900];
  public filter = '';
  public submitButtonText = 'Create';

  public fontsForm: FormGroup;
  constructor() {
    this.fontsForm = new FormGroup({
      fontName: new FormControl('', Validators.required),
      fontClass: new FormControl('', Validators.required),
      fontWeight: new FormControl(100, Validators.required),
      fontUrl: new FormControl('', Validators.required)
    });

    this.FontName.valueChanges.subscribe((value) => {
      if (value != ''){
        if (this.nameAlreadyExists(value)){
          this.FontName.setErrors({notUnique: true});
        }
      }
    });

    this.FontUrl.valueChanges.subscribe((value) => {
      if (value != '') {
        if (!this.isValidUrl(value)) {
          this.FontUrl.setErrors({ invalidUrl: true });
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializationActions();
  }

  ngOnInit(): void {
    this.initializationActions();
  }

  initializationActions(): void {
    if (this.MODE == 'CREATE') {
      this.FontClass.setValue('');
      this.FontName.setValue('');
      this.FontWeight.setValue(100);
      this.FontUrl.setValue('');
      this.submitButtonText = 'Create';
    }
    else if (this.MODE == 'EDIT') {
      this.FontClass.setValue(this.editData.data.FClass);
      this.FontName.setValue(this.editData.data.Name);
      this.FontWeight.setValue(this.editData.data.Weight);
      this.FontUrl.setValue(this.editData.data.Url);
      this.submitButtonText = 'Edit';
    } else if (this.MODE == 'VIEW') {
      this.showPreview = false;
      this.previewFont = null;
      for (const item of this.fontsData) {
        this.addPreview(item);
      }
    }
  }

  nameAlreadyExists(name: string): boolean {
    if (this.MODE == 'EDIT' && this.editData.data.Name.toLowerCase() == name.toLowerCase()) {
      return false;
    }
    for (const item of this.fontsData) {
      if (item.Name.toLowerCase() == name.toLowerCase()){
        return true;
      }
    }
    return false;
  }

  isValidUrl(value: string): boolean {
    const control: HTMLInputElement = document.createElement('input');
    control.type = 'url';
    control.value = value;
    return control.checkValidity();
  }

  addFont(fontUrl: string, callback?) {
    if (fontUrl) {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';
      try {
        document.body.appendChild(link).onload = () => {
          if (callback) {
            callback();
          }
        };
      } catch (error) {
        alert(error);
      }
    }
  }

  showMainPreview(): void{
    this.previewFont = {
      FClass: this.FontClass.value,
      Name: this.FontName.value,
      Url: this.FontUrl.value,
      Weight: this.FontWeight.value
    };
    this.addFont(this.previewFont.Url, () => {
      this.showPreview = true;
    });
  }

  addPreview(value: CustomFont) {
    this.addFont(value.Url);
  }

  editFont(value: CustomFont, index: number){
    this.outputData.emit({
      docId: index,
      data: value
    });
  }

  submitData(){
    this.submitButtonText = 'Loading';
    let x = this.fontsData.length;
    if (this.MODE == 'EDIT'){
      x = this.editData.docId;
    }
    this.outputData.emit({
      docId: x,
      data: {
        FClass: this.FontClass.value,
        Name: this.FontName.value,
        Url: this.FontUrl.value,
        Weight: this.FontWeight.value
      }
    });
  }

  viewFonts(){
    this.modeChanged.emit('VIEW');
  }

  newFont(){
    this.modeChanged.emit('CREATE');
  }

  // formDetails getters
  get FontName() {
    return this.fontsForm.get('fontName');
  }

  get FontClass() {
    return this.fontsForm.get('fontClass');
  }

  get FontWeight() {
    return this.fontsForm.get('fontWeight');
  }

  get FontUrl() {
    return this.fontsForm.get('fontUrl');
  }

}
