import { Pipe, PipeTransform } from '@angular/core';
import { Taskdoc } from '../models/taskdoc.model';
import { CommService } from '../services/comm.service';

@Pipe({
  name: 'taskIndexing'
})
export class TaskIndexingPipe implements PipeTransform {
  constructor(private comm: CommService){ }

  transform(data: Array<Taskdoc>,
            props: Array<string>,
            target: string,
            status: string,
            startIndex: number,
            numberOfEntries: number): Array<Taskdoc> {

    const temp = this.realTransform(data, props, target, status);
    this.comm.setTaskFilteredLength(temp.length);
    if (temp.length <= numberOfEntries) {
      return temp;
    }
    else{
      // This is where pagination is done folks
      let endIndex = startIndex + numberOfEntries;
      if (temp.length <= endIndex) {
      endIndex = temp.length;
      }

      return temp.slice(startIndex, endIndex);
    }
  }

  realTransform(data: Array<Taskdoc>,
                props: Array<string>,
                target: string,
                status: string): Array<Taskdoc> {

    return this.statusTransform(data, status).filter((item: Taskdoc) => {
      // one time lowercase
      target = target.toLowerCase();

      // property loop with match escape
      let match: Taskdoc;
      let matchProp;
      let validItem: Taskdoc;
      let validProp: string;
      for (const prop of props) {
        validItem = item;
        validProp = item.taskData[prop];
        matchProp = validProp ? validProp.toLowerCase().indexOf(target) > -1 : -1;
        if (matchProp) {
          match = validItem;
          break;
        }
      }

      // return match
      return match;

    });
  }

  statusTransform(data: Array<Taskdoc>, status: string): Array<Taskdoc> {
    if (status == '') { return data; }
    else {
      return data.filter((item: Taskdoc) => {
        if (item.taskData.status == status) {
          return item;
        }
      });
    }
  }

}
