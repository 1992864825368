<div [hidden]="!loading" class="overlays-container" style="text-align: center;">
    <img src="https://ajsupport.com/wp-content/uploads/2020/05/Line-Preloader.gif" class="shadow-sm"
        style="object-fit: cover;" [style.width.px]="sizeData?.Width" [style.height.px]="sizeData?.Height">
</div>

<div *ngIf="download" class="overlays-container" style="text-align: center;">
    <img src="https://ajsupport.com/wp-content/uploads/2020/05/Line-Preloader.gif" class="shadow-sm"
        style="object-fit: cover;" [style.width.px]="sizeData?.Width" [style.height.px]="sizeData?.Height">
</div>

<div [hidden]="loading || download" id="poster" class="overlays-container" [style.width.px]="width"
    [style.height.px]="height">
    <canvas #photoCanvas class="shadow" width="{{width}}" height="{{height}}" (click)="onClearOverlaysSelection()">
    </canvas>
    <app-overlay-logo [settings]="settings"></app-overlay-logo>
    <app-overlay-texts [settings]="settings"></app-overlay-texts>
</div>