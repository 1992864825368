import { Injectable } from '@angular/core';
import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';

@Injectable({
  providedIn: 'root'
})
export class SwalService {
  constructor() { }

  footaskdeleteswal(callback) {
    const Swal1: SweetAlert = _swal as any;
    Swal1({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
      buttons: {
        btn1: {
          text: "Yes, delete it!",
          className: 'btn-success',
          value: true
        },
        btn2: {
          text: "Cancel",
          className: 'btn-danger',
          value: false
        }
      }
    }).then((result) => {

    if (result) {
      Swal1(
        'Deleted!',
        'Task has been deleted.',
        'success'
      );

      callback(true);
    }
  });
}
  foocontactdeleteswal(callback) {
    const Swal2: SweetAlert = _swal as any;
  Swal2({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    buttons: {
      btn1: {
        text: "Yes, delete it!",
        className: 'btn-success',
        value: true
      },
      btn2: {
        text: "Cancel",
        className: 'btn-danger',
        value: false
      }
    }
  }).then((value) => {
    if (value) {
      Swal2(
        'Deleted!',
        'Contact has been deleted.',
        'success'
      );

      callback(true);
    }
  });
}

  fooeventdeleteswal(callback) {
    const Swal3: SweetAlert = _swal as any;
    Swal3({
    title: 'Confirm deletion',
    text: "Are you sure you want to delete this event?",
    icon: 'warning',
      buttons: {
        btn1: {
          text: "Yes, delete it!",
          className: 'btn-success',
          value: true
        },
        btn2: {
          text: "Cancel",
          className: 'btn-danger',
          value: false
        }
      }
    }).then((value) => {
      if (value) {
      Swal3(
        'Deleted!',
        'The event has been deleted.',
        'success'
      );

      callback(true);
    }
  });
}
}
