import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CommService } from '../services/comm.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public showProfile = false;
  public userDetails = null;
  public profileImage = '';
  constructor(
    public authService: AuthService,
    public commService: CommService
  ) {}

  ngOnInit(): void {
    this.authService.getUsers().then((result) => {
      this.userDetails = {
        name: result.payload.val().displayName,
        email: result.payload.val().emailId,
        phone: result.payload.val().phoneNo,
        photoUrl: result.payload.val().imageData,
      };
      this.profileImage = this.userDetails.name
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase();
    });
  }

  redirectToUpdate() {
    this.commService.activeIdChanged('profile');
    this.commService.sourceChanged(true);
  }
}
