<div class="container">
    <div class="d-block mb-4 mb-md-0 pt-3">
        <h5 class="mb-0">
            <span class="sidebar-icon material-icons-outlined">
                verified_user
            </span>
            <span class="heading-text" style="font-size: 20px !important;">Access Request</span>
        </h5>
        <p style="font-size: 13px !important;">List of all new access request to your platform</p>
    </div>

    <div class="accordion acc" id="accordionPricing" [hidden]="newreq.length == 0">
        <div class="accordion-item" *ngFor="let item of newreq; let i = index">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]=" '#a' + item.docId" [attr.aria-controls]="'a'+item.docId"
                    aria-expanded="false">
                    {{i+1}}.
                    <span class="ml-1 mr-1 sidebar-icon material-icons-outlined" style="font-size: 18px;">
                        verified_user
                    </span>
                    {{item.userData.emailId}} is sent a request to access the platform
                </button>
            </h2>
            <div [attr.id]="'a'+item.docId" class="accordion-collapse collapse pl-3" aria-labelledby="headingOne"
                data-bs-parent="#accordionPricing">
                <div class="accordion-body">
                    <div class="row">
                        <div class="col-md-1">
                            <img style="width: 110px;" [src]="item.userData.imageData">
                        </div>
                        <div class="col-md-11">
                            <div class="row">
                                <div class="col-md-4">
                                    Display Name : {{item.userData.displayName}}
                                </div>

                                <div class="col-md-4">
                                    Email : {{item.userData.emailId}}
                                </div>

                                <div class="col-md-4">
                                    Phone Number : {{item.userData.phoneNo}}
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    Designation : {{item.userData.designation}}
                                </div>

                                <div class="col-md-4">
                                    Reco # : {{item.userData.reco}}
                                </div>

                                <div class="col-md-4">
                                    Treb # : {{item.userData.treb}}
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-1" [hidden]="!loading">
                                    <div class="spinner-border text-primary spinner-border-md" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div class="col-md-1" [hidden]="loading">
                                    <button class="btn btn-secondary su_btn" type="button" (click)="accept(item.docId)">
                                        Accept
                                    </button>
                                </div>
                                <div class="col-md-1 ml-1" [hidden]="loading">
                                    <button class="btn btn-danger su_btn" type="button" (click)="reject(item.docId)">
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="text-align: center;" [hidden]="newreq.length != 0">
        <img style="width: 40%;"
            src="https://image.freepik.com/free-vector/man-woman-touching-each-other-when-work-is-done_1150-35029.jpg">
        <h5>Great Job</h5>
        <p>No more access request for your platform</p>
    </div>
</div>