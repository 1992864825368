<div class="resizable-draggable overlays-container editable-text"
  [style.left.px]="data.left ? (data.left) : 0"
  [style.top.px]="data.top ? (data.top) : 0"
  [style.height.px]="data.height ? (data.height) : 0"
  [style.width.px]="data.width ? (data.width) : 0">
  <img style="object-fit: cover;"
    [style.height.px]="data.height ? (data.height) : 0"
    [style.width.px]="data.width ? (data.width) : 0"
    [src]="data.selectedFile">
</div>
