<div *ngIf="showPreview">
  <h4>{{previewFont?.Name}} preview text is as shown below:</h4>
  <p [ngStyle]="{'font-family': previewFont?.FClass, 'font-weight': previewFont?.Weight}">The Quick Brown Fox jumps over
    the Lazy Dog.</p>
</div>
<ng-container *ngIf="MODE == 'VIEW'">
  <div class="row mb-2">
    <div class="col-md-4">
      <input #search type="text" class="input_custom_design form-control" placeholder="Search"
        (input)="filter = search.value">
    </div>
    <div class="col-md-2">
      <button class="btn btn-sm px-3 btn-secondary ms-3 add_btn_style" (click)="newFont()">Add New Font</button>
    </div>
  </div>
  <div class="card">
    <div class="table-responsive">
      <table class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th>#</th>
            <th>Name & Url</th>
            <th>Class</th>
            <th>Weight</th>
            <th>Preview</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of fontsData | filterBy : filter; let i = index;">
            <td>{{i+1}}</td>
            <td>{{item.Name}}<br>{{item.Url}}</td>
            <td>{{item.FClass}}</td>
            <td>{{item.Weight}}</td>
            <td>
              <p [ngStyle]="{'font-family': item.FClass, 'font-weight': item.Weight}">Preview Text</p>
            </td>
            <td><button class="btn btn-primary btn-xs edit_btn_style" (click)="editFont(item,i)">Edit</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="MODE == 'CREATE' || MODE == 'EDIT'">
  <div>
    <div class="mb-3">
      <p class="mb-0">Add New Font Details</p>
      <small>Enter all fields to apply new font's to your template</small>
    </div>
    <form [formGroup]="fontsForm" (submit)="submitData()">
      <div class="row">
        <div class="mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Font Name</label>
          <input type="text" class="form-control input_custom_design"
            [class.is-invalid]="FontName.touched && FontName.invalid" [class.is-valid]="FontName.valid"
            formControlName="fontName" required>
          <div *ngIf="FontName.touched && FontName.invalid;else validEntry" class="invalid-feedback">
            Ivalid data
          </div>
          <ng-template #validEntry>
            <div *ngIf="FontName.touched" class="valid-feedback">
              Looks good!
            </div>
          </ng-template>
        </div>
        <div class="mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Font Class</label>
          <input type="text" class="form-control input_custom_design"
            [class.is-invalid]="FontClass.touched && FontClass.invalid" [class.is-valid]="FontClass.valid"
            formControlName="fontClass" required>
        </div>
        <div class="mb-2">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Font Weight</label>
          <select type="text" class="form-control input_custom_design" style="height: 45px;"
            [class.is-invalid]="FontWeight.touched && FontWeight.invalid" [class.is-valid]="FontWeight.valid"
            formControlName="fontWeight" required>
            <option *ngFor="let w of fontWeights" [value]="w">{{w}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label class="mb-0" style="font-size: 14px;font-weight: 400;">Font Url</label>
          <input type="url" class="form-control input_custom_design"
            [class.is-invalid]="FontUrl.touched && FontUrl.invalid" [class.is-valid]="FontUrl.valid"
            formControlName="fontUrl" required>
          <div *ngIf="FontUrl.touched && FontUrl.invalid;else validUrl" class="invalid-feedback">
            Invalid data
          </div>
          <ng-template #validUrl>
            <div *ngIf="FontUrl.touched" class="valid-feedback">
              Looks good!
            </div>
          </ng-template>
        </div>
        <div class="mb-2">
          <button class="btn btn-danger mr-2" type="button" (click)="viewFonts()" style="border-radius: 5px;
          line-height: 1rem;">Cancel</button>
          <button class="btn btn-secondary mr-2" type="button" [disabled]="fontsForm.invalid"
            (click)="showMainPreview()" style="border-radius: 5px;
            line-height: 1rem;">Preview</button>
          <button class="btn btn-primary mr-2" type="submit" style="border-radius: 5px;
          line-height: 1rem;" [disabled]="fontsForm.invalid">{{submitButtonText}}</button>
        </div>
      </div>
    </form>
  </div>
</ng-container>