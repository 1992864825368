<!-- <div class="overlay" [style.width.px]="sizeSettings.sizes[sizeSettings.selectedSizeIndex].width" [style.height.px]="sizeSettings.sizes[sizeSettings.selectedSizeIndex].height" [hidden]="logoSettings.isGraphicHidden">
    <div class="overlay-bounds" *ngIf="logoSettings.selectedFile" appMoveClampedToParent [style.width.px]="logoSettings.size" [style.height.px]="logoSettings.size" [ngClass]="{ selected: isSelected }" (mousedown)="onSelect()">
        <div class="logo" [style.width.px]="logoSettings.size" [style.height.px]="logoSettings.size" [style.border-radius.%]="logoSettings.radius/2" [ngStyle]="{'background-image': 'url(' + logoSettings.selectedFile.url + ')', 'background-size': logoSettings.size + 'px'}">
        </div>
    </div>
</div> -->

<div class="overlay" draggable="true" *ngFor="let layer of settings.logoSettings.images; let i=index;"
  [style.width.px]="sizeData?.Width" [style.height.px]="sizeData?.Height" [hidden]="layer.isGraphicHidden">
  <app-image-overlay [settings]="settings" [layer]="layer" [i]="i"></app-image-overlay>
</div>