import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adminUserFilter'
})
export class AdminUserFilterPipe implements PipeTransform {

  transform(data: Array<any>, filter: string): Array<any> {
    const filteredData = this.adminFilter(data);
    if (filter == '') { return filteredData; }
    else {
      return filteredData.filter((value: any) => {
        if (value.userData.displayName && value.userData.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          return value;
        } else if (value.userData.emailId && value.userData.emailId.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          return value;
        } else if (value.userData.phoneNo && String(value.userData.phoneNo).indexOf(filter) !== -1) {
          return value;
        } else if (value.userData.designation && value.userData.designation.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          return value;
        } else if (value.userData.reco && String(value.userData.reco).indexOf(filter) !== -1) {
          return value;
        } else if (value.userData.treb && String(value.userData.treb).indexOf(filter) !== -1) {
          return value;
        }
      });
    }
  }

  adminFilter(data: Array<any>): Array<any> {
    const loggedAdmin = JSON.parse(sessionStorage.getItem('admin'));
    return data.filter((value: any) => {
      if (value.userData.emailId?.toLowerCase() != loggedAdmin.email.toLowerCase() && !value.userData.administrator) {
        return value;
      }
    });
  }

}
