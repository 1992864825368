import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
import { CustomTags } from 'src/app/shared/models/custom-tags.model';
import { PostTypesManagerService } from 'src/app/shared/services/post-types-manager.service';
import { TagsManagerService } from 'src/app/shared/services/tags-manager.service';
import { SettingsService } from '../../shared/services/settings.service';

@Component({
  selector: 'app-image-config',
  templateUrl: './image-config.component.html',
  styleUrls: ['./image-config.component.scss'],
})
export class ImageConfigComponent implements OnInit {
  @ViewChild('addPostTypeModal') addPostTypeModal: TemplateRef<any>;
  @ViewChild('addTagModal') addTagModal: TemplateRef<any>;

  private modalRef: NgbModalRef = null;

  public postTypesData: CustomPostType[] = [];
  public tagsData: CustomTags[] = [];

  url: string;
  name: string;
  thumbnail: string;
  tags: string;
  postType: string;
  postStatus: string;
  // selectedSize: string;
  author: string;
  currentIndex: number;
  currentUid: string;
  isEditing: boolean;
  isTemplate = false;

  nameAlreadyExists = false;

  templateModel: any;
  images: any[] = [];
  public templateModels: any[] = [];
  constructor(
    private modalService: NgbModal,
    private dbService: SettingsService,
    private postTypesManager: PostTypesManagerService,
    private tagsManager: TagsManagerService
  ) {
    this.loadData();
  }
  loadData() {
    this.dbService.getImages().then((result) => {
      this.images = result.payload.val();
    });
  }
  ngOnInit(): void {
    this.postTypesManager.getPostTypes().subscribe((value) => {
      this.postTypesData = value;
    });

    this.tagsManager.getTags().subscribe((value) => {
      this.tagsData = value;
    });
  }
  addTemplate() {
    this.isTemplate = true;
  }
  deleteSettings(i) {
    const data = {
      isDeleted: true,
      index: i,
      updatedDate: Date.now(),
    };
    this.dbService.updateImageDetails(data);
    this.loadData();
  }
  editSettings(item, i) {
    this.thumbnail = item.thumbnail;
    this.url = item.url;
    this.name = item.name;
    this.tags = item.tags;
    this.author = item.author;
    this.currentIndex = i;
    this.currentUid = item.uniqueId;
    // this.selectedSize = item.selectedSizeIndex;
    this.postStatus = item.status;
    this.postType = item.type;
    this.isEditing = true;
    this.isTemplate = true;
  }
  saveTemplate() {
    if (this.isEditing) {
      const data = {
        thumbnail: this.thumbnail,
        author: this.author,
        name: this.name,
        tags: this.tags,
        uniqueId: this.currentUid,
        url: this.url,
        index: this.currentIndex,
        status: this.postStatus,
        // selectedSizeIndex: this.selectedSize,
        type: this.postType,
        updatedDate: Date.now(),
      };
      this.dbService.updateImageDetails(data);
    } else {
      const idx = this.images ? this.images.length : 0;
      const newUid = this.images
        ? this.images[this.images.length - 1].uniqueId.substr(3)
        : 0;
      const uid = 'img' + (newUid * 1 + 1);
      // ////console.log(uid);

      const data = {
        author: this.author,
        name: this.name,
        tags: this.tags,
        uniqueId: uid,
        url: this.url,
        thumbnail: this.thumbnail,
        index: idx,
        status: this.postStatus,
        // selectedSizeIndex: this.selectedSize,
        type: this.postType,
        createdDate: Date.now(),
      };
      this.dbService.createImageDetails(data);
    }
    this.clearImageDetailsField();
    this.loadData();
  }
  clearImageDetailsField() {
    this.url = '';
    this.thumbnail = '';
    this.name = '';
    this.tags = '';
    this.author = '';
    this.isTemplate = false;
    this.isEditing = false;
    this.currentIndex = 0;
  }
  onImageUpload(e) {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        let img = reader.result;
        this.dbService.uploadImage(this.name, img).then((result) => {
          this.url = result;
        });
      };
    }
  }
  onThumbnailUpload(e) {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        let img = reader.result;
        const extension = file.name.split('.').pop();
        this.dbService
          .uploadThumbnail(this.name + '.' + extension, img)
          .then((result) => {
            this.thumbnail = result;
          });
      };
    }
  }

  checkName() {
    for (const item of this.images) {
      if (this.name == item.name) {
        this.nameAlreadyExists = true;
        break;
      } else {
        this.nameAlreadyExists = false;
      }
    }
  }

  postTypeConfigModeChanged(mode: 'CREATE' | 'EDIT' | 'VIEW'): void {
    this.modalRef.close();
  }

  postTypeOutputData(value: { docId: number; data: CustomPostType }): void {
    this.modalRef.close();

    this.postTypesManager
      .addOrUpdatePostType(value.docId, value.data)
      .then(() => {
        alert('Post Type added');
      })
      .catch((reason) => {
        ////console.log(reason);
      });
  }

  tagConfigModeChanged(mode: 'CREATE' | 'EDIT' | 'VIEW'): void {
    this.modalRef.close();
  }

  tagOutputData(value: { docId: number; data: CustomTags }): void {
    this.modalRef.close();

    this.tagsManager
      .addOrUpdateTag(value.docId, value.data)
      .then(() => {
        alert('Tag added');
      })
      .catch((reason) => {
        ////console.log(reason);
      });
  }

  openAddTagModal() {
    this.modalRef = this.modalService.open(this.addTagModal);
  }

  openAddPostTypeModal() {
    this.modalRef = this.modalService.open(this.addPostTypeModal);
  }
}
