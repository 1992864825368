import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminLoginComponent } from '../admin/admin-login/admin-login.component';
import { ImageConfigComponent } from '../admin/image-config/image-config.component';
import { TemplateModelComponent } from '../admin/template-model/template-model.component';

import { ImageOverlayComponent } from './image-overlay/image-overlay.component';
import { TextOverlayComponent } from './text-overlay/text-overlay.component';
import { NgxMaskModule } from 'ngx-mask';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AccessReqComponent } from './modules/access-req/access-req.component';
import { UsersListComponent } from './modules/users-list/users-list.component';
import { ConfigComponent } from './modules/config/config.component';
import { SupportComponent } from './modules/support/support.component';
import { FontsComponent } from './fonts/fonts.component';
import { PostTypesComponent } from './post-types/post-types.component';
import { TagsComponent } from './tags/tags.component';
import { FilterByPipe } from '../shared/pipes/filter-by.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TemplateEditFilterPipe } from '../shared/pipes/template-edit-filter.pipe';
import { AdminUserFilterPipe } from '../shared/pipes/admin-user-filter.pipe';

@NgModule({
  declarations: [
    AdminLoginComponent,
    ImageConfigComponent,
    TemplateModelComponent,
    ImageOverlayComponent,
    TextOverlayComponent,
    AdminDashboardComponent,
    AccessReqComponent,
    UsersListComponent,
    ConfigComponent,
    SupportComponent,
    FontsComponent,
    PostTypesComponent,
    TagsComponent,
    FilterByPipe,
    TemplateEditFilterPipe,
    AdminUserFilterPipe
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgbModule
  ]
})
export class AdminModule { }
