import { Component, OnInit } from '@angular/core';
import { CustomFont } from 'src/app/shared/models/custom-font.model';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
import { CustomTags } from 'src/app/shared/models/custom-tags.model';
import { FontManagerService } from 'src/app/shared/services/font-manager.service';
import { PostTypesManagerService } from 'src/app/shared/services/post-types-manager.service';
import { TagsManagerService } from 'src/app/shared/services/tags-manager.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent implements OnInit {
  public fontsInputData: CustomFont[] = [];
  public fontConfigMODE: 'CREATE' | 'EDIT' | 'VIEW' = 'VIEW';
  public fontEditData: {
    docId: number;
    data: CustomFont;
  } = null;

  public postTypesInputData: CustomPostType[] = [];
  public postTypeConfigMODE: 'CREATE' | 'EDIT' | 'VIEW' = 'VIEW';
  public postTypeEditData: {
    docId: number;
    data: CustomPostType;
  } = null;

  public tagsInputData: CustomTags[] = [];
  public tagConfigMODE: 'CREATE' | 'EDIT' | 'VIEW' = 'VIEW';
  public tagEditData: {
    docId: number;
    data: CustomTags;
  } = null;
  config = 'Font Configuration';
  constructor(
    private fontsManager: FontManagerService,
    private postTypesManager: PostTypesManagerService,
    private tagsManager: TagsManagerService
  ) {}

  ngOnInit(): void {
    this.fontsManager.getFonts().subscribe((value) => {
      this.fontsInputData = value;
    });

    this.postTypesManager.getPostTypes().subscribe((value) => {
      this.postTypesInputData = value;
    });

    this.tagsManager.getTags().subscribe((value) => {
      this.tagsInputData = value;
    });
  }

  fontConfigModeChanged(mode: 'CREATE' | 'EDIT' | 'VIEW'): void {
    this.fontConfigMODE = mode;
    if (mode == 'VIEW') {
      this.fontEditData = null;
    }
  }

  fontOutputData(value: { docId: number; data: CustomFont }): void {
    if (this.fontConfigMODE == 'EDIT' || this.fontConfigMODE == 'CREATE') {
      this.fontsManager
        .addOrUpdateFont(value.docId, value.data)
        .then(() => {
          this.fontEditData = null;
          this.fontConfigMODE = 'VIEW';
        })
        .catch((reason) => {
          ////console.log(reason);
        });
    } else if (this.fontConfigMODE == 'VIEW') {
      this.fontEditData = value;
      this.fontConfigMODE = 'EDIT';
    }
  }

  postTypeConfigModeChanged(mode: 'CREATE' | 'EDIT' | 'VIEW'): void {
    this.postTypeConfigMODE = mode;
    if (mode == 'VIEW') {
      this.postTypeEditData = null;
    }
  }

  postTypeOutputData(value: { docId: number; data: CustomPostType }): void {
    if (
      this.postTypeConfigMODE == 'EDIT' ||
      this.postTypeConfigMODE == 'CREATE'
    ) {
      this.postTypesManager
        .addOrUpdatePostType(value.docId, value.data)
        .then(() => {
          this.postTypeEditData = null;
          this.postTypeConfigMODE = 'VIEW';
        })
        .catch((reason) => {
          ////console.log(reason);
        });
    } else if (this.postTypeConfigMODE == 'VIEW') {
      this.postTypeEditData = value;
      this.postTypeConfigMODE = 'EDIT';
    }
  }

  tagConfigModeChanged(mode: 'CREATE' | 'EDIT' | 'VIEW'): void {
    this.tagConfigMODE = mode;
    if (mode == 'VIEW') {
      this.tagEditData = null;
    }
  }

  tagOutputData(value: { docId: number; data: CustomTags }): void {
    if (this.tagConfigMODE == 'EDIT' || this.tagConfigMODE == 'CREATE') {
      this.tagsManager
        .addOrUpdateTag(value.docId, value.data)
        .then(() => {
          this.tagEditData = null;
          this.tagConfigMODE = 'VIEW';
        })
        .catch((reason) => {
          ////console.log(reason);
        });
    } else if (this.tagConfigMODE == 'VIEW') {
      this.tagEditData = value;
      this.tagConfigMODE = 'EDIT';
    }
  }
}
