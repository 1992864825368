<div class="container">
  <div class="d-block mb-4 mb-md-0 pt-3">
    <h5 class="mb-0">
      <span class="sidebar-icon material-icons-outlined">
        people
      </span>
      <span class="heading-text" style="font-size: 20px !important;">Users Management</span>
    </h5>
    <p style="font-size: 13px !important;">List of all users using this application</p>
  </div>

  <div class="row mb-2">
    <div class="col-md-4">
      <input #searchField type="text" class="input_custom_design form-control" placeholder="Search"
        (input)="filter = searchField.value">
    </div>
    <div class="col-md-12">
      <table class="table">
        <thead class="thead-light">
          <tr>
            <th>No</th>
            <th>Display Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Designation</th>
            <th>Reco #</th>
            <th>Treb #</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of users | adminUserFilter : filter;let i = index">
            <td>{{i+1}}</td>
            <td>{{u.userData.displayName}}</td>
            <td>{{u.userData.emailId}}</td>
            <td>{{u.userData.phoneNo}}</td>
            <td>{{u.userData.designation}}</td>
            <td>{{u.userData.reco}}</td>
            <td>{{u.userData.treb}}</td>
            <td>
              <ng-container *ngIf="!u.userData.isAdmin;else alreadyAdmin">
                <button type="button" (click)="setAsAdmin(u)"
                  class="btn btn-primary btn-lg btn-block edit_btn_style">Assign
                  Admin </button>
              </ng-container>
              <ng-template #alreadyAdmin>
                <button type="button" (click)="removeAdmin(u)"
                  class="btn btn-danger btn-lg btn-block edit_btn_style">Remove
                  Admin </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>