import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { base64ToFile } from 'ngx-image-cropper';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private db: AngularFireDatabase,
    private afs: AngularFireStorage
  ) {}

  getSettings() {
    return new Promise<any>((resolve, reject) => {
      this.db
        .object('/settings')
        .snapshotChanges()
        .subscribe((snapshots) => {
          resolve(snapshots);
        });
    });
  }

  getImages() {
    return new Promise<any>((resolve, reject) => {
      this.db
        .object('/settings/imageSettings/images')
        .snapshotChanges()
        .subscribe((snapshots) => {
          resolve(snapshots);
        });
    });
  }

  ////
  getImagesBySize(size: number) {
    return this.db
      .list('/settings/imageSettings/images', (ref) =>
        ref.orderByChild('type').equalTo(size.toString()).limitToLast(20)
      )
      .valueChanges();
  }

  getImageById(id) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .object<any>('/settings/imageSettings/images')
        .snapshotChanges()
        .subscribe((snapshots) => {
          let images: any[];
          images = snapshots.payload.val();
          for (const img of images) {
            if (img.uniqueId == id) {
              resolve(img);
              break;
            }
          }
          resolve(null);
        });
    });
  }

  getTemplateById(id) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .object('/templateSettings/' + id)
        .snapshotChanges()
        .subscribe((snapshots) => {
          resolve(snapshots);
        });
    });
  }

  getTemplateData() {
    return new Promise<any>((resolve, reject) => {
      this.db
        .object('/templateSettings')
        .snapshotChanges()
        .subscribe((snapshots) => {
          resolve(snapshots);
        });
    });
  }

  updateImageDetails(data) {
    try {
      if (data.isDeleted) {
        this.db.object('/settings/imageSettings/images/' + data.index).update({
          isDeleted: data.isDeleted,
          updatedDate: data.updatedDate,
        });
      } else {
        this.db.object('/settings/imageSettings/images/' + data.index).update({
          author: data.author,
          name: data.name,
          tags: data.tags,
          uniqueId: data.uniqueId,
          url: data.url,
          thumbnail: data.thumbnail,
          status: data.status,
          //  selectedSizeIndex: data.selectedSizeIndex,
          type: data.type,
          updatedDate: data.updatedDate,
        });
      }
      return true;
    } catch (e) {
      return false;
    }
  }
  createImageDetails(data) {
    try {
      this.db.object('/settings/imageSettings/images/' + data.index).set({
        author: data.author,
        name: data.name,
        thumbnail: data.thumbnail,
        tags: data.tags,
        uniqueId: data.uniqueId,
        url: data.url,
        isDeleted: false,
        status: data.status,
        //  selectedSizeIndex: data.selectedSizeIndex,
        type: data.type,
        createdDate: data.createdDate,
        updatedDate: '',
      });
      return true;
    } catch (e) {
      // ////console.log(e);
      return false;
    }
  }
  mapModels(data, templateId, model, isUpdate: boolean) {
    try {
      if (isUpdate) {
        this.db
          .object(
            '/templateSettings/' + templateId + '/' + model + '/' + data.index
          )
          .update(data);
      } else {
        this.db
          .object(
            '/templateSettings/' + templateId + '/' + model + '/' + data.index
          )
          .set(data);
      }
      return true;
    } catch (e) {
      // ////console.log(e);
      return false;
    }
  }

  uploadImage(filename, data) {
    return new Promise<any>((resolve, reject) => {
      this.afs
        .ref('images/' + filename)
        .putString(data, 'data_url')
        .then((result) => {
          result.ref.getDownloadURL().then((value) => {
            resolve(value);
          });
        });
    });
  }

  uploadThumbnail(filename, data) {
    return new Promise<any>((resolve, reject) => {
      this.afs
        .ref('thumbnail/' + filename)
        .putString(data, 'data_url')
        .then((result) => {
          result.ref.getDownloadURL().then((value) => {
            resolve(value);
          });
        });
    });
  }

  uploadOverlayImage(file: File): Promise<any> {
    const path = '/overlayImages/' + Date.now() + file.name;
    return new Promise((resolve, reject) => {
      this.afs
        .upload(path, file)
        .then((result) => {
          result.ref
            .getDownloadURL()
            .then((value) => {
              // Download URL
              resolve(value);
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  uploadProfilePicture(base64: string): Promise<any> {
    const file = this.convertBase64ToFile(base64);
    const path = '/profilePic/' + file.name;
    return new Promise((resolve, reject) => {
      this.afs
        .upload(path, file, {})
        .then((result) => {
          result.ref
            .getDownloadURL()
            .then((value) => {
              // Download URL
              resolve(value);
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  deleteFile(url: string): Promise<any> {
    const ref = this.afs.refFromURL(url);
    return ref.delete().toPromise();
  }

  convertBase64ToFile(base64: string): File {
    const blob = base64ToFile(base64);

    return new File([blob], 'HLD' + Date.now() + '.jpg', { type: 'image/jpg' });
  }
}
