import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { Tasks } from '../models/tasks.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private db: AngularFireDatabase) { }

  getTasks() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return this.db.list('/tasks', ref => ref.orderByChild('userId').equalTo(user.uid))
    .snapshotChanges()
    .pipe(map( snaps => {
      return snaps.map( snap => {
        return{
          docId: snap.payload.key,
          taskData: snap.payload.val()
        } as {
          docId: string;
          taskData: Tasks
        };
      });
    }));
  }

  updateTask(docId, value: Tasks) {
    if (docId) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      value.userId = user.uid;
      return this.db.object('/tasks/'+ docId).update(value);
    }
    else {
      const user = JSON.parse(sessionStorage.getItem('user'));
      value.userId = user.uid;
      return this.db.object('/tasks/'+this.db.createPushId()).update(value);
    }

  }

  removeTask(docId){
    return this.db.object('/tasks/' + docId).remove();
  }
}
