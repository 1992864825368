import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Taskdoc } from 'src/app/shared/models/taskdoc.model';
import { Tasks } from 'src/app/shared/models/tasks.model';
import { NotyfService } from 'src/app/shared/services/notyf.service';
import { SwalService } from 'src/app/shared/services/swal.service';
import { TaskService } from 'src/app/shared/services/task.service';

@Component({
  selector: 'app-task-kanban',
  templateUrl: './task-kanban.component.html',
  styleUrls: ['./task-kanban.component.scss']
})
export class TaskKanbanComponent implements OnInit {
  public waiting: Taskdoc[] = [];
  public progress: Taskdoc[] = [];
  public done: Taskdoc[] = [];
  public taskModalButton = 'Add task';

  // Add or Edit task data
  public docId = '';
  public title = '';
  public status = '';
  public description = '';
  public endDate = '';
  public endTime = '';

  constructor(private taskService: TaskService,
              private notyf: NotyfService,
              private datePipe: DatePipe,
              private swalService: SwalService) { }

  ngOnInit(): void {
    this.taskService.getTasks().subscribe((value) => {
      this.waiting = [];
      this.progress = [];
      this.done = [];
      value.forEach(
        (task) => {
          switch (task.taskData.status) {
            case 'Done': this.done.push(task); break;
            case 'In Progress': this.progress.push(task); break;
            case 'Waiting': this.waiting.push(task); break;
          }
        }
      );
    });
  }

  openNewTaskModel(status?): void {
    this.docId = '';
    this.title = '';
    this.status = status ? status : '';
    this.description = '';
    this.endDate = '';
    this.endTime = '';
    this.taskModalButton = 'Add task';
  }

  openEditTaskModel(val: Tasks, docId): void {
    this.docId = docId;
    this.title = val.title;
    this.status = val.status;
    this.description = val.description;
    this.endDate = this.datePipe.transform(val.endDateTime, 'YYYY-MM-dd');
    this.endTime = this.datePipe.transform(val.endDateTime, 'HH:mm');
    this.taskModalButton = 'Edit task';
  }

  deleteTask(docId): void {
    this.swalService.footaskdeleteswal(
      (val: boolean) => {
        this.taskService.removeTask(docId);
      }
    );
  }

  addOrEditTask(): void {
    if (this.allDataIsValid()) {
      this.taskService.updateTask(this.docId, {
        userId: '',
        title: this.title,
        description: this.description,
        endDateTime: new Date(this.endDate + ' ' + this.endTime),
        status: this.status
      })
        .then(() => {
          if (this.taskModalButton == 'Add task') {
            this.notyf.success('Task created');
          }
          else {
            this.notyf.success('Task updated');
          }
        })
        .catch((error) => {
          this.notyf.error(error);
        });
    } else {
      if (this.taskModalButton == 'Add task') {
        this.notyf.warning('Task not added');
      }
      else {
        this.notyf.warning('Task not updated');
      }
    }
  }

  changeStatus(event: CdkDragDrop<Taskdoc[]>) {
    if (event.previousContainer !== event.container) {
      const task = event.previousContainer.data[event.previousIndex];
      this.openEditTaskModel(task.taskData, task.docId);
      this.status = this.findTaskGroup(event.container.data);
      this.addOrEditTask();
    }
  }

  findTaskGroup(tasks: Taskdoc[]): string {
    switch (tasks) {
      case this.waiting: return 'Waiting';
      case this.progress: return 'In Progress';
      case this.done: return 'Done';
    }
    return null;
  }

  allDataIsValid(): boolean {
    if (!this.title) { return false; }
    if (!this.status) { return false; }
    if (!this.description) { return false; }
    if (!this.endDate) { return false; }
    if (!this.endTime) { return false; }
    return true;
  }

}
