<!-- <div class="container-fluid px-3 ">
  <div class="row mt-4 mb-3">
    <div class="col-6 d-flex justify-content-between ps-0">
      <div class="me-lg-3">
        <a href="#" class="btn btn-green" data-bs-toggle="modal" data-bs-target="#KanbanAddCard"
          (click)="openNewTaskModel()"><span class="fas fa-plus me-2"></span><span>New Tasks</span></a>
      </div>
    </div>
  </div>
</div> -->

<div class="container-fluid kanban-container py-4 px-0 ">
  <div class="row d-flex flex-nowrap m-0">
    <div class="col-12 col-sm-6 col-lg-4 col-xl-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="fs-6 fw-bold mb-0">Waiting</h5>
      </div>
      <div id="kanbanColumn1" class="list-group kanban-list" cdkDropList #waitingList="cdkDropList"
        [cdkDropListData]="waiting" (cdkDropListDropped)="changeStatus($event)"
        [cdkDropListConnectedTo]="[doneList, progressList]">
        <div class="card px-3 py-2" *ngFor="let w of waiting" cdkDrag>
          <div class="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-2">
            <h3 class="h5 mb-0">{{w.taskData.title}}</h3>
            <div>
              <div class="dropdown">
                <button type="button" class="btn btn-sm fs-6 px-1 py-0 dropdown-toggle" id="dropdownMenuLink"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="far fa-edit"></span>
                </button>
                <div class="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                  aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item fw-normal rounded-top" href="#" data-bs-toggle="modal"
                    data-bs-target="#KanbanAddCard" (click)="openEditTaskModel(w.taskData,w.docId)"><span
                      class="fas fa-edit"></span>Edit task</a>
                  <div role="separator" class="dropdown-divider my-0"></div>
                  <a class="dropdown-item fw-normal text-danger rounded-bottom" href="javascript:void()"
                    (click)="deleteTask(w.docId)"><span class="fas fa-trash-alt"></span>Remove</a>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <p>{{w.taskData.description}}</p>
            <h5 class="fs-6 fw-normal mt-4">
              <span class="fas fa-clock me-2"></span>
              {{w.taskData.endDateTime | date:'EEE, MMMM d, y, h:mm:ss a'}}
            </h5>
          </div>
        </div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-outline-gray-700 dashed-outline new-card w-100" data-bs-toggle="modal"
          data-bs-target="#KanbanAddCard" (click)="openNewTaskModel('Waiting')">
          <span class="fas fa-plus me-2"></span> Add another card
        </button>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4 col-xl-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="fs-6 fw-bold mb-0">In progress</h5>
      </div>
      <div id="kanbanColumn2" class="list-group kanban-list" cdkDropList #progressList="cdkDropList"
        [cdkDropListData]="progress" (cdkDropListDropped)="changeStatus($event)"
        [cdkDropListConnectedTo]="[doneList, waitingList]">

        <div class="card shadow-sm px-3 py-2" *ngFor="let p of progress" cdkDrag>
          <div class="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-2">
            <h3 class="h5 mb-0">{{p.taskData.title}}</h3>
            <div>
              <div class="dropdown">
                <button type="button" class="btn btn-sm fs-6 px-1 py-0 dropdown-toggle" id="dropdownMenuLink"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="far fa-edit"></span>
                </button>
                <div class="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                  aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item fw-normal rounded-top" href="#" data-bs-toggle="modal"
                    data-bs-target="#KanbanAddCard" (click)="openEditTaskModel(p.taskData,p.docId)"><span
                      class="fas fa-edit"></span>Edit task</a>
                  <div role="separator" class="dropdown-divider my-0"></div>
                  <a class="dropdown-item fw-normal text-danger rounded-bottom" href="javascript:void()"
                    (click)="deleteTask(p.docId)"><span class="fas fa-trash-alt"></span>Remove</a>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <p>{{p.taskData.description}}</p>
            <h5 class="fs-6 fw-normal mt-4">
              <span class="fas fa-clock me-2"></span>
              {{p.taskData.endDateTime | date:'EEE, MMMM d, y, h:mm:ss a'}}
            </h5>
          </div>
        </div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-outline-gray-700 dashed-outline w-100 new-card" data-bs-toggle="modal"
          data-bs-target="#KanbanAddCard" (click)="openNewTaskModel('In Progress')">
          <span class="fas fa-plus me-2"></span> Add another card
        </button>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4 col-xl-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="fs-6 fw-bold mb-0">Done</h5>
      </div>
      <div id="kanbanColumn3" class="list-group kanban-list" cdkDropList #doneList="cdkDropList"
        [cdkDropListData]="done" (cdkDropListDropped)="changeStatus($event)"
        [cdkDropListConnectedTo]="[progressList, waitingList]">

        <div class="card shadow-sm px-3 py-2" *ngFor="let d of done" cdkDrag>
          <div class="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-2">
            <h3 class="h5 mb-0">{{d.taskData.title}}</h3>
            <div>
              <div class="dropdown">
                <button type="button" class="btn btn-sm fs-6 px-1 py-0 dropdown-toggle" id="dropdownMenuLink"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="far fa-edit"></span>
                </button>
                <div class="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                  aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item fw-normal rounded-top" href="#" data-bs-toggle="modal"
                    data-bs-target="#KanbanAddCard" (click)="openEditTaskModel(d.taskData,d.docId)"><span
                      class="fas fa-edit"></span>Edit task</a>
                  <div role="separator" class="dropdown-divider my-0"></div>
                  <a class="dropdown-item fw-normal text-danger rounded-bottom" href="javascript:void()"
                    (click)="deleteTask(d.docId)"><span class="fas fa-trash-alt"></span>Remove</a>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <p>{{d.taskData.description}}</p>
            <h5 class="fs-6 fw-normal mt-4">
              <span class="fas fa-clock me-2"></span>
              {{d.taskData.endDateTime | date:'EEE, MMMM d, y, h:mm:ss a'}}
            </h5>
          </div>
        </div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-outline-gray-700 dashed-outline w-100 new-card" data-bs-toggle="modal"
          data-bs-target="#KanbanAddCard" (click)="openNewTaskModel('Done')">
          <span class="fas fa-plus me-2"></span> Add another card
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="KanbanAddCard" tabindex="-1" aria-labelledby="KanbanAddCardLabel4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{taskModalButton}}
        </h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <div class="modal-body pb-0">
        <div class="mb-3">
          <input type="text" class="input_custom_design form-control" id="title"
            placeholder="Enter a title for this task" [(ngModel)]="title">
        </div>
        <div class="mb-3">
          <textarea class="input_custom_design form-control" id="description"
            placeholder="Enter a description for this task…" rows="3" [(ngModel)]="description"></textarea>
        </div>
        <div class="row align-items-center">
          <div class="col-md-4 mb-3">
            <input type="date" class="input_custom_design form-control" id="endDate"
              placeholder="Select a task end date" [(ngModel)]="endDate">
          </div>
          <div class="col-md-4 mb-3">
            <input type="time" class="input_custom_design form-control" id="endTime"
              placeholder="Select a task end time" [(ngModel)]="endTime">
          </div>
          <div class="col-md-4 mb-3">
            <select class="form-select input_custom_design mb-0" id="status" [(ngModel)]="status">
              <option selected>Select</option>
              <option value="Done">Done</option>
              <option value="In Progress">In Progress</option>
              <option value="Waiting">Waiting</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 pt-0 justify-content-end">
        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-success" (click)="addOrEditTask()"
          data-bs-dismiss="modal">{{taskModalButton}}</button>
      </div>
    </div>
  </div>
</div>