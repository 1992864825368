import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotyfService } from 'src/app/shared/services/notyf.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-access-req',
  templateUrl: './access-req.component.html',
  styleUrls: ['./access-req.component.scss']
})
export class AccessReqComponent implements OnInit {

  newreq: any = [];
  loading = false

  constructor(
    private userServ: UserDetailsService,
    private notyf: NotyfService,
    private router: Router) { }

  ngOnInit(): void {
    this.userServ.getnewreq().subscribe((response) => {
      this.newreq = response
    })
  }

  accept(docId) {
    this.loading = true;
    this.userServ.verification(docId, true, true)
      .then(() => {
        this.loading = false;
        this.notyf.success('Sucessfully updated')
      })
      .catch(() => {
        this.loading = false;
        this.notyf.error('Error in update')
      })
  }

  reject(docId) {
    this.loading = true;
    this.userServ.verification(docId, true, false)
      .then(() => {
        this.loading = false;
        this.notyf.success('Sucessfully updated')
      })
      .catch(() => {
        this.loading = false;
        this.notyf.error('Error in update')
      })
  }

}
