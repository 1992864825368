<div class="row mt-4">
  <div class="col-12 col-xl-4">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card text-center p-0" style="border-radius: 0.5rem">
          <div
            class="profile-cover rounded-top"
            style="
              background-image: url('https://static6.depositphotos.com/1001026/673/i/600/depositphotos_6732170-stock-photo-glass-city.jpg');
            "
          ></div>
          <div class="card-body pb-5">
            <img
              *ngIf="!photoURL"
              src="../../../assets/img/no-image-icon-md.png"
              class="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4"
            />
            <img
              *ngIf="photoURL"
              [src]="processImgUrl(photoURL)"
              class="rounded-circle my-3 img-responsive"
              class="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4"
            />
            <h4 class="h3">{{ displayName }}</h4>
            <h5 class="fw-normal" *ngIf="designation == ''">Not selected</h5>
            <h5 class="fw-normal" *ngIf="designation != ''">
              {{ designation }}
            </h5>
            <p class="text-gray mb-4">{{ phone }}</p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-body mb-4" style="border-radius: 0.5rem">
          <h2 class="h5 mb-4">Select profile photo</h2>
          <div class="d-flex align-items-center">
            <div class="me-3">
              <!-- Avatar -->
              <div class="user-avatar xl-avatar">
                <img
                  *ngIf="!photoURL"
                  src="../../../assets/img/no-image-icon-md.png"
                  class="rounded"
                />
                <img
                  *ngIf="photoURL"
                  [src]="processImgUrl(photoURL)"
                  class="rounded-circle my-3 img-responsive"
                  class="rounded"
                />
              </div>
            </div>
            <div class="file-field">
              <div class="d-flex justify-content-xl-center ms-xl-3">
                <div class="d-flex">
                  <span class="icon icon-md"
                    ><span class="fas fa-paperclip me-3"></span
                  ></span>
                  <input
                    #imageFileInput
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    (change)="onSelectFileChange($event, content)"
                    ngModel
                    name="photoURL"
                    required
                  />
                  <div class="d-md-block text-left">
                    <div class="fw-normal text-dark mb-1">Choose Image</div>
                    <div class="text-gray small">
                      JPG, GIF or PNG. Max size of 5MB
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-8">
    <div class="card card-body mb-4" style="border-radius: 0.5rem">
      <h2 class="h5 mb-3">General information</h2>
      <div class="row">
        <div class="col-md-4 mb-2">
          <div>
            <label for="first_name">Display Name</label>
            <input
              class="input_custom_design form-control"
              [(ngModel)]="displayName"
              id="first_name"
              type="text"
              placeholder="Enter your first name"
              required
            />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <label for="designation">Designation</label>
          <select
            class="form-select input_custom_design mb-0"
            id="designation"
            [(ngModel)]="designation"
            name="designation"
          >
            <option value="" selected>Select</option>
            <option value="Broker">Broker</option>
            <option value="Real Estate Broker">Real Estate Broker</option>
            <option value="Broker Real Estate Agent">
              Broker Real Estate Agent
            </option>
            <option value="REALTOR®️ Broker">REALTOR®️ Broker</option>
            <option value="REALTOR®️">REALTOR®️</option>
            <option value="Real Estate Agent">Real Estate Agent</option>
            <option value="REALTOR®️ Salesperson">REALTOR®️ salesperson</option>
          </select>
        </div>

        <div class="col-md-4 mb-2">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              class="input_custom_design form-control"
              [(ngModel)]="email"
              id="email"
              type="email"
              placeholder="name@company.com"
              required
            />
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4 mb-2">
          <label for="birthday">Birthday</label>
          <div class="input-group">
            <input
              class="input_custom_design form-control"
              [(ngModel)]="userData.dob"
              id="birthday"
              type="date"
              placeholder="dd/mm/yyyy"
              required
            />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <label for="gender">Gender</label>
          <select
            class="form-select input_custom_design mb-0"
            id="gender"
            [(ngModel)]="userData.gender"
            aria-label="Gender select example"
          >
            <option value="" selected>Gender</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </select>
        </div>
        <div class="col-md-4 mb-2">
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
              class="input_custom_design form-control"
              id="phone"
              type="text"
              placeholder="+12-345 678 910"
              [(ngModel)]="phone"
              mask="(000) 000-0000"
              required
            />
          </div>
        </div>
      </div>
      <h2 class="h5 my-3">Location Information</h2>
      <!-- <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p> -->
      <div class="row">
        <div class="col-sm-9 mb-2">
          <div class="form-group">
            <label for="address">Address</label>
            <input
              class="input_custom_design form-control"
              id="address"
              type="text"
              [(ngModel)]="userData.address"
              placeholder="Enter your home address"
              required
            />
          </div>
        </div>
        <div class="col-sm-3 mb-2">
          <div class="form-group">
            <label for="number">Area</label>
            <input
              class="input_custom_design form-control"
              id="area"
              type="text"
              [(ngModel)]="userData.area"
              placeholder="Area"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 mb-2">
          <div class="form-group">
            <label for="city">City</label>
            <input
              class="input_custom_design form-control"
              id="city"
              type="text"
              placeholder="City"
              [(ngModel)]="userData.city"
              required
            />
          </div>
        </div>
        <div class="col-sm-4 mb-2">
          <label for="state">State</label>
          <input
            class="input_custom_design form-control"
            id="state"
            name="state"
            placeholder="State"
            [(ngModel)]="userData.state"
            required
          />
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label for="zip">ZIP</label>
            <input
              class="input_custom_design form-control"
              id="zip"
              type="tel"
              placeholder="ZIP"
              [(ngModel)]="userData.zip"
              required
            />
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button
          type="button"
          class="btn btn-dark"
          (click)="updateProfile()"
          [hidden]="hint"
        >
          Save All
        </button>
        <button type="button" class="btn btn-primary" [hidden]="!hint" disabled>
          {{ loadingStatus }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="moda">
    <form>
      <div class="col-md-12">
        <form accept-charset="UTF-8" method="POST" target="_blank">
          <ng-container>
            <div class="row">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">
                    Image Editor
                  </h4>
                  <div>
                    <button
                      type="button"
                      class="btn"
                      aria-label="Close"
                      (click)="modal.dismiss('Cross click')"
                    >
                      <span aria-hidden="true">X</span>
                    </button>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6">
                      <!-- <label style="font-size: 12px;">Select Image</label> -->
                      <input
                        type="file"
                        accept="image/*"
                        #file
                        (change)="onSelectFileChange($event)"
                      />
                      <!-- <button style="margin-right: 10px;" (click)="file.click()" type="button"
                        class="btn btn-secondary">
                        Select Image
                      </button> -->
                    </div>
                    <div class="col-md-6 fliping" style="text-align: right">
                      <!-- <label style="font-size: 12px;">Flip</label> -->
                      <div>
                        <img
                          src="../../assets/img/brand/left.png"
                          (click)="flipHorizontal()"
                          style="
                            background-color: rgb(248, 248, 248);
                            width: 40px;
                            padding: 7px;
                            margin-right: 10px;
                          "
                        />
                        <img
                          src="../../assets/img/brand/flip.png"
                          (click)="flipVertical()"
                          style="
                            background-color: rgb(248, 248, 248);
                            width: 40px;
                            padding: 7px;
                            margin-right: 10px;
                          "
                        />
                        <img
                          src="../../assets/img/brand/phone.png"
                          (click)="transformImage(1)"
                          style="
                            background-color: rgb(248, 248, 248);
                            width: 40px;
                            padding: 7px;
                            margin-right: 10px;
                          "
                        />
                        <img
                          *ngIf="isMAR"
                          src="../../assets/img/brand/expand-screen.png"
                          (click)="fitFullImage()"
                          style="
                            background-color: rgb(248, 248, 248);
                            width: 40px;
                            padding: 7px;
                            margin-right: 10px;
                          "
                        />
                        <img
                          *ngIf="!isMAR"
                          src="../../assets/img/brand/minimize-arrows.png"
                          (click)="fitFullImage()"
                          style="
                            background-color: rgb(248, 248, 248);
                            width: 40px;
                            padding: 7px;
                            margin-right: 10px;
                          "
                        />
                      </div>
                      <!-- <div class="col-12" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-primary me-1"
                          (click)="flipHorizontal()">Horizontal</button>
                        <button type="button" class="btn btn-primary" (click)="flipVertical()">Vertical</button>
                      </div> -->
                    </div>
                    <!-- <div class="col-md-2  rotate"> -->
                    <!-- <label style="font-size: 12px;">Rotate</label> -->
                    <!-- <ngx-slider [(value)]="rotateValue" (valueChange)=transformImage(1)
                        [options]="rotateSliderOptions"></ngx-slider> -->
                    <!-- <div>
                        <img src="../../../../assets/img/brand/phone.png" (click)="transformImage(1)"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                      </div>
                    </div>
                    <div class="col-md-2  rotate"> -->
                    <!-- <label style="font-size: 12px;">Fit Full Image</label> -->
                    <!-- <ngx-slider [(value)]="rotateValue" (valueChange)=transformImage(1)
                        [options]="rotateSliderOptions"></ngx-slider> -->
                    <!-- <div>
                        <img *ngIf="isMAR" src="../../../../assets/img/brand/expand-screen.png" (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                        <img *ngIf="!isMAR" src="../../../../assets/img/brand/minimize-arrows.png"
                          (click)="fitFullImage()"
                          style="background-color: rgb(248, 248, 248); width: 40px;padding: 7px;">
                      </div> -->
                    <!-- </div> -->
                    <div class="col-md-12">
                      <label style="font-size: 12px">Zoom Image</label>
                      <ngx-slider
                        [(value)]="zoomValue"
                        (valueChange)="transformImage(2)"
                        [options]="zoomSliderOptions"
                      >
                      </ngx-slider>
                    </div>
                    <div class="col-md-12">
                      <image-cropper
                        [imageBase64]="photoPIC"
                        [maintainAspectRatio]="isMAR"
                        [containWithinAspectRatio]="false"
                        [aspectRatio]="ratio"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [canvasRotation]="0"
                        [transform]="transform"
                        [alignImage]="'center'"
                        [style.display]="null"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        [transform]="transform"
                      >
                      </image-cropper>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    style="margin-right: 10px"
                    type="button"
                    class="btn float-right btn-secondary"
                    (click)="transform = {}"
                  >
                    Reset Image
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary float-right"
                    (click)="onCloseHandled()"
                  >
                    Save & Close
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
    </form>
  </div>
</ng-template>
