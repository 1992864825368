<div class="d-flex justify-content-between align-items-center pb-2 pt-4 pb-md-4">
    <div>
        <a href="javascript: void()" class="btn btn-secondary text-dark mb-3 mb-md-0"><span
                class="fas fa-plus me-2"></span><span>Compose</span></a>
    </div>
    <div class="d-block d-sm-flex">
        <div class="btn-group mb-3 me-3 mb-md-0 d-none d-md-inline-flex">
            <button type="button" class="btn btn-dark"><span class="fas fa-archive"></span></button>
            <button type="button" class="btn btn-dark"><span class="fas fa-exclamation-circle"></span></button>
            <button type="button" class="btn btn-dark"><span class="fas fa-trash-alt"></span></button>
        </div>
        <div class="mb-3 mb-md-0">
            <button type="button" class="btn btn-dark dropdown-toggle arrow-none" data-bs-toggle="dropdown"
                aria-expanded="false">
                More
                <span class="icon icon-small ms-1"><span class="fas fa-chevron-down"></span></span>
            </button>
            <div class="dropdown-menu pb-0">
                <span class="ps-3 fw-bold text-dark">More Options :</span>
                <a class="dropdown-item" href="#">Mark as Unread</a>
                <a class="dropdown-item" href="#">Add to Tasks</a>
                <a class="dropdown-item" href="#">Add Star</a>
                <a class="dropdown-item rounded-bottom" href="#">Mute</a>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="  message-wrapper border border-gray-400 bg-white shadow-sm rounded ">
        <div class="card hover-state border-bottom rounded-0 rounded-top py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck1">
                        <label class="form-check-label" for="mailCheck1"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar1" name="star">
                        <label class="rating-star-label" for="InboxStar1">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3 pe-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <img src="../assets/img/team/profile-picture-1.jpg" class="user-avatar rounded-circle me-3"
                            alt="Avatar">
                        <span class="h6 fw-normal mb-0">Roy Fendley</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">11:01 AM</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck2">
                        <label class="form-check-label" for="mailCheck2"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar2" name="star">
                        <label class="rating-star-label" for="InboxStar2">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <img src="../assets/img/team/profile-picture-3.jpg" class="user-avatar rounded-circle me-3"
                            alt="Avatar">
                        <span class="h6 fw-normal mb-0">Bonnie Green</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">10:23 AM</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck3">
                        <label class="form-check-label" for="mailCheck3"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar3" name="star">
                        <label class="rating-star-label" for="InboxStar3">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <div class="user-avatar xs-avatar bg-secondary me-3"><span>SA</span></div>
                        <span class="h6 fw-normal mb-0">Scott Anderson</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">10:00 AM</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck4">
                        <label class="form-check-label" for="mailCheck4"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar4" name="star">
                        <label class="rating-star-label" for="InboxStar4">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <img src="../assets/img/team/profile-picture-4.jpg" class="user-avatar rounded-circle me-3"
                            alt="Avatar">
                        <span class="h6 fw-normal mb-0">Ronnie Buchanan</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">07:45 AM</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck5">
                        <label class="form-check-label" for="mailCheck5"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar5" name="star">
                        <label class="rating-star-label" for="InboxStar5">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <img src="../assets/img/team/profile-picture-3.jpg" class="user-avatar rounded-circle me-3"
                            alt="Avatar">
                        <span class="h6 fw-normal mb-0">Jane Rinehart</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">07:30 AM</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck6">
                        <label class="form-check-label" for="mailCheck6"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar6" name="star">
                        <label class="rating-star-label" for="InboxStar6">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <div class="user-avatar xs-avatar bg-success me-3"><span>WG</span></div>
                        <span class="h6 fw-normal mb-0">William Ginther</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">06:10 AM</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck7">
                        <label class="form-check-label" for="mailCheck7"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar7" name="star">
                        <label class="rating-star-label" for="InboxStar7">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <div class="user-avatar xs-avatar bg-purple me-3"><span>GD</span></div>
                        <span class="h6 fw-normal mb-0">George Driskell</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">Jun 14</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck8">
                        <label class="form-check-label" for="mailCheck8"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar8" name="star">
                        <label class="rating-star-label" for="InboxStar8">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <img src="../assets/img/team/profile-picture-4.jpg" class="user-avatar rounded-circle me-3"
                            alt="Avatar">
                        <span class="h6 fw-normal mb-0">Ronnie Buchanan</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">Jun 15</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card hover-state border-bottom rounded-0 py-3">
            <div class="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0">
                <div class="col-1 align-items-center px-0 d-none d-lg-flex">
                    <div class="form-check inbox-check me-2">
                        <input class="form-check-input" type="checkbox" value="" id="mailCheck9">
                        <label class="form-check-label" for="mailCheck9"></label>
                    </div>
                    <div class="rating-star d-none d-lg-inline-block">
                        <input type="checkbox" id="InboxStar9" name="star">
                        <label class="rating-star-label" for="InboxStar9">
                            <span class="sr-only">Star</span>
                        </label>
                    </div>
                </div>
                <div class="col-10 col-lg-2 ps-0 ps-lg-3">
                    <a href="#" class="d-flex align-items-center">
                        <img src="../assets/img/team/profile-picture-4.jpg" class="user-avatar rounded-circle me-3"
                            alt="Avatar">
                        <span class="h6 fw-normal mb-0">Jane Rinehart</span>
                    </a>
                </div>
                <div class="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4">
                    <div class="text-muted small d-none d-lg-block">Jun 18</div>
                    <div class="btn-group ms-3">
                        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon icon-sm">
                                <span class="fas fa-ellipsis-h icon-dark"></span>
                            </span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end py-0">
                            <a class="dropdown-item rounded-top" href="#"><span class="fas fa-user-shield me-2"></span>
                                Reset Pass</a>
                            <a class="dropdown-item" href="javascript: void()"><span class="fas fa-eye me-2"></span>View
                                Details</a>
                            <a class="dropdown-item text-danger rounded-bottom" href="#"><span
                                    class="fas fa-user-times me-2"></span>Suspend</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0">
                    <a href="javascript: void()" class="fw-normal text-gray truncate-text">
                        <span class="fw-normal ps-lg-5">Long time no see - Can we help you set up email
                            forwarding?</span>
                        <span class="d-none d-md-inline">We’ve noticed you haven’t set up email forwarding and we could
                            help
                            you</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="row p-4">
            <div class="col-7 mt-1">
                Showing 1 - 20 of 289
            </div> <!-- end col-->
            <div class="col-5">
                <div class="btn-group float-end">
                    <a href="#" class="btn btn-light"><span class="fas fa-chevron-left"></span></a>
                    <a href="#" class="btn btn-dark"><span class="fas fa-chevron-right"></span></a>
                </div>
            </div> <!-- end col-->
        </div>
    </div>
</div>