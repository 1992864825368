<!-- Section -->
<section class="d-flex align-items-center sec" style="overflow: hidden;">
  <div class="container">

    <div class="row justify-content-center form-bg-image" style="background: url('assets/img/illustrations/signin.svg') ;
    background-size: 1300px 500px !important; background-attachment: fixed;
    background-position: 109px !important; padding-top: 0px;">

      <p class="text-center">
        <img src="../../assets/img/brand/img.PNG" alt="Volt Logo" style="width: 20%;">
      </p>


      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
          <div class="text-center text-md-center mb-4 mt-md-0">
            <h1 class="mb-0 h3">Sign in</h1>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="redirectToHome()" class="mt-4">
            <!-- Form -->
            <div class="form-group mb-3">
              <label for="user_id">Email Address <span class="text-danger">*</span></label>
              <div class="input-group">
                <input placeholder="example@company.com" formControlName="email"
                  autofocus required
                  type="email" class="input_custom_design form-control" [class.is-invalid]="Email.touched && Email.invalid" [class.is-valid]="Email.valid">
                <div *ngIf="Email.touched && Email.invalid" class="invalid-feedback">Enter a valid email address</div>
              </div>
            </div>
            <!-- End of Form -->
            <div class="form-group">
              <!-- Form -->
              <div class="form-group mb-3">
                <label for="password">Password <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input placeholder="Password" formControlName="password" required autocomplete="current-password"
                    type="text" class="input_custom_design form-control"
                    [class.passField]="passwordHide"
                    [class.showPassField]="!passwordHide" [class.is-invalid]="Password.touched && Password.invalid"
                    [class.is-valid]="Password.valid">
                    <button class="btn btn-icon-only" type="button">
                      <a href="javascript:void()" (click)="passwordHide = !passwordHide"><i class="fa"
                          [class.fa-eye-slash]="passwordHide" [class.fa-eye]="!passwordHide" aria-hidden="true"></i></a>
                    </button>
                  <div *ngIf="Password.touched && Password.invalid" class="invalid-feedback">Enter a valid password
                  </div>
                </div>
              </div>
              <!-- End of Form -->
              <div class="d-flex justify-content-between align-items-top mb-4">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="remember">
                  <label class="form-check-label mb-0" for="remember">
                    Remember me
                  </label>
                </div>
                <div><a routerLink="/forgot" class="small text-right">Lost password?</a></div>
              </div>
            </div>
            <div class="d-grid">
              <button type="submit" class="btn btn-dark" [hidden]="hint" [disabled]="loginForm.invalid">Sign in</button>
              <button class="btn btn-primary" type="button" disabled [hidden]="!hint">
                <!-- <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> -->
                Loading...
              </button>
            </div>
          </form>
          <div class="d-flex justify-content-center align-items-center mt-4">
            <span class="fw-normal">
              Not registered?
              <a [routerLink]="[ '/sign-up']">Create account</a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="login_footer">
      <a href="javascript:void(0);" class="link_password">Support &nbsp;/&nbsp;</a>
      <a href="javascript:void(0);" class="link_password">HomeLifeApp.com</a>
      <div class="fill-remaining-space"></div>
      <div class="secure_login">
        <img alt="secure login"
          src="https://res.cloudinary.com/ffdc/image/upload/v1584350622/security_24px_outlined_giy3g4.svg">
        <div class="sub_title"><span>Secure</span> login</div>
      </div>
    </div> -->
  </div>
</section>
