import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'templateEditFilter'
})
export class TemplateEditFilterPipe implements PipeTransform {

  transform(data: Array<any>, index: number): Array<any> {
    if (index == -1) {
      return data;
    }
    else {
      return data.filter((x, i) => {
        if (i != index) {
          return x;
        }
      });
    }
  }

}
