import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { EditSettingsService } from '../../../shared/services/edit-settings.service';
import { MoveClampedToParentDirective } from '../../../shared/directives/move-clamped-to-parent.directive';
import { EditableTextComponent } from '../editable-text/editable-text.component';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
import { CustomFont } from 'src/app/shared/models/custom-font.model';
import { FontManagerService } from 'src/app/shared/services/font-manager.service';

@Component({
  selector: 'app-overlay-texts',
  templateUrl: './overlay-texts.component.html',
  styleUrls: ['./overlay-texts.component.css'],
})
export class OverlayTextsComponent implements OnInit {
  public postTypes: CustomPostType[] = [];
  public fontsData: CustomFont[] = [];
  public sizeData: CustomPostType = null;

  @ViewChildren(MoveClampedToParentDirective)
  moveClampedToParents: QueryList<MoveClampedToParentDirective>;
  @ViewChildren(EditableTextComponent)
  editableTexts: QueryList<EditableTextComponent>;

  @Input() settings: any;

  constructor(
    private editSettingsService: EditSettingsService,
    private fontsManager: FontManagerService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    // subscribe
    this.editSettingsService.storeOverlays.subscribe((isClear) =>
      this.onUpdateOverlays(isClear)
    );
    if (this.settings) {
      this.postTypes = this.settings.sizeSettings.sizes;
      const imgData = this.settings.imageSettings.images.filter(
        (x: any) => x.uniqueId == this.settings.selectedImageUniqueId
      );
      this.sizeData = this.getPostData(imgData.type);
    }
    this.fontsManager.getFonts().subscribe((value) => {
      this.fontsData = value;
      for (const item of this.fontsData) {
        this.addFont(item.Url);
      }
    });
  }

  setHeightAndWidth(data) {
    this.settings.textSettings.models[data.uniqueId].height = data.height;
    this.settings.textSettings.models[data.uniqueId].width = data.width;
  }

  onUpdateOverlays(isClear: boolean) {
    // update
    if (this.moveClampedToParents) {
      this.moveClampedToParents.forEach((item) => item.update());
    }

    // clear currently selected controls
    if (isClear) {
      // update selectedModel helper
      this.settings.selectedModelUniqueId = -1;

      // reset controls
      this.editableTexts.map((item) => item.reset());
    }
  }

  onSelected(index) {
    // only one selected overlay at a time
    this.editSettingsService.updateOverlays(true);

    // update selectedModel helper
    this.settings.selectedModelUniqueId = index;

    // update edit text overlay
    const editableTextComponent = this.editableTexts.filter(
      (item) => item.model.uniqueId === index
    );
    if (editableTextComponent.length === 1) {
      this.editSettingsService.updateEditText(editableTextComponent[0]);
    }
  }

  getPostData(name: string): CustomPostType {
    for (const item of this.postTypes) {
      if (item.Name == name) {
        return item;
      }
    }
  }

  getFont(data: any): CustomFont {
    for (const f of this.fontsData) {
      if (f.Name == data.fontName) {
        return f;
      }
    }
  }

  addFont(fontUrl: string, callback?) {
    if (fontUrl) {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';
      try {
        document.body.appendChild(link).onload = () => {
          if (callback) {
            callback();
          }
        };
      } catch (error) {
        ////console.log(error);
      }
    }
  }
}
