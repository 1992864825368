import { Component, OnInit } from '@angular/core';
import { fabric } from 'fabric';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit {
  width = '1080';
  height = '1080';

  constructor() {}
  protected _canvas?: fabric.Canvas;

  public ngOnInit(): void {
    var canvas = new fabric.Canvas('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    var rect = new fabric.Rect({
      top: 100,
      left: 100,
      width: 60,
      height: 70,
      fill: 'red',
    });

    canvas.add(rect);

    canvas.item(0).selectable = true;
  }
}
