import { Options } from '@angular-slider/ngx-slider';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { AuthService } from '../shared/services/auth.service';
import { EditSettingsService } from '../shared/services/edit-settings.service';
import { NotyfService } from '../shared/services/notyf.service';
import { SettingsService } from '../shared/services/settings.service';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  signUpForm;
  public passwordScore = 0;

  @Input() settings: any;

  @ViewChild('imageFileInput') imageFileInput: ElementRef;

  public photoURL = '../../assets/img/avater__.jpg';
  public photoPIC = '../../assets/img/no-image-icon-md.png';
  transform: ImageTransform = {};
  rotateValue = 0;
  rotateSliderOptions: Options = {
    floor: 1,
    ceil: 360,
  };
  zoomValue = 0;
  zoomSliderOptions: Options = {
    floor: 1,
    ceil: 10,
  };
  ratio = 65 / 78;
  templateModel: NgbModalRef;
  modalShown = false;
  croppedImage = '../../assets/img/no-image-icon-md.png';
  loadingStatus = 'Loading';

  public isMAR = true;

  hint = false;
  passwordHide = true;
  confirmPassHide = true;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private editservice: EditSettingsService,
    private fUp: SettingsService,
    private router: Router,
    private notyf: NotyfService
  ) {}

  ngOnInit(): void {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
        Validators.required,
      ]),
      displayName: new FormControl('', [Validators.required]),
      designation: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
      // accesscode: new FormControl('', [Validators.required]),
      checked: new FormControl('', [Validators.required]),
      treb: new FormControl('', [Validators.required]),
      reco: new FormControl('', [Validators.required]),
    });

    this.Password.valueChanges.subscribe((value) => {
      this.passwordScore = this.checkstrength(value);
      if (this.passwordScore > 0 && this.passwordScore < 50) {
        this.Password.setErrors({ weak: true });
      }
    });

    this.PhoneNumber.valueChanges.subscribe((value) => {
      if (this.checkPhnum(value) || value.length < 10) {
        this.PhoneNumber.setErrors({ invalid: true });
      }
    });
  }

  onSubmit(): void {
    if (this.photoURL != '../../assets/img/avater__.jpg') {
      // if (this.signUpForm.value.accesscode == 'miracle1') {
      this.hint = true;
      this.loadingStatus = 'Creating Account';
      this.authService
        .signUp({
          email: this.Email.value,
          password: this.Password.value,
        })
        .then((result: any) => {
          this.loadingStatus = 'Uploading Picture';
          this.fUp
            .uploadProfilePicture(this.croppedImage)
            .then((fileUrl) => {
              ////console.log(fileUrl);
              this.loadingStatus = 'Saving your data';
              this.authService
                .createUser(result.user.uid, {
                  displayName: this.DisplayName.value,
                  emailId: this.Email.value,
                  imageData: fileUrl,
                  phoneNo: this.PhoneNumber.value,
                  designation: this.Designation.value,
                  treb: this.treb.value,
                  reco: this.reco.value,
                  verify: false,
                  status: false,
                })
                .then(() => {
                  this.hint = false;
                  this.notyf.success('Account created');
                  this.router.navigate(['/login']);
                })
                .catch((reason) => {
                  this.hint = false;
                  this.notyf.error(reason.message);
                });
            })
            .catch((error) => {
              this.hint = false;
              this.notyf.error('File Upload Failed');
              setTimeout(
                () => this.notyf.info('Creating account with default picture'),
                1500
              );
              this.loadingStatus = 'Saving your data';
              this.authService
                .createUser(result.user.uid, {
                  displayName: this.DisplayName.value,
                  emailId: this.Email.value,
                  imageData: '../../assets/img/no-image-icon-md.png',
                  phoneNo: this.PhoneNumber.value,
                  designation: this.Designation.value,
                  treb: this.treb.value,
                  reco: this.reco.value,
                  verify: false,
                  status: false,
                })
                .then(() => {
                  this.hint = false;
                  this.notyf.success('Account created');
                  this.router.navigate(['/login']);
                })
                .catch((reason) => {
                  this.hint = false;
                  this.notyf.error(reason.message);
                });
            });
        })
        .catch((reason) => {
          this.hint = false;
          this.notyf.error(reason);
        });
      // }
      // else {
      //   this.notyf.error('Please enter valid accesscode');
      // }
    } else {
      this.notyf.error('Please select profile image');
    }
  }

  /**
   *
   * @param $event file input event
   * @param content template reference of the modal that is passed via html
   */
  onSelectFileChange(event, content?) {
    // cancel check
    const files = event.srcElement.files;
    if (files.length > 0) {
      const f: File = files[0];
      // 5242880 is for 5MB
      if (f.size <= 5242880) {
        // base64 encode file (TODO extract to a service)
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageFileInput.nativeElement.value = null;
          this.photoPIC = (e.target as FileReader).result as string;
          if (this.photoURL == '../../assets/img/no-image-icon-md.png') {
            this.photoURL = this.photoPIC;
          }
          this.croppedImage = this.processImgUrl(this.photoPIC);
          if (!this.modalShown && content) {
            this.cropImage(content);
          }
        };
        reader.readAsDataURL(f);
      } else {
        this.notyf.error('Image is too big');
        setTimeout(() => this.notyf.info('Size should be less than 5MB'), 1500);
      }
    }
  }

  check_image() {
    if (this.photoURL == '../../assets/img/avater__.jpg') {
      return true;
    } else {
      return false;
    }
  }

  processImgUrl(url) {
    return this.editservice.processImgUrl(url, 200, 100);
  }
  transformImage(prop) {
    if (prop === 1) {
      this.rotateValue += 90;
      this.rotateValue = this.rotateValue == 360 ? 0 : this.rotateValue;
      this.transform = {
        ...this.transform,
        rotate: this.rotateValue,
      };
    } else if (prop === 2) {
      this.transform = {
        ...this.transform,
        scale: this.zoomValue,
      };
    }
  }
  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }
  onCloseHandled() {
    this.templateModel.close();
    this.photoURL = this.croppedImage;
  }

  fitFullImage(): void {
    this.isMAR = this.isMAR ? false : true;
    const x = this.photoURL;
    this.photoURL = '';
    setTimeout(() => {
      this.photoURL = x;
    }, 100);
  }

  getImageFit(): string {
    return this.isMAR ? 'cover' : 'fill';
  }
  cropImage(content) {
    this.transform = {};
    this.templateModel = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      size: 'md',
    });

    this.monitorModel();
  }
  imageCropped(event: ImageCroppedEvent) {
    setTimeout(() => {
      this.croppedImage = event.base64;
    }, 100);
  }

  monitorModel() {
    this.templateModel?.shown.subscribe(() => {
      this.modalShown = true;
    });

    this.templateModel?.closed.subscribe((value) => {
      this.modalShown = false;
    });

    this.templateModel?.dismissed.subscribe((value) => {
      this.modalShown = false;
    });
  }

  /*  checkemail(email_add) {
     const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return regularExpression.test(String(email_add).toLowerCase());
   } */

  checkstrength(pass) {
    let score = 0;
    // award every unique letter until 5 repetitions
    const letters = {};
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }
    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (const check in variations) {
      variationCount += variations[check] ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    // ////console.log(score);
    return Math.trunc(score);
  }

  checkPhnum(phnumber) {
    if (
      phnumber.match(/[a-z]/i) != null &&
      phnumber.match(/[a-z]/i).length > 0
    ) {
      return true;
    } else if (phnumber.match(/[a-z]/i) == null) {
      return false;
    }
  }

  // Getters for SignUp form

  public get Email() {
    return this.signUpForm.get('email');
  }

  public get Password() {
    return this.signUpForm.get('password');
  }

  public get ConfirmPassword() {
    return this.signUpForm.get('confirmPassword');
  }

  public get DisplayName() {
    return this.signUpForm.get('displayName');
  }

  public get Designation() {
    return this.signUpForm.get('designation');
  }

  public get PhoneNumber() {
    return this.signUpForm.get('phoneNumber');
  }

  public get Checked() {
    return this.signUpForm.get('checked');
  }

  public get Accesscode() {
    return this.signUpForm.get('accesscode');
  }

  public get reco() {
    return this.signUpForm.get('reco');
  }

  public get treb() {
    return this.signUpForm.get('treb');
  }
}
