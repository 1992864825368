import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EditSettingsService } from './../../shared/services/edit-settings.service';
import { GenerateImageService } from './../../shared/services/generate-image.service';
import { ImageFilterService } from './../../shared/services/image-filter.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommService } from 'src/app/shared/services/comm.service';

import { SettingsService } from 'src/app/shared/services/settings.service';
import { NotyfService } from 'src/app/shared/services/notyf.service';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
declare var foosider: Function;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [NgbDropdownConfig],
})
export class MainComponent implements OnInit {
  public postTypes: CustomPostType[] = [];
  public sizeData: CustomPostType = null;

  disa_btn = false;
  loading_btn = true;

  custDesign = false;

  editing_hint = false;
  public zoom = [];
  @Output() templateSettings: any;
  @Output() settings: any;

  download = false;

  count = 0;

  public toggleSub = [false];
  public activeTabId = 'template';
  public zoomValue = '35%';
  public userDetails = null;
  public forMobileCodeFlag = false;
  public showDownload = true;

  public elementId: number;
  public data: any;
  constructor(
    private editSettingsService: EditSettingsService,
    private generateImageService: GenerateImageService,
    private imageFilterService: ImageFilterService,
    public router: Router,
    config: NgbDropdownConfig,
    public authService: AuthService,
    private notyf: NotyfService,
    public commService: CommService,
    public settingsService: SettingsService
  ) {
    config.placement = 'top-right';
    this.settings = '';
    this.settingsService.getSettings().then((result) => {
      this.settings = result.payload.val();
      this.settings.logoSettings.images = [];
      this.postTypes = this.settings.sizeSettings.sizes;
    });
    this.settingsService.getTemplateData().then((result) => {
      this.templateSettings = result.payload.val();
    });

    // Populating zoom values
    for (let i = 25; i <= 100; i += 5) {
      this.zoom.unshift(i + '%');
    }
  }

  ngOnInit() {
    this.commService.activeId.subscribe((value) => {
      this.activeTabId = value;
    });
    this.commService.onTemplate.subscribe((value) => {
      this.showDownload = value;
    });
    // Incoming Text Edit Data
    // this.commService.textEditDataTransfered.subscribe((value) => {
    //   this.elementId = value.elementId;
    //   this.data = value.data;
    //   if (value.elementId >= 0) {
    //     this.custDesign = true;
    //   } else {
    //     this.custDesign = false;
    //   }
    // });
    // Incoming Font Size Data
    this.commService.fontSizeValueData.subscribe((value) => {
      if (this.elementId == value.elementId) {
        this.data.size = value.data.size;
      }
    });
    // Incoming Font Auto Adjuster Loop Data
    this.commService.fontAutoAdjustLoopData.subscribe((value) => {
      if (this.elementId == value.elementId) {
        this.data.loopExecuting = value.data.loopExecuting;
      }
    });
    // Incoming Box Top Data
    this.commService.boxTopValueData.subscribe((value) => {
      if (this.elementId == value.elementId) {
        this.data.top = value.data.top;
      }
    });
    // Incoming Box Left Data
    this.commService.boxLeftValueData.subscribe((value) => {
      if (this.elementId == value.elementId) {
        this.data.left = value.data.left;
      }
    });
    // Incoming Box Width Data
    this.commService.boxWidthtValueData.subscribe((value) => {
      if (this.elementId == value.elementId) {
        this.data.width = value.data.width;
      }
    });

    this.forMobileCodeFlag = this.getIsMobile();
    window.onresize = () => {
      this.forMobileCodeFlag = this.getIsMobile();
    };
    foosider();

    this.authService.getUsers().then((result) => {
      this.userDetails = {
        name: result.payload.val().displayName,
        email: result.payload.val().emailId,
        phone: result.payload.val().phoneNo,
        photoUrl: result.payload.val().imageData,
      };
    });
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.count = 0;
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
  }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 768;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

  onImageSettingsChange(payload: any) {
    // if (this.count != 0)
    const imgData = this.settings.imageSettings.images.filter(
      (x: any) => x.uniqueId == this.settings.selectedImageUniqueId
    );
    this.sizeData = this.getPostData(imgData.type);

    this.editing_hint = true;
    this.loading_btn = true;

    // this.count++
    this.editSettingsService.updateCanvas();
  }

  onCanvasReposition() {
    // //////console.log("canvas reposition");
  }

  onSizeSettingsChange(payload: any) {
    this.editSettingsService.updateCanvas();
    this.editSettingsService.updateOverlays();
  }

  onFilterReset() {
    // this.filterSettings.selectedFilterIndex = 0;
    // this.imageFilterService.updateFilter(this.filterSettings.filters[this.filterSettings.selectedFilterIndex]);
  }

  onFilterSettingsChange(payload: any) {
    // this.imageFilterService.updateFilter(this.filterSettings.filters[this.filterSettings.selectedFilterIndex]);
  }

  onTextSettingsChange(payload: any) {
    this.editSettingsService.updateOverlays();
  }

  onLogoSettingsChange(payload: any) {
    this.editSettingsService.updateOverlays();
  }

  loadingChange(payload: any) {
    this.loading_btn = payload;
  }

  onDownload(hint, quality) {
    this.zoomValue = '30%';
    this.disa_btn = true;
    setTimeout(() => {
      this.generateImageService.generateImage(hint, quality);
      this.generateImageService.status.subscribe((rv_data) => {
        if (rv_data === true) {
          this.disa_btn = false;
          // this.notyf.success('File downloaded sucessfully');
        } else if (rv_data === 'error') {
          this.disa_btn = false;
          this.notyf.error('Error in file download');
        }
      });
    }, 10);
  }

  getPostData(name: string): CustomPostType {
    for (const item of this.postTypes) {
      if (item.Name == name) {
        return item;
      }
    }
  }

  onRefresh() {
    this.settings.textSettings.models = [];
    this.settings.logoSettings.images = [];
    this.editSettingsService.updateOverlays();
  }
  onImageLayerSettingsChange(payload: any) {
    this.editSettingsService.updateOverlays();
  }
  zoomImage(zoomPercentage) {
    this.zoomValue = zoomPercentage;
  }
  backButtonClicked(): void {
    this.editing_hint = false;
    this.custDesign = false;
    this.elementId = -1;
    this.data = null;
    this.commService.sendTextEditData(this.elementId, this.data);
  }

  // Font Size Functions
  fontSizeChangedEvent(event): void {
    this.commService.setFontSize(this.elementId, event.target.value);
  }
  increaseFontSize(): void {
    this.commService.increaseFontSizeCalled(this.elementId);
  }
  decreaseFontSize(): void {
    this.commService.decreaseFontSizeCalled(this.elementId);
  }
  resetFontSize(): void {
    this.commService.resetFontValueCalled(this.elementId);
  }
  startOrStopFontAdjustment(): void {
    if (this.data.loopExecuting != null) {
      this.commService.stopFontAdjustmentCalled(this.elementId);
    } else {
      this.commService.startFontAdjustmentCalled(this.elementId);
    }
  }

  // Text Value Function
  textValueChangedEvent(event): void {
    this.commService.setTextData(this.elementId, event.target.value);
  }

  // Box Width Functions
  boxWidthChangedEvent(event): void {
    this.commService.setBoxWidth(this.elementId, event.target.value);
  }
  increaseBoxWidth(): void {
    this.commService.increaseBoxWidthCalled(this.elementId);
  }
  decreaseBoxWidth(): void {
    this.commService.decreaseBoxWidthCalled(this.elementId);
  }
  resetBoxWidth(): void {
    this.commService.resetBoxWidthCalled(this.elementId);
  }

  // Box Left Functions
  boxLeftChangedEvent(event): void {
    this.commService.setBoxLeft(this.elementId, event.target.value);
  }
  increaseBoxLeft(): void {
    this.commService.increaseBoxLeftCalled(this.elementId);
  }
  decreaseBoxLeft(): void {
    this.commService.decreaseBoxLeftCalled(this.elementId);
  }
  resetBoxLeft(): void {
    this.commService.resetBoxLeftCalled(this.elementId);
  }

  // Box Top Functions
  boxTopChangedEvent(event): void {
    this.commService.setBoxTop(this.elementId, event.target.value);
  }
  increaseBoxTop(): void {
    this.commService.increaseBoxTopCalled(this.elementId);
  }
  decreaseBoxTop(): void {
    this.commService.decreaseBoxTopCalled(this.elementId);
  }
  resetBoxTop(): void {
    this.commService.resetBoxTopCalled(this.elementId);
  }
}
