import {
  Component,
  AfterViewInit,
  ElementRef,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { EditSettingsService } from '../../../shared/services/edit-settings.service';
import { GenerateImageService } from '../../../shared/services/generate-image.service';
import { ImageFilterService } from '../../../shared/services/image-filter.service';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';
@Component({
  selector: 'app-canvas-select',
  templateUrl: './canvas-select.component.html',
  styleUrls: ['./canvas-select.component.css'],
})
export class CanvasSelectComponent implements AfterViewInit {
  public postTypes: CustomPostType[] = [];
  public sizeData: CustomPostType = null;

  loading = true;
  imga;

  @ViewChild('photoCanvas') canvasArtboard: ElementRef;
  @Input() settings: any;

  download = false;
  /* position; */ width = '';
  height = '';

  @Output() loadingChange: EventEmitter<any> = new EventEmitter();

  private ctx: CanvasRenderingContext2D;

  constructor(
    private editSettingsService: EditSettingsService,
    private generateImageService: GenerateImageService,
    private imageFilterService: ImageFilterService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    console.log(this.settings);
    this.width = '';
    this.height = '';
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    if (this.settings) {
      this.postTypes = this.settings.sizeSettings.sizes;
    }
  }

  ngAfterViewInit() {
    // canvas context
    this.ctx = this.canvasArtboard.nativeElement.getContext('2d');

    // subscribe
    this.editSettingsService.storeCanvas.subscribe(() => this.onUpdateCanvas());
    this.imageFilterService.store.subscribe(() => this.onUpdateFilter());
    this.generateImageService.store.subscribe((rv_data) => {
      this.onGenerateDownloadableImage(rv_data.hint, rv_data.quality);
    });

    this.cd.detectChanges();
  }

  // load image into canvas
  onUpdateCanvas() {
    // console.log(
    //   this.settings.imageSettings.images.find(
    //     (x: any) => x.uniqueId == this.settings.selectedImageUniqueId
    //   ).thumbnail
    // );
    const imgData = this.settings.imageSettings.images.filter(
      (x: any) => x.uniqueId == this.settings.selectedImageUniqueId
    );
    this.imga = imgData[0].thumbnail;
    this.sizeData = this.getPostData(imgData[0].type);

    this.width = '' + this.sizeData.Width;
    this.height = '' + this.sizeData.Height;
    this.loading = true;

    const image = new Image();

    const modelMatch = this.settings.imageSettings.images.filter(
      (x: any) => x.uniqueId == this.settings.selectedImageUniqueId
    );

    image.src = this.editSettingsService.processImgUrl(
      modelMatch[0].url,
      this.sizeData.Width,
      this.sizeData.Height
    );
    ////debugger
    image.crossOrigin = 'Anonymous';

    // clean canvas
    // this.ctx.clearRect(0, 0, sizeData.width, sizeData.height);
    // this.ctx.beginPath();
    // this.ctx.rect(2516,2256, 1299, 1500);
    // this.ctx.stroke();
    // provide imageFilterService with a new canvas
    this.imageFilterService.updateCanvasReference(
      this.canvasArtboard.nativeElement
    );
    // update canvas
    image.onload = () => {
      this.ctx.drawImage(
        image,
        0,
        0,
        this.sizeData.Width,
        this.sizeData.Height
      );

      setTimeout(() => {
        this.loading = false;
        this.loadingChange.emit(this.loading);
      }, 200);
    };
    // image.src ="../assets/img/bdayimg.jpg";
    // image.onload = () => this.ctx.drawImage(image,213,191,200,200);
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  onGenerateDownloadableImage(hint, quality) {
    this.download = true;
    const image = new Image();
    image.src = this.canvasArtboard.nativeElement.toDataURL('image/png');
    image.crossOrigin = 'Anonymous';
    this.settings.canvasSettings.downloadableImage = image;
    const data = document.getElementById('poster');
    html2canvas(data, {
      scale: quality,
    }).then((canvas) => {
      // Few necessary setting options
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      try {
        if (hint == 'png') {
          const a = document.createElement('a');
          a.href = contentDataURL;
          a.download = 'poster.png';
          a.click();
          this.generateImageService.imageGenerationStatus(true);
          this.change(false);
        } else if (hint === 'pdf') {
          const pdf = new jspdf({
            orientation: 'l', // landscape
            unit: 'pt', // points, pixels won't work properly
            format: [imgWidth, imgHeight], // set needed dimensions for any element
          });
          const position = 0;
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          pdf.save('Poster.pdf'); // Generated PDF

          this.generateImageService.imageGenerationStatus(true);
          this.change(false);
        }
      } catch (ex) {
        this.generateImageService.imageGenerationStatus('error');
        this.change(false);
      }
    });
  }

  change(st) {
    this.download = st;
  }

  onUpdateFilter() {
    // ////console.log('update filter: likely use the imageFilterService canvas ref');
  }

  onClearOverlaysSelection() {
    this.editSettingsService.updateOverlays(true);
  }

  getPostData(name: any): CustomPostType {
    for (const item of this.postTypes) {
      if (item.Name == name) {
        return item;
      }
    }
    return this.postTypes[0];
  }
}
