<div class="container">
  <div class="row mt-2">
    <div class="col-md-6">
      <h5 class="mb-0">
        <span class="sidebar-icon material-icons-outlined">
          design_services
        </span>
        <span class="heading-text" style="font-size: 20px !important;">Templates</span>
      </h5>
      <p style="font-size: 13px !important;">Manage your all templates</p>
    </div>
    <div class="col-6" *ngIf="!isTemplate">
      <!--addAddress(content)-->
      <button type="button" style="float: right;" class="btn btn-primary mb-2" (click)="addTemplate()">Add New
        Template</button>
    </div>
  </div>
  <ng-container *ngIf="images && !isTemplate">
    <div class="card table-responsive-lg">
      <table class="table table-hover">
        <thead class="thead-light">
          <tr>
            <th scope="col">#</th>
            <!-- <th scope="col">Id</th> -->
            <th scope="col">Thumbnail</th>
            <th scope="col">Name</th>
            <th scope="col">Tag</th>
            <th scope="col">Status</th>
            <th scope="col">Type</th>
            <!-- <th scope="col">Selected Index</th> -->
            <th scope="col">Actions</th>
            <!-- <th scope="col">Edit</th>
            <th scope="col">Delete</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of images; let i =index">
            <th scope="row">{{i+1}}</th>
            <!-- <td scope="col">{{item.uniqueId}}</td> -->
            <td scope="col">
              <img class="small-image" [src]="item.thumbnail">
              <!-- <img class="small-image" src="../../../assets/img/animal.webp"> -->
            </td>
            <td scope="col">{{item.name}}</td>
            <td scope="col">{{item.tags}}</td>

            <td *ngIf="!item.isDeleted" scope="col">{{item.status}}</td>
            <td *ngIf="item.isDeleted" scope="col" class="text-danger">DELETED</td>
            <td scope="col">{{item.type}}</td>
            <!-- <td scope="col">{{item.selectedSizeIndex}}</td> -->
            <td scope="col">
              <button class="edit_btn_style mr-2"
                [routerLink]="['/admin/dashboard/template-model', item.uniqueId]">Details</button>
              <button class="btn btn-primary btn-xs edit_btn_style mr-2" (click)="editSettings(item,i)">Edit</button>
              <button class="btn btn-danger btn-xs edit_btn_style mr-2" (click)="deleteSettings(i)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="isTemplate">
    <form #addForm="ngForm" class="mt-3 mb-3" (ngSubmit)="saveTemplate()">
      <div class="">
        <div class="row">
          <div class="mb-3">
            <p class="mb-0">
              <span class="sidebar-icon material-icons-outlined" (click)="clearImageDetailsField()">
                arrow_back
              </span>
              Add New Template Details
            </p>
            <small>Make your new template from here</small>
          </div>
          <div class="col-md-6 ">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Template Name</label>
                  <input type="text" class="form-control input_custom_design" required [(ngModel)]="name" name="name"
                    #names="ngModel" (input)="checkName()" placeholder="name" />
                  <div *ngIf="names.invalid && (names.dirty || names.touched)" class="danger_style">
                    <div *ngIf="names.errors.required">
                      Name is required.
                    </div>
                  </div>
                  <div *ngIf="nameAlreadyExists" class="alert alert-danger">
                    This name already exists
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Template Thumbanil</label>
                  <input type="text" class="form-control input_custom_design" required [(ngModel)]="thumbnail"
                    name="thumbnail" #thumbnails="ngModel" placeholder="Image thumbnail" />
                  <div *ngIf="thumbnails.invalid && (thumbnails.dirty || thumbnails.touched)" class="danger_style">
                    <div *ngIf="thumbnails.errors.required">
                      Thumbnail is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Upload
                    Template Thumbnail</label>
                  <input type="file" (change)='onThumbnailUpload($event)' [disabled]="name==null || name==''" />
                  <div *ngIf="name==null || name==''" class="danger_style">Set the name field, first</div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Template Background Url</label>
                  <input type="text" class="form-control input_custom_design" required [(ngModel)]="url" name="url"
                    #urls="ngModel" placeholder="Image Url" />
                  <div *ngIf="urls.invalid && (urls.dirty || urls.touched)" class="danger_style">
                    <div *ngIf="urls.errors.required">
                      Url is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Upload Template Background Image</label>
                  <input type="file" (change)='onImageUpload($event)' [disabled]="name==null || name==''" />
                  <div *ngIf="name==null || name==''" class="danger_style">Set the name field, first</div>
                </div>
              </div>


              <div class="col-md-6">
                <div class="form-group mb-0">
                  <label>Tag</label>
                  <select class="form-control  input_custom_design" style="height: 45px;" required [(ngModel)]="tags"
                    name="tags" #tag="ngModel">
                    <option value="">Select Tag</option>
                    <option *ngFor="let t of tagsData;let i = index" [value]="i">{{t.Name}}</option>
                  </select>
                  <div *ngIf="tag.invalid && (tag.dirty || tag.touched)" class="danger_style">
                    <div *ngIf="tag.errors.required">
                      Tags is required.
                    </div>
                  </div>
                </div>
                <div><button style="padding: 0px 10px;
                border: none;float: right;
                font-size: 12px;" class="" type="button" (click)="openAddTagModal()">Add new
                    tag</button>
                </div>
              </div>
              <!-- <div class="col">
            <div class="form-group">
              <label>Selected Size Index</label>
              <select class="form-control" required [(ngModel)]="selectedSize" name="selectedSize"
                #selectedSizeInput="ngModel">
                <option value="">Select Size</option>
                <option value="0">Normal Post</option>
                <option value="3">Instagram Stories</option>
              </select>
              <div *ngIf="selectedSizeInput.invalid && (selectedSizeInput.dirty || selectedSizeInput.touched)"
                class="alert alert-danger">
                <div *ngIf="selectedSizeInput.errors.required">
                  Size is required.
                </div>
              </div>
            </div>
          </div> -->
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <label>Type</label>
                  <select class="form-control input_custom_design" style="height: 45px;" required [(ngModel)]="postType"
                    name="postType" #postTypes="ngModel">
                    <option value="">Select Type</option>
                    <option *ngFor="let pt of postTypesData;let i = index" [value]="i">{{pt.Name}}</option>
                  </select>
                  <div *ngIf="postTypes.invalid && (postTypes.dirty || postTypes.touched)" class="danger_style">
                    <div *ngIf="postTypes.errors.required">
                      Type is required.
                    </div>
                  </div>
                </div>
                <div><button style="padding: 0px 10px;
                border: none;float: right;
                font-size: 12px;" class="" type="button" (click)="openAddPostTypeModal()">Add
                    new template type</button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Status</label>
                  <select class="form-control input_custom_design" style="height: 45px;" required
                    [(ngModel)]="postStatus" name="postStatus" #postStatuses="ngModel">
                    <option value="">Select Option</option>
                    <option value="live">Live</option>
                    <option value="coming soon">Coming Soon</option>
                    <option value="hidden">Hidden</option>
                  </select>
                  <div *ngIf="postStatuses.invalid && (postStatuses.dirty || postStatuses.touched)"
                    class="danger_style">
                    <div *ngIf="postStatuses.errors.required">
                      Status is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Author</label>
                  <input type="text" class="form-control input_custom_design" required [(ngModel)]="author"
                    name="author" #authors="ngModel" placeholder="author" />
                  <div *ngIf="authors.invalid && (authors.dirty || authors.touched)" class="danger_style">
                    <div *ngIf="authors.errors.required">
                      Authors is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button class="btn btn-danger  mr-2" (click)="clearImageDetailsField()">Cancel</button>

                <button type="submit" class="btn btn-success mr-2"
                  [disabled]="addForm.invalid || nameAlreadyExists">Submit</button>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="col-12 mb-4">
              <p class="mb-0">Background image</p><img class="big-image" [src]="url">
              <small style="color: rgb(104, 104, 104);">base of your template, all other things placed over the
                image</small>
            </div>
            <div class="col-12">
              <p class="mb-0">Thumbnail</p><img class="big-image" [src]="thumbnail">
              <small style="color: rgb(104, 104, 104);">Preview that users can see this image</small>
            </div>
          </div>
        </div>

      </div>

    </form>

  </ng-container>
</div>

<ng-template #addPostTypeModal let-modal>
  <app-post-types [MODE]="'CREATE'" [postTypesData]="postTypesData" (modeChanged)="postTypeConfigModeChanged($event)"
    (outputData)="postTypeOutputData($event)">
  </app-post-types>
</ng-template>

<ng-template #addTagModal let-modal>
  <app-tags [MODE]="'CREATE'" [tagsData]="tagsData" (modeChanged)="tagConfigModeChanged($event)"
    (outputData)="tagOutputData($event)"></app-tags>
</ng-template>