import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomPostType } from 'src/app/shared/models/custom-post-type.model';

@Component({
  selector: 'app-post-types',
  templateUrl: './post-types.component.html',
  styleUrls: ['./post-types.component.scss'],
})
export class PostTypesComponent implements OnInit, OnChanges {
  @Input() MODE: 'CREATE' | 'EDIT' | 'VIEW';
  @Input() editData: {
    docId: number;
    data: CustomPostType;
  };
  @Input() postTypesData: CustomPostType[] = [];

  @Output() modeChanged = new EventEmitter<'CREATE' | 'EDIT' | 'VIEW'>();
  @Output() outputData = new EventEmitter<{
    docId: number;
    data: CustomPostType;
  }>();
  public filter = '';
  public submitButtonText = 'Create';

  public postTypesForm: FormGroup;

  constructor() {
    this.postTypesForm = new FormGroup({
      postType: new FormControl('', Validators.required),
      postTypeHeight: new FormControl(0, Validators.required),
      postTypeWidth: new FormControl(0, Validators.required),
      status: new FormControl('active', Validators.required),
      description: new FormControl('', Validators.required),
    });

    this.PostType.valueChanges.subscribe((value) => {
      if (value != '') {
        if (this.nameAlreadyExists(value)) {
          this.PostType.setErrors({ notUnique: true });
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializationActions();
  }

  ngOnInit(): void {
    this.initializationActions();
  }

  initializationActions(): void {
    if (this.MODE == 'CREATE') {
      this.PostType.setValue('');
      this.PostTypeHeight.setValue(0);
      this.PostTypeWidth.setValue(0);
      this.submitButtonText = 'Create';
    } else if (this.MODE == 'EDIT') {
      this.PostType.setValue(this.editData.data.Name);
      this.PostTypeHeight.setValue(this.editData.data.Height);
      this.PostTypeWidth.setValue(this.editData.data.Width);
      this.Status.setValue(this.editData.data.Status);
      this.Description.setValue(this.editData.data.Description);
      this.submitButtonText = 'Edit';
    }
  }

  nameAlreadyExists(name: string): boolean {
    if (
      this.MODE == 'EDIT' &&
      this.editData.data.Name.toLowerCase() == name.toLowerCase()
    ) {
      return false;
    }
    for (const item of this.postTypesData) {
      if (item.Name.toLowerCase() == name.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  editPostType(value: CustomPostType, index: number) {
    this.outputData.emit({
      docId: index,
      data: value,
    });
  }

  submitData() {
    this.submitButtonText = 'Loading';
    let x = this.postTypesData.length;
    if (this.MODE == 'EDIT') {
      x = this.editData.docId;
    }
    this.outputData.emit({
      docId: x,
      data: {
        Name: this.PostType.value,
        Height: this.PostTypeHeight.value,
        Width: this.PostTypeWidth.value,
        Status: this.Status.value,
        Description: this.Description.value,
      },
    });
  }

  viewPostTypes() {
    this.modeChanged.emit('VIEW');
  }

  newPostType() {
    this.modeChanged.emit('CREATE');
  }

  // formDetails getters
  get PostType() {
    return this.postTypesForm.get('postType');
  }

  get PostTypeHeight() {
    return this.postTypesForm.get('postTypeHeight');
  }

  get PostTypeWidth() {
    return this.postTypesForm.get('postTypeWidth');
  }

  get Status() {
    return this.postTypesForm.get('status');
  }

  get Description() {
    return this.postTypesForm.get('description');
  }
}
