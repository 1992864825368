import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeModule } from './home/home.module';
import { AdminModule } from './admin/admin.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AppResizeDirective } from './shared/directives/app-resize.directive';
import { TemplateFormComponent } from './template-form/template-form.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SignUpComponent } from './sign-up/sign-up.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NOTYF, notyfFactory } from './shared/utils/notyf.token';
import {
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy,
} from '@angular/common';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AngularDraggableModule } from 'angular2-draggable';
import { CanvasComponent } from './home/canvas/canvas.component';

import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { FontManagerService } from './modules/admin/services/font-manager.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppResizeDirective,
    TemplateFormComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    CanvasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HomeModule,
    AdminModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFireModule.initializeApp(environment.testDb),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    ImageCropperModule,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    NgxSliderModule,
    NgxMaskModule.forRoot(),
    AngularDraggableModule,
    IvyCarouselModule,

    MatDialogModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NOTYF, useFactory: notyfFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private fontService: FontManagerService) {
    this.fontService.getFonts().subscribe((response: any) => {
      for (const item of response) {
        this.addFont(item.fontUrl);
      }
    });
  }

  addFont(fontUrl: string, callback?) {
    if (fontUrl) {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';
      try {
        document.body.appendChild(link).onload = () => {
          if (callback) {
            callback();
          }
        };
      } catch (error) {
        ////console.log(error);
      }
    }
  }
}
