import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotyfService } from 'src/app/shared/services/notyf.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
})
export class AdminLoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [
      Validators.pattern(/([A-Za-z0-9\!\@\#\$\%\^\&\*\?\.\_\+\=\-])\w+/),
      Validators.required,
    ]),
  });

  hint = false;

  constructor(
    private authService: AuthService,
    private notyf: NotyfService,
    private router: Router
  ) {}

  ngOnInit() {}

  redirectToHome() {
    if (this.Email.value.length > 0 && this.Password.value.length > 0) {
      this.hint = true;
      //debugger
      this.authService
        .SignIn(this.Email.value, this.Password.value)
        .then((result) => {
          ////console.log(result)
          this.authService
            .checkAdminData(result.user)
            .then((value) => {
              this.hint = false;
              this.router.navigateByUrl('/admin/dashboard');
              this.notyf.info('Logged In');
            })
            .catch((error) => {
              this.hint = false;
              this.notyf.error(error);
            });
        })
        .catch((error) => {
          this.hint = false;
          this.notyf.error(error);
        });
    } else {
      this.notyf.error('Please enter valid email address and password');
    }
  }

  public get Email() {
    return this.loginForm.get('email');
  }

  public get Password() {
    return this.loginForm.get('password');
  }
}
