import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDeleted'
})
export class IsDeletedPipe implements PipeTransform {

  transform(data: Array<any>)  {
    return data.filter(img =>!img.isDeleted);
  }

}
