import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDB } from 'src/app/shared/models/calendar.db.model';
import { Calendar } from 'src/app/shared/models/calendar.model';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { NotyfService } from 'src/app/shared/services/notyf.service';
import { SwalService } from 'src/app/shared/services/swal.service';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @ViewChild('addEventModal') addEventModal: TemplateRef<any>;
  @ViewChild('editEventModal') editEventModal: TemplateRef<any>;
  @ViewChild('fullcalendar') fullCalendar: FullCalendarComponent;

  private calendarEvents: Calendar[] = [];
  private calendarEventsDB: {
    docId: string;
    eventData: CalendarDB;
  }[] = [];
  public calendarOptions: CalendarOptions = {
    selectable: true,
    editable: true,
    initialView: 'dayGridMonth',
    themeSystem: 'bootstrap',
    events: this.calendarEvents,
    dateClick: this.dateClicked.bind(this),
    eventClick: this.eventClicked.bind(this),
  };

  private editEventData;

  public eventTitleEdit = '';
  public dateStartEdit = '';
  public dateEndEdit = '';
  public dateStartAdd = '';
  public dateEndAdd = '';
  public eventColor = 'bg-danger';

  constructor(
    private modal: NgbModal,
    private datePipe: DatePipe,
    private calendarService: CalendarService,
    private notyf: NotyfService,
    private swalService: SwalService
  ) {}

  ngOnInit(): void {
    this.calendarService.getEvents().subscribe((value) => {
      this.calendarEventsDB = value;
      this.calendarEvents = [];
      value.forEach((events) => {
        this.calendarEvents.push(this.convertDBeventToEvent(events.eventData));
      });
      this.calendarOptions.events = this.calendarEvents;
      this.fullCalendar.getApi().render();
    });
  }

  convertDBeventToEvent(value: CalendarDB): Calendar {
    return {
      id: value.id,
      className: value.className,
      title: value.title,
      start: value.start,
      end: value.end,
    };
  }

  dateClicked(value): void {
    this.dateStartAdd = value.dateStr;
    this.dateEndAdd = value.dateStr;
    this.modal.open(this.addEventModal).result.then(
      (result) => {
        this.addEvent(result);
      },
      (reason) => {
        //////console.log(reason);
      }
    );
  }

  eventClicked(value): void {
    this.editEventData = this.findEvent(value.event.id);
    const end = new Date(this.editEventData.end);
    end.setDate(end.getDate() - 1);
    this.eventTitleEdit = this.editEventData.title;
    this.dateStartEdit = this.editEventData.start;
    this.dateEndEdit = this.datePipe.transform(end, 'YYYY-MM-dd');
    this.eventColor = this.editEventData.className;
    this.modal.open(this.editEventModal).result.then(
      (result) => {
        if (result == 'DELETE') {
          this.deleteEvent();
        } else {
          this.editEvent(result);
        }
      },
      (reason) => {
        //////console.log(reason);
      }
    );
  }

  findEvent(id): Calendar {
    for (const event of this.calendarEvents) {
      if (event.id == id) {
        return event;
      }
    }
    return null;
  }

  findEventDB(id): {
    docId: string;
    eventData: CalendarDB;
  } {
    for (const event of this.calendarEventsDB) {
      if (event.eventData.id == id) {
        return event;
      }
    }
    return null;
  }

  addEvent(value): void {
    const start = new Date(value.dateStart);
    const end = new Date(value.dateEnd);
    end.setDate(end.getDate() + 1);
    const x = this.nextId();
    this.calendarService
      .updateEvent(null, {
        id: x,
        className: this.eventColor,
        start: this.datePipe.transform(start, 'YYYY-MM-dd'),
        end: this.datePipe.transform(end, 'YYYY-MM-dd'),
        title: value.eventTitle,
        userId: '',
      })
      .then(() => {
        this.notyf.success('Event created');
      })
      .catch((error) => {
        this.notyf.error(error);
      });
  }

  nextId(): string {
    let x = 1;
    for (const event of this.calendarEvents) {
      if (Number(event.id) >= x) {
        x = Number(event.id);
      }
    }
    x++;

    return '' + x;
  }

  editEvent(value): void {
    const start = new Date(value.dateStartEdit);
    const end = new Date(value.dateEndEdit);
    end.setDate(end.getDate() + 1);

    const x = this.findEventDB(this.editEventData.id);
    this.calendarService
      .updateEvent(x.docId, {
        id: this.editEventData.id,
        className: this.eventColor,
        title: value.eventTitleEdit,
        start: this.datePipe.transform(start, 'YYYY-MM-dd'),
        end: this.datePipe.transform(end, 'YYYY-MM-dd'),
        userId: '',
      })
      .then(() => {
        this.notyf.success('Event updated');
      })
      .catch((error) => {
        this.notyf.error(error);
      });
  }

  deleteEvent(): void {
    this.swalService.fooeventdeleteswal((val: boolean) => {
      const x = this.findEventDB(this.editEventData.id);
      this.calendarService.removeEvent(x.docId);
    });
  }
}
