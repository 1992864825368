<div class="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 ">
  <div class="col-auto d-flex justify-content-between ps-0 mb-4 mb-lg-0">
    <div class="me-lg-3">
      <a href="#" class="btn btn-green" data-bs-toggle="modal" data-bs-target="#KanbanAddCard"
        (click)="openNewTaskModel()"><span class="fas fa-plus me-2"></span><span>New
          Tasks</span></a>
    </div>
    <div class="btn-group">
      <button title="Toggle for Done tasks" class="btn btn-dark text-white" [class.active]="statusFilter == 'Done'"
        (click)="changeStatusFilter('Done')">
        <span class="fas fa-check-square"></span></button>
      <button title="Toggle for In Progress tasks" class="btn btn-dark text-white"
        [class.active]="statusFilter == 'In Progress'" (click)="changeStatusFilter('In Progress')">
        <span class="fas fa-spinner"></span></button>
      <button title="Toggle for Waiting tasks" class="btn btn-dark text-white"
        [class.active]="statusFilter == 'Waiting'" (click)="changeStatusFilter('Waiting')">
        <span class="fas fa-bookmark"></span></button>
    </div>
  </div>
  <div class="col-12 col-lg-6 px-0">
    <div class="mb-0">
      <div class="input-group">
        <input type="text" class="input_custom_design form-control" id="exampleInputIconLeft"
          placeholder="Search Tasks Here" [(ngModel)]="taskFilter" aria-label="Search" aria-describedby="basic-addon3">
      </div>
    </div>
  </div>
</div>
<div class="task-wrapper border bg-white " style="border-radius: 0.5rem!important">
  <div class=" hover-state border-bottom rounded-0 py-3"
    *ngFor="let t of (tasks | taskIndexing : ['title', 'description'] : taskFilter : statusFilter : startIndex : numberOfEntries);index as i"
    [class.rounded-top]="i==1">
    <div class="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0">
      <div class="col1 text-left text-sm-center mb-2 mb-sm-0">
        <div class="form-check check-lg inbox-check me-sm-2">
          <input class="form-check-input" type="checkbox" value="" id="mailCheck11">
          <label class="form-check-label" for="mailCheck11"></label>
        </div>
      </div>
      <div class="col-11 col-lg-8 px-0 mb-4 mb-md-0">
        <div class="d-block d-sm-flex">
          <h3 class="h5">{{t.taskData.title}}</h3>
          <div class="ms-sm-3"><span class="badge super-badge badge-lg" [class.bg-success]="t.taskData.status=='Done'"
              [class.bg-warning]="t.taskData.status=='In Progress'"
              [class.bg-purple]="t.taskData.status=='Waiting'">{{t.taskData.status}}</span>
          </div>
        </div>
        <div>
          <a href="javascript:void()" class="fw-bold text-dark">
            <span class="fw-normal text-gray">{{t.taskData.description}}</span>
          </a>
        </div>
        <div>
          <div style="font-size: 11px !important;color: rgb(87, 87, 87);">
            <span class="fas fa-clock me-2"></span>
            {{t.taskData.endDateTime | date:'EEE, MMMM d, y, h:mm:ss a'}}
          </div>
        </div>

      </div>
      <div
        class="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0">
        <div class="btn-group ms-md-3">
          <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu dropdown-menu-end py-0">
            <a class="dropdown-item rounded-top" href="#" data-bs-toggle="modal" data-bs-target="#KanbanAddCard"
              (click)="openEditTaskModel(t.taskData,t.docId)"><span class="fas fa-edit"></span>Edit</a>
            <a class="dropdown-item text-danger rounded-bottom" href="javascript:void()"
              (click)="deleteTask(t.docId)"><span class="fas fa-trash-alt"></span>Delete</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row p-4">
    <div class="col-7 mt-1">
      Showing {{startIndex + 1}} - {{displayEndIndexValue()}} of {{filteredArrayLength}}
    </div> <!-- end col-->
    <div class="col-5">
      <div class="btn-group float-end">
        <a href="javascript:void()" class="btn btn-gray-200" [class.disabled]="startIndex == 0"
          (click)="prevButtonClicked()">
          <span class="fas fa-chevron-left"></span>
        </a>
        <a href="javascript:void()" class="btn btn-secondary" [class.disabled]="nextButtonDisable()"
          (click)="nextButtonClicked()">
          <span class="fas fa-chevron-right"></span>
        </a>
      </div>
    </div> <!-- end col-->
  </div>
</div>






<div class="modal fade" id="KanbanAddCard" tabindex="-1" aria-labelledby="KanbanAddCardLabel4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{taskModalButton}}
        </h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <div class="modal-body pb-0">
        <div class="mb-3">
          <input type="text" class="input_custom_design form-control" id="title"
            placeholder="Enter a title for this task" [(ngModel)]="title">
        </div>
        <div class="mb-3">
          <textarea class="input_custom_design form-control" id="description"
            placeholder="Enter a description for this task…" rows="3" [(ngModel)]="description"></textarea>
        </div>
        <div class="row align-items-center">
          <div class="col-md-4 mb-3">
            <input type="date" class="input_custom_design form-control" id="endDate"
              placeholder="Select a task end date" [(ngModel)]="endDate">
          </div>
          <div class="col-md-4 mb-3">
            <input type="time" class="input_custom_design form-control" id="endTime"
              placeholder="Select a task end time" [(ngModel)]="endTime">
          </div>
          <div class="col-md-4 mb-3">
            <select class="form-select input_custom_design mb-0" id="status" [(ngModel)]="status">
              <option selected>Select</option>
              <option value="Done">Done</option>
              <option value="In Progress">In Progress</option>
              <option value="Waiting">Waiting</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 pt-0 justify-content-end">
        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-success" (click)="addOrEditTask()"
          data-bs-dismiss="modal">{{taskModalButton}}</button>
      </div>
    </div>
  </div>
</div>