<!-- <section class="d-flex align-items-center sec" style="overflow: hidden;">
  <div class="container">

    <div class="row justify-content-center">
      <h1 class="text-center">ADMIN SECTION</h1>

      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
          <div class="text-center text-md-center mb-4 mt-md-0">
            <h1 class="mb-0 h3">Sign in</h1>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="redirectToHome()" class="mt-4">
            <div class="form-group mb-3">
              <label for="user_id">Email Address <span class="text-danger">*</span></label>
              <div class="input-group">
                <input type="email" class="form-control" placeholder="example@company.com" formControlName="email"
                  [class.is-invalid]="Email.touched && Email.invalid" [class.is-valid]="Email.valid" autofocus required>
                <div *ngIf="Email.touched && Email.invalid" class="invalid-feedback">Enter a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-group mb-3">
                <label for="password">Password <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input type="password" placeholder="Password" class="form-control" formControlName="password"
                    [class.is-invalid]="Password.touched && Password.invalid" [class.is-valid]="Password.valid"
                    required>
                  <div *ngIf="Password.touched && Password.invalid" class="invalid-feedback">Enter a valid password
                  </div>
                </div>
              </div>
            </div>
            <div class="d-grid">
              <button type="submit" class="btn btn-dark" [hidden]="hint" [disabled]="loginForm.invalid">Sign in</button>
              <button class="btn btn-primary" type="button" disabled [hidden]="!hint">
                Loading...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

   
  </div>
</section> -->


<!-- Section -->
<section class="d-flex align-items-center sec" style="overflow: hidden;">
  <div class="container">

    <div class="row justify-content-center form-bg-image" style="background: url('assets/img/illustrations/signin.svg') ;
    background-size: 1300px 500px !important; background-attachment: fixed;
    background-position: 109px !important; padding-top: 0px;">

      <p class="text-center">
        <img src="../../assets/img/brand/img.PNG" alt="Volt Logo" style="width: 20%;">
      </p>


      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
          <div class="text-center text-md-center mb-4 mt-md-0">
            <h3 class="mb-0 h3">Admin Session</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="redirectToHome()" class="mt-4">
            <!-- Form -->
            <div class="form-group mb-3">
              <label for="user_id">Email Address <span class="text-danger">*</span></label>
              <div class="input-group">
                <input placeholder="example@company.com" formControlName="email" autofocus required type="email"
                  class="input_custom_design form-control" [class.is-invalid]="Email.touched && Email.invalid"
                  [class.is-valid]="Email.valid">
                <div *ngIf="Email.touched && Email.invalid" class="invalid-feedback">Enter a valid email address</div>
              </div>
            </div>
            <!-- End of Form -->
            <div class="form-group">
              <!-- Form -->
              <div class="form-group mb-3">
                <label for="password">Password <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input placeholder="Password" formControlName="password" required autocomplete="current-password"
                    type="password" class="input_custom_design form-control"
                    [class.is-invalid]="Password.touched && Password.invalid" [class.is-valid]="Password.valid">
                  <div *ngIf="Password.touched && Password.invalid" class="invalid-feedback">Enter a valid password
                  </div>
                </div>
              </div>
            </div>
            <div class="d-grid">
              <button type="submit" class="btn btn-dark" [hidden]="hint" [disabled]="loginForm.invalid">Sign in</button>
              <button class="btn btn-primary" type="button" disabled [hidden]="!hint">
                Loading...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>