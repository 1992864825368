import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommService {
  private onTemplateSource = new Subject<boolean>();
  private activeIdSource = new Subject<string>();
  private taskFilteredArrayLengthSource = new Subject<number>();
  private contactFilteredArrayLengthSource = new Subject<number>();
// Text Editor Data Transfer sources
  private textEditDataTransferredSource = new Subject<{ elementId: number, data: any }>();
  // |--> Text Font funtion sources
  private fontSizeValueDataSource = new Subject<{ elementId: number, data: { size: any } }>();
  private fontIncreaseValueDataSource = new Subject<{ elementId: number }>();
  private fontDecreaseValueDataSource = new Subject<{ elementId: number }>();
  private fontValueResetDataSource = new Subject<{ elementId: number }>();
  // |--> Text Font Adjust Loop funtion sources
  private fontAutoAdjustLoopDataSource = new Subject<{ elementId: number, data: { loopExecuting: any } }>();
  private fontStartAutoAdjustDataSource = new Subject<{ elementId: number }>();
  private fontStopAutoAdjustDataSource = new Subject<{ elementId: number }>();
  // |--> Text value funtion sources
  private textDataSource = new Subject<{ elementId: number, data: { text: any } }>();
  // |--> Box Top funtion sources
  private boxTopValueDataSource = new Subject<{ elementId: number, data: { top: any } }>();
  private boxTopIncreaseValueDataSource = new Subject<{ elementId: number }>();
  private boxTopDecreaseValueDataSource = new Subject<{ elementId: number }>();
  private boxTopValueResetDataSource = new Subject<{ elementId: number }>();
  // |--> Box Left funtion sources
  private boxLeftValueDataSource = new Subject<{ elementId: number, data: { left: any } }>();
  private boxLeftIncreaseValueDataSource = new Subject<{ elementId: number }>();
  private boxLeftDecreaseValueDataSource = new Subject<{ elementId: number }>();
  private boxLeftValueResetDataSource = new Subject<{ elementId: number }>();
  // |--> Box Width funtion sources
  private boxWidthValueDataSource = new Subject<{ elementId: number, data: { width: any } }>();
  private boxWidthIncreaseValueDataSource = new Subject<{ elementId: number }>();
  private boxWidthDecreaseValueDataSource = new Subject<{ elementId: number }>();
  private boxWidthValueResetDataSource = new Subject<{ elementId: number }>();

  public onTemplate = this.onTemplateSource.asObservable();
  public activeId = this.activeIdSource.asObservable();
  public taskFilteredLength = this.taskFilteredArrayLengthSource.asObservable();
  public contactFilteredLength = this.contactFilteredArrayLengthSource.asObservable();
// Text Editor Data Transfer receiver
  public textEditDataTransfered = this.textEditDataTransferredSource.asObservable();
  // |--> Text Font funtion receiver
  public fontSizeValueData = this.fontSizeValueDataSource.asObservable();
  public fontIncreaseValueData = this.fontIncreaseValueDataSource.asObservable();
  public fontDecreaseValueData = this.fontDecreaseValueDataSource.asObservable();
  public fontValueResetData = this.fontValueResetDataSource.asObservable();
  // |--> Text Font Adjust Loop funtion receiver
  public fontAutoAdjustLoopData = this.fontAutoAdjustLoopDataSource.asObservable();
  public fontStartAutoAdjustData = this.fontStartAutoAdjustDataSource.asObservable();
  public fontStopAutoAdjustData = this.fontStopAutoAdjustDataSource.asObservable();
  // |--> Text value funtion receiver
  public textData = this.textDataSource.asObservable();
  // |--> Box Top funtion receiver
  public boxTopValueData = this.boxTopValueDataSource.asObservable();
  public boxTopIncreaseValueData = this.boxTopIncreaseValueDataSource.asObservable();
  public boxTopDecreaseValueData = this.boxTopDecreaseValueDataSource.asObservable();
  public boxTopValueResetData = this.boxTopValueResetDataSource.asObservable();
  // |--> Box Left funtion receiver
  public boxLeftValueData = this.boxLeftValueDataSource.asObservable();
  public boxLeftIncreaseValueData = this.boxLeftIncreaseValueDataSource.asObservable();
  public boxLeftDecreaseValueData = this.boxLeftDecreaseValueDataSource.asObservable();
  public boxLeftValueResetData = this.boxLeftValueResetDataSource.asObservable();
  // |--> Box Width funtion receiver
  public boxWidthtValueData = this.boxWidthValueDataSource.asObservable();
  public boxWidthIncreaseValueData = this.boxWidthIncreaseValueDataSource.asObservable();
  public boxWidthDecreaseValueData = this.boxWidthDecreaseValueDataSource.asObservable();
  public boxWidthValueResetData = this.boxWidthValueResetDataSource.asObservable();

  constructor() { }

  sourceChanged(value: boolean): void {
    this.onTemplateSource.next(value);
  }

  activeIdChanged(value: string): void {
    this.activeIdSource.next(value);
  }

  setTaskFilteredLength(value: number): void {
    this.taskFilteredArrayLengthSource.next(value);
  }

  setContactFilteredLength(value: number): void {
    this.contactFilteredArrayLengthSource.next(value);
  }

// Text Editor Data Transfer functions
  sendTextEditData(elementId: number, data: any): void{
    this.textEditDataTransferredSource.next({elementId, data});
  }
  // |--> Text Font funtions
  setFontSize(elementId: number, size: any): void {
    this.fontSizeValueDataSource.next({elementId, data: {size}});
  }

  increaseFontSizeCalled(elementId: number): void {
    this.fontIncreaseValueDataSource.next({elementId});
  }

  decreaseFontSizeCalled(elementId: number): void {
    this.fontDecreaseValueDataSource.next({ elementId });
  }

  resetFontValueCalled(elementId: number): void {
    this.fontValueResetDataSource.next({ elementId });
  }
  // |--> Text Font Adjust Loop funtions
  setFontAjdustLoopValue(elementId: number, loopExecuting: any): void{
    this.fontAutoAdjustLoopDataSource.next({ elementId, data: {loopExecuting}});
  }

  startFontAdjustmentCalled(elementId: number): void {
    this.fontStartAutoAdjustDataSource.next({ elementId });
  }

  stopFontAdjustmentCalled(elementId: number): void {
    this.fontStopAutoAdjustDataSource.next({ elementId });
  }
  // |--> Text value funtion
  setTextData(elementId: number, text: any): void {
    this.textDataSource.next({ elementId, data: {text}});
  }
  // |--> Box Top funtions
  setBoxTop(elementId: number, top: any): void {
    this.boxTopValueDataSource.next({ elementId, data: { top } });
  }

  increaseBoxTopCalled(elementId: number): void {
    this.boxTopIncreaseValueDataSource.next({ elementId });
  }

  decreaseBoxTopCalled(elementId: number): void {
    this.boxTopDecreaseValueDataSource.next({ elementId });
  }

  resetBoxTopCalled(elementId: number): void {
    this.boxTopValueResetDataSource.next({ elementId });
  }
  // |--> Box Top funtions
  setBoxLeft(elementId: number, left: any): void {
    this.boxLeftValueDataSource.next({ elementId, data: { left } });
  }

  increaseBoxLeftCalled(elementId: number): void {
    this.boxLeftIncreaseValueDataSource.next({ elementId });
  }

  decreaseBoxLeftCalled(elementId: number): void {
    this.boxLeftDecreaseValueDataSource.next({ elementId });
  }

  resetBoxLeftCalled(elementId: number): void {
    this.boxLeftValueResetDataSource.next({ elementId });
  }
  // |--> Box Top funtions
  setBoxWidth(elementId: number, width: any): void {
    this.boxWidthValueDataSource.next({ elementId, data: { width } });
  }

  increaseBoxWidthCalled(elementId: number): void {
    this.boxWidthIncreaseValueDataSource.next({ elementId });
  }

  decreaseBoxWidthCalled(elementId: number): void {
    this.boxWidthDecreaseValueDataSource.next({ elementId });
  }

  resetBoxWidthCalled(elementId: number): void {
    this.boxWidthValueResetDataSource.next({ elementId });
  }
}
