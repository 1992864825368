<div #canvas [style.width.px]="1500" [style.height.px]="1500" style="zoom: 50%;margin: 50px;"
    [ngStyle]="{'background': 'url(' + bg + ') no-repeat 0 0 / cover'}">
    <div *ngFor="let item of details.Image">
        <img class="absolute" [src]="item.selectedFile" [width]="item.width" [height]="item.height"
            [style.top.px]="item.top" [style.left.px]="item.left">
    </div>
    <div *ngFor="let item of details.Text">
        <p class="absolute" contenteditable="true" draggable="true" [style.top.px]="item.top"
            [style.left.px]="item.left" [style.font-family]="item.fontFamily" [style.max-width.px]="item.width"
            [style.font-size.px]="item.size ? item.size : ''" [style.color]="item.tColor" [ngClass]="{
            'isItalic': item?.isItalic, 
            'text-left': item?.alignIndex === 0, 
            'text-center': item?.alignIndex === 1,
            'text-right': item?.alignIndex === 2, 
            'size-normal': item?.sizeIndex === 0
            }" [style.text-transform]="item.textT">
            {{item.text}}
        </p>
    </div>
</div>