<nav class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0" style="background-color: rgb(255, 255, 255);
  border-radius: 0px !important;
  border-bottom: 1px solid #f3f3f3 !important;
  /* box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 0%);
  -webkit-box-shadow: 3px 1px 3px -1px rgb(0 0 0 / 40%);
  -moz-box-shadow: 3px 1px 10px -2px rgba(0,0,0,0.61); */
  padding-bottom: 5px !important;
  padding-left: 10px !important;">
  <div class="container-fluid px-0">
    <div class="d-flex justify-content-between w-100" id="navbarSupportedContent">
      <div class="d-flex align-items-center">
        <button id="sidebar-toggle"
          class="sidebar-toggle me-3 btn btn-icon-only btn-lg btn-circle d-none d-md-inline-block"><span
            class="fas fa-bars"></span>
        </button>
      </div>
      <!-- Navbar links -->
      <ul class="navbar-nav align-items-center" *ngIf="userDetails !== null">
        <li class="nav-item dropdown">
          <!-- <a class="nav-link text-dark me-lg-3 icon-notifications dropdown-toggle" data-unread-notifications="true"
            href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="icon icon-sm">
              <span class="fas fa-bell bell-shake"></span>
              <span class="icon-badge rounded-circle unread-notifications"></span>
            </span>
          </a> -->
          <div class="dropdown-menu dashboard-dropdown dropdown-menu-lg dropdown-menu-end mt-2 py-0">
            <div class="list-group list-group-flush">
              <a href="#" class="text-center text-primary fw-bold border-bottom border-light py-3">Notifications</a>
              <a href="../../pages/calendar.html"
                class="list-group-item list-group-item-action border-bottom border-light">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img alt="Image placeholder" src="../../assets/img/team/profile-picture-1.jpg"
                      class="user-avatar lg-avatar rounded-circle" style="object-fit: cover;">
                  </div>
                  <div class="col ps-0 ms-2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="h6 mb-0 text-small">Jose Leos</h4>
                      </div>
                      <div class="text-end">
                        <small class="text-danger">a few moments ago</small>
                      </div>
                    </div>
                    <p class="font-small mt-1 mb-0">Added you to an event "Project stand-up" tomorrow at 12:30 AM.</p>
                  </div>
                </div>
              </a>
              <a href="../../pages/tasks.html"
                class="list-group-item list-group-item-action border-bottom border-light">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img alt="Image placeholder" src="../../assets/img/team/profile-picture-2.jpg"
                      class="user-avatar lg-avatar rounded-circle">
                  </div>
                  <div class="col ps-0 ms-2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="h6 mb-0 text-small">Neil Sims</h4>
                      </div>
                      <div class="text-end">
                        <small class="text-danger">2 hrs ago</small>
                      </div>
                    </div>
                    <p class="font-small mt-1 mb-0">You've been assigned a task for "Awesome new project".</p>
                  </div>
                </div>
              </a>
              <a href="../../pages/tasks.html"
                class="list-group-item list-group-item-action border-bottom border-light">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img alt="Image placeholder" src="../../assets/img/team/profile-picture-3.jpg"
                      class="user-avatar lg-avatar rounded-circle">
                  </div>
                  <div class="col ps-0 m-2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="h6 mb-0 text-small">Roberta Casas</h4>
                      </div>
                      <div class="text-end">
                        <small>5 hrs ago</small>
                      </div>
                    </div>
                    <p class="font-small mt-1 mb-0">Tagged you in a document called "First quarter financial plans",</p>
                  </div>
                </div>
              </a>
              <a href="../../pages/single-message.html"
                class="list-group-item list-group-item-action border-bottom border-light">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img alt="Image placeholder" src="../../assets/img/team/profile-picture-4.jpg"
                      class="user-avatar lg-avatar rounded-circle">
                  </div>
                  <div class="col ps-0 ms-2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="h6 mb-0 text-small">Joseph Garth</h4>
                      </div>
                      <div class="text-end">
                        <small>1 d ago</small>
                      </div>
                    </div>
                    <p class="font-small mt-1 mb-0">New message: "Hey, what's up? All set for the presentation?"</p>
                  </div>
                </div>
              </a>
              <a href="../../pages/single-message.html"
                class="list-group-item list-group-item-action border-bottom border-light">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <img alt="Image placeholder" src="../../assets/img/team/profile-picture-5.jpg"
                      class="user-avatar lg-avatar rounded-circle">
                  </div>
                  <div class="col ps-0 ms-2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="h6 mb-0 text-small">Bonnie Green</h4>
                      </div>
                      <div class="text-end">
                        <small>2 hrs ago</small>
                      </div>
                    </div>
                    <p class="font-small mt-1 mb-0">New message: "We need to improve the UI/UX for the landing page."
                    </p>
                  </div>
                </div>
              </a>
              <a href="#" class="dropdown-item text-center text-primary fw-bold rounded-bottom py-3">View all</a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle pt-1 px-0" role="button" data-bs-toggle="dropdown"
            aria-expanded="true">
            <div class="media align-items-center">
              <img *ngIf="userDetails?.photoUrl" [src]="userDetails.photoUrl"
                class="user-avatar md-avatar rounded-circle" alt="Image placeholder">
            </div>
          </a>
          <div class="dropdown-menu dashboard-dropdown dropdown-menu-end mt-2 py-0">
            <a href="javascript:void()" class="dropdown-item rounded-top fw-bold"><span
                class="far fa-user-circle"></span>{{userDetails?.name}}</a>
            <a href="javascript:void(0)" class="dropdown-item fw-bold"><span class="fas fa-envelope"></span>{{
              userDetails?.email }}</a>
            <a href="javascript:void(0)" class="dropdown-item fw-bold"><span class="fas fa-phone"></span>{{
              userDetails?.phone }}</a>
            <a href="javascript:void()" (click)="redirectToUpdate()" class="dropdown-item rounded-top fw-bold"><span
                class="fas fa-cog"></span>Settings</a>
            <div role="separator" class="dropdown-divider my-0"></div>
            <a class="dropdown-item rounded-bottom fw-bold" href="javascript: void()"
              (click)="authService.SignOut()"><span class="fas fa-sign-out-alt text-danger"></span>Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>