import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Taskdoc } from 'src/app/shared/models/taskdoc.model';
import { Tasks } from 'src/app/shared/models/tasks.model';
import { CommService } from 'src/app/shared/services/comm.service';
import { NotyfService } from 'src/app/shared/services/notyf.service';
import { SwalService } from 'src/app/shared/services/swal.service';
import { TaskService } from 'src/app/shared/services/task.service';

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss']
})
export class TaskOverviewComponent implements OnInit {
  public tasks: Taskdoc[] = [];
  public taskFilter = '';
  public statusFilter = '';
  public startIndex = 0;
  public numberOfEntries = 10;
  public filteredArrayLength = 0;
  public taskModalButton = 'Add task';

  // Add or Edit task data
  public docId = '';
  public title = '';
  public status = '';
  public description = '';
  public endDate = '';
  public endTime = '';

  constructor(private taskService: TaskService,
              private notyf: NotyfService,
              private comm: CommService,
              private datePipe: DatePipe,
              private swalService: SwalService) { }

  ngOnInit(): void {
    this.taskService.getTasks().subscribe((value) => {
      this.tasks = value;
    });
    this.comm.taskFilteredLength.subscribe((value) => {
      this.filteredArrayLength = value;
      if (this.numberOfEntries >= value) {
        this.startIndex = 0;
      }
    });
  }

  displayEndIndexValue(): number {
    const x = this.startIndex + this.numberOfEntries;
    return (this.filteredArrayLength <= (x)) ? this.filteredArrayLength : x;
  }

  changeStatusFilter(value): void {
    this.statusFilter = this.statusFilter == value ? '' : value;
  }

  nextButtonDisable(): boolean {
    return (this.filteredArrayLength <= (this.startIndex + this.numberOfEntries)) ? true : false;
  }

  prevButtonClicked(): void {
    this.startIndex -= this.numberOfEntries;
  }

  nextButtonClicked(): void {
    this.startIndex += this.numberOfEntries;
  }

  openNewTaskModel(): void {
    this.docId = '';
    this.title = '';
    this.status = '';
    this.description = '';
    this.endDate = '';
    this.endTime = '';
    this.taskModalButton = 'Add task';
  }

  openEditTaskModel(val: Tasks, docId): void {
    this.docId = docId;
    this.title = val.title;
    this.status = val.status;
    this.description = val.description;
    this.endDate = this.datePipe.transform(val.endDateTime, 'YYYY-MM-dd');
    this.endTime = this.datePipe.transform(val.endDateTime, 'HH:mm');
    this.taskModalButton = 'Edit task';
  }

  deleteTask(docId): void {
    this.swalService.footaskdeleteswal(
      (val: boolean) => {
        this.taskService.removeTask(docId);
      }
    );
  }

  addOrEditTask(): void {
    if (this.allDataIsValid()) {
      this.taskService.updateTask(this.docId, {
        userId: '',
        title: this.title,
        description: this.description,
        endDateTime: new Date(this.endDate + ' ' + this.endTime),
        status: this.status
      })
        .then(() => {
          if (this.taskModalButton == 'Add task') {
            this.notyf.success('Task created');
          }
          else {
            this.notyf.success('Task updated');
          }
        })
        .catch((error) => {
          this.notyf.error(error);
        });
    } else {
      if (this.taskModalButton == 'Add task') {
        this.notyf.warning('Task not added');
      }
      else {
        this.notyf.warning('Task not updated');
      }
    }
  }

  allDataIsValid(): boolean {
    if (!this.title) { return false; }
    if (!this.status) { return false; }
    if (!this.description) { return false; }
    if (!this.endDate) { return false; }
    if (!this.endTime) { return false; }
    return true;
  }

}
