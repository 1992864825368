import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomTags } from 'src/app/shared/models/custom-tags.model';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit, OnChanges {
  @Input() MODE: 'CREATE' | 'EDIT' | 'VIEW';
  @Input() editData: {
    docId: number;
    data: CustomTags;
  };
  @Input() tagsData: CustomTags[] = [];

  @Output() modeChanged = new EventEmitter<'CREATE' | 'EDIT' | 'VIEW'>();
  @Output() outputData = new EventEmitter<{
    docId: number;
    data: CustomTags;
  }>();
  public filter = '';
  public submitButtonText = 'Create';

  public tagsForm: FormGroup;

  constructor() {
    this.tagsForm = new FormGroup({
      tagName: new FormControl('', Validators.required),
      tagDescription: new FormControl(''),
      status: new FormControl('active', Validators.required),
    });

    this.TagName.valueChanges.subscribe((value) => {
      if (value != '') {
        if (this.nameAlreadyExists(value)) {
          this.TagName.setErrors({ notUnique: true });
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializationActions();
  }

  ngOnInit(): void {
    this.initializationActions();
  }

  initializationActions(): void {
    if (this.MODE == 'CREATE') {
      this.TagName.setValue('');
      this.TagDescription.setValue('');
      this.submitButtonText = 'Create';
    } else if (this.MODE == 'EDIT') {
      this.TagName.setValue(this.editData.data.Name);
      this.TagDescription.setValue(this.editData.data.Description);
      this.Status.setValue(this.editData.data.Status);
      this.submitButtonText = 'Edit';
    }
  }

  nameAlreadyExists(name: string): boolean {
    if (
      this.MODE == 'EDIT' &&
      this.editData.data.Name.toLowerCase() == name.toLowerCase()
    ) {
      return false;
    }
    for (const item of this.tagsData) {
      if (item.Name.toLowerCase() == name.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  editTag(value: CustomTags, index: number) {
    this.outputData.emit({
      docId: index,
      data: value,
    });
  }

  submitData() {
    this.submitButtonText = 'Loading';
    let x = this.tagsData.length;
    if (this.MODE == 'EDIT') {
      x = this.editData.docId;
    }
    this.outputData.emit({
      docId: x,
      data: {
        Name: this.TagName.value,
        Description: this.TagDescription.value,
        CreatedOn: new Date(),
        Status: this.Status.value,
      },
    });
  }

  viewTags() {
    this.modeChanged.emit('VIEW');
  }

  newTag() {
    this.modeChanged.emit('CREATE');
  }

  // formDetails getters
  get TagName() {
    return this.tagsForm.get('tagName');
  }

  get TagDescription() {
    return this.tagsForm.get('tagDescription');
  }

  get Status() {
    return this.tagsForm.get('status');
  }
}
