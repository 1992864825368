<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3">
  <div class="d-block mb-4 mb-md-0">
    <h5 class="mb-0">
      <span class="sidebar-icon material-icons-outlined">
        contacts
      </span>
      <span class="heading-text" style="font-size: 20px !important;">Contacts</span>
    </h5>
    <p style="font-size: 13px !important;">Saved contacts list.</p>
  </div>
  <div class="btn-toolbar mb-2 mb-md-0">
    <button class="btn btn-dark" type="button" style="font-size: 14px;" data-bs-toggle="modal"
      data-bs-target="#KanbanAddCard" (click)="openNewContactModel()">
      <span class="fas fa-plus me-2"></span> New User
    </button>
    <!-- <a href="#" class="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#KanbanAddCard"
      (click)="openNewContactModel()"><span class="fas fa-plus me-2"></span> New User</a> -->
    <!-- <div class="btn-group ms-2 ms-lg-3">
      <button type="button" class="btn btn-sm btn-outline-primary">Share</button>
      <button type="button" class="btn btn-sm btn-outline-primary">Export</button>
    </div> -->
  </div>
</div>
<div class="table-settings mb-3">
  <div class="row justify-content-between align-items-center">
    <div class="col-lg-4 d-flex">
      <div class="input-group">
        <input type="text" class="input_custom_design form-control" placeholder="Search" [(ngModel)]="contactFilter">
      </div>
    </div>
    <div class="col-lg-2 d-flex">
      <select class="input_custom_design form-select " aria-label="Message select example 2" [(ngModel)]="groupFilter">
        <option value='' selected>All</option>
        <option value="Business">Business</option>
        <option value="Personal">Personal</option>
        <option value="Starred">Starred</option>
        <option value="Miscellaneous">Miscellaneous</option>
      </select>
    </div>
    <div class="col-lg-6 text-right">
      <div class="btn-group me-1">
        <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-1" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <span class="icon icon-sm icon-gray pt-1">
            <span class="fas fa-sliders-h"></span>
          </span>
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end pb-0">
          <span class="small ps-3 fw-bold text-dark">Show</span>
          <a class="dropdown-item d-flex fw-bold" href="javascript:void()" (click)="numberOfEntries = 10">10
            <span *ngIf="numberOfEntries == 10" class="icon icon-small ms-auto">
              <span class="fas fa-check"></span>
            </span>
          </a>
          <a class="dropdown-item fw-bold" href="javascript:void()" (click)="numberOfEntries = 20">20
            <span *ngIf="numberOfEntries == 20" class="icon icon-small ms-auto">
              <span class="fas fa-check"></span>
            </span>
          </a>
          <a class="dropdown-item fw-bold rounded-bottom" href="javascript:void()" (click)="numberOfEntries = 30">30
            <span *ngIf="numberOfEntries == 30" class="icon icon-small ms-auto">
              <span class="fas fa-check"></span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card card-body p-0 table-wrapper table-responsive mb-5" style="border-radius: 0.5rem !important;">
  <table class="table user-table table-hover align-items-center" style="overflow: hidden;">
    <thead>
      <tr style=" height: 50px;">
        <!-- <th class="border-bottom">
          <div class="form-check dashboard-check">
            <input class="form-check-input" type="checkbox" value="" id="userCheck55">
            <label class="form-check-label" for="userCheck55">
            </label>
          </div>
        </th> -->
        <th class="border-bottom">Name & Email</th>
        <th class="border-bottom">Phone Number</th>
        <th class="border-bottom">Address</th>
        <th class="border-bottom">Group</th>
        <th class="border-bottom">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let c of (contacts | contactIndexing : ['name', 'phoneNumber', 'email'] : contactFilter : groupFilter : startIndex : numberOfEntries);">
        <!-- <td>
          <div class="form-check dashboard-check">
            <input class="form-check-input" type="checkbox" value="">
            <label class="form-check-label">
            </label>
          </div>
        </td> -->
        <td>
          <a href="javascript:void()" class="d-flex align-items-center">
            <img src="../../../assets/img/default-user-avatar-300x293.png" class="user-avatar rounded-circle me-3"
              alt="Avatar">
            <div class="d-block">
              <span class="fw-bold">{{c.contactData.name}}</span>
              <div class="small text-gray">{{c.contactData.email}}</div>
            </div>
          </a>
        </td>
        <td><span class="fw-normal">{{c.contactData.phoneNumber}}</span></td>
        <td>
          <p class="fw-normal mb-0">{{c.contactData.address}}</p>
        </td>
        <td><span class="fw-normal">
            <i *ngIf="c.contactData.group == 'Business'" class="fas fa-briefcase"></i>
            <i *ngIf="c.contactData.group == 'Personal'" class="fas fa-user-friends"></i>
            <i *ngIf="c.contactData.group == 'Starred'" class="fas fa-star"></i>
            <i *ngIf="c.contactData.group == 'Miscellaneous'" class="fas fa-asterisk"></i>
            {{c.contactData.group}}</span></td>
        <td>
          <!-- <span class="fas fa-trash text-danger ms-2" title="Delete" data-bs-toggle="tooltip"
            (click)="deleteContact(c.docId)">
          </span> -->

          <div class="btn-group">
            <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="icon icon-sm pt-1">
                <span class="fas fa-ellipsis-h icon-dark"></span>
              </span>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu py-0">
              <a *ngIf="c.contactData.group != 'Starred'" class="dropdown-item rounded-top" href="javascript:void()"
                (click)='setContactAsStarred(c)'>
                <span class="fas fa-user-shield me-2"></span>Set as Starred</a>
              <a class="dropdown-item" href="javascript:void()" (click)="openEditContactModel(c.contactData, c.docId)"
                data-bs-toggle="modal" data-bs-target="#KanbanAddCard">
                <span class="fas fa-edit me-2"></span>Edit Contact</a>
              <a class="dropdown-item" href="javascript:void()" (click)="deleteContact(c.docId)" data-bs-toggle="modal"
                data-bs-target="#KanbanAddCard">
                <span class="fas fa-trash me-2"></span>Delete Contact</a>
            </div>
          </div>

        </td>
      </tr>
    </tbody>
  </table>

  <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
    <nav aria-label="Page navigation example">
      <ul class="pagination mb-0">
        <li class="page-item">
          <a class="page-link" href="javascript:void()" [class.disabled]="startIndex == 0"
            (click)="prevButtonClicked()">Previous</a>
        </li>
        <li class="page-item" [class.active]="1 == activePage">
          <a class="page-link" href="javascript:void()" (click)="pageEntryClicked(1)">1</a>
        </li>
        <li *ngIf="pageEntryDisable(pageEntry)" class="page-item" [class.active]="(pageEntry+1)== activePage">
          <a class="page-link" href="javascript:void()" (click)="pageEntryClicked(pageEntry+1)">{{pageEntry+1}}</a>
        </li>
        <li *ngIf="pageEntryDisable(pageEntry+1)" class="page-item" [class.active]="(pageEntry+2)== activePage">
          <a class="page-link" href="javascript:void()" (click)="pageEntryClicked(pageEntry+2)">{{pageEntry+2}}</a>
        </li>
        <li *ngIf="pageEntryDisable(pageEntry+2)" class="page-item" [class.active]="(pageEntry+3)== activePage">
          <a class="page-link" href="javascript:void()" (click)="pageEntryClicked(pageEntry+3)">{{pageEntry+3}}</a>
        </li>
        <li *ngIf="pageEntryDisable(pageEntry+3)" class="page-item" [class.active]="(pageEntry+4)== activePage">
          <a class="page-link" href="javascript:void()" (click)="pageEntryClicked(pageEntry+4)">{{pageEntry+4}}</a>
        </li>
        <li class="page-item">
          <a class="page-link" href="javascript:void()" [class.disabled]="nextButtonDisable()"
            (click)="nextButtonClicked()">Next</a>
        </li>
      </ul>
    </nav>
    <div class="fw-normal small mt-4 mt-lg-0">Showing <b>{{displayEndIndexValue()}}</b> out of
      <b>{{filteredArrayLength}}</b> entries
    </div>
  </div>

</div>





<div class="modal fade" id="KanbanAddCard" tabindex="-1" aria-labelledby="KanbanAddCardLabel4" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{contactModalButton}}
        </h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div class="modal-header pl-2 pr-2 pt-3">
        <h5 class="mb-0">
          <span class="sidebar-icon material-icons-outlined">
            contact_mail
          </span>
          <span class="heading-text" style="font-size: 20px !important;"> {{contactModalButton}}</span>
          <br>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
      <form #addoreditForm="ngForm">
        <div class="modal-body">
          <div class="mb-2">
            <label>Contact Name <span class="text-danger">*</span></label>
            <input type="text" class="input_custom_design form-control" id="name" name="name"
              [class.is-invalid]="name.length == 0" [class.is-valid]="name.length > 0" autofocus required
              [(ngModel)]="name">
          </div>
          <div class="row align-items-center">
            <div class="col-md-6 mb-2">
              <label>Phone Number <span class="text-danger">*</span></label>
              <input type="text" mask="(000) 000-0000" class="input_custom_design form-control" id="phone" name="phone"
                [(ngModel)]="phoneNumber" [class.is-invalid]="phoneNumber.length == 0"
                [class.is-valid]="phoneNumber.length > 0" required>
            </div>
            <div class="col-md-6 mb-2">
              <label>Contact Type <span class="text-danger">*</span></label>
              <select class="input_custom_design form-select mb-0" id="group" name="group" [(ngModel)]="group"
                [class.is-invalid]="group.length == 0" [class.is-valid]="group.length > 0" required>
                <option value='' selected>Type</option>
                <option value="Business">Business</option>
                <option value="Personal">Personal</option>
                <option value="Starred">Starred</option>
                <option value="Miscellaneous">Miscellaneous</option>
              </select>
            </div>
          </div>
          <div class="mb-2">
            <label>Email Address </label>
            <input type="email" class="input_custom_design form-control" id="email" name="email" [(ngModel)]="email"
              [class.is-invalid]="email.length == 0" [class.is-valid]="email.length > 0">
          </div>
          <div class="mb-2">
            <label>Contact Address </label>
            <textarea class="input_custom_design form-control" id="address" rows="3" name="address"
              [(ngModel)]="address"></textarea>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0 justify-content-end">
          <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-success" data-bs-dismiss="modal" [disabled]="!addoreditForm.valid"
            (click)="addOrEditContact()">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>