<form  [formGroup]="form" class="form-horizontal">
    <div *ngFor="let field of fields"> 
     <!--    <app-form-fields [options]="field" [form]="form" >
        </app-form-fields> -->
    </div> 
</form>
<div *ngIf="uploadedFiles.length >0">
    <div  *ngFor="let item of uploadedFiles">
        <span (click)="cropImage(item.imgid)">
        <img [src]="item.imgValue" height="100px" width="100px" class="img-list" />
        </span> 
    </div>
    <image-cropper
        [maintainAspectRatio]="true"
        [imageBase64] = "originalImage"
        [containWithinAspectRatio]="true"
        [resizeToWidth]="1000"
        [cropperMinWidth]="100"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="0"
        [alignImage]="'left'"
        [style.display]="null"
        format="png"
        (imageCropped)="imageCropped($event)">
    </image-cropper>
</div>
<div *ngIf="croppedImage.length >0">
    <img [src]="croppedImage" height="200px" width="200px" class="img-list" />
</div>