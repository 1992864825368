import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  transform(data: Array<any>, filter: string): Array<any> {
    if (filter == '') { return data; }
    else {
      return data.filter((value: any) => {
        if (value.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          return value;
        }
      });
    }
  }

}
