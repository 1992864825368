import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CustomFont } from 'src/app/shared/models/custom-font.model';

@Component({
  selector: 'app-text-overlay',
  templateUrl: './text-overlay.component.html',
  styleUrls: ['./text-overlay.component.scss']
})
export class TextOverlayComponent implements OnInit {
  @Input() data: any;
  @Input() font: CustomFont;

  constructor() { }

  ngOnInit(): void {
    // this.addFont(this.data.fontUrl);
  }

  calcPtToPx(val): number {
    return Number(val * 1.3333333);
  }

  /* addFont(fontUrl: string, callback?) {
    if (fontUrl) {
      const link = document.createElement('link');
      link.href = fontUrl;
      link.rel = 'stylesheet';
      document.body.appendChild(link).onload = () => {
        if (callback) {
        callback();
        }
      };
    }
  } */
  PSLetterSpacing(value): number {
    return value / 1000;
  }

}
