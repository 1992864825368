<div class="overlay" *ngFor="let texts of settings.textSettings.models; let i=index;" [style.width.px]="sizeData?.Width"
  [style.height.px]="sizeData?.Height" contenteditable="true">
  <!-- <app-editable-text class="overlay-bounds" [style.left.px]="texts.left ? texts.left : ''"
    [style.top.px]="texts.top ? texts.top : ''" (onResized)="setHeightAndWidth($event)"
    [appMoveClampedToParent]="settings.isWkFlow" *ngIf="texts.isSelected" [elementId]="i"
    [style.height.px]="texts.height" [style.width.px]="texts.width" [model]="texts" [font]="getFont(texts)"
    [options]="settings.textSettings.options"
    [ngClass]="{ selected: texts.uniqueId === settings.selectedModelUniqueId }"
    (mousedown)="onSelected(texts.uniqueId)"></app-editable-text> -->

  <app-editable-text class="overlay-bounds" [style.left.px]="texts.left ? texts.left : ''"
    [style.top.px]="texts.top ? texts.top : ''" [appMoveClampedToParent]="settings.isWkFlow" *ngIf="texts.isSelected"
    [elementId]="i" [style.height.px]="texts.height" [style.width.px]="texts.width" [model]="texts"
    [font]="getFont(texts)" [options]="settings.textSettings.options"
    [ngClass]="{ selected: texts.uniqueId === settings.selectedModelUniqueId }"
    (mousedown)="onSelected(texts.uniqueId)"></app-editable-text>
</div>