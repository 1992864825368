import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { CustomFont } from '../models/custom-font.model';

@Injectable({
  providedIn: 'root'
})
export class FontManagerService {

  constructor(private db: AngularFireDatabase) { }

  getFonts() {
    return this.db.list<CustomFont>('/customFonts')
      .snapshotChanges()
      .pipe(map(snaps => {
        return snaps.map(snap => {
          return snap.payload.val();/* {
            docId: snap.payload.key,
            data: snap.payload.val()
          } as {
            docId: string;
            data: CustomFont
          }; */
        });
      })) ;
  }

  addOrUpdateFont(docId, value: CustomFont) {
    return this.db.object('/customFonts/' + docId).update(value);
  }
}
