<!-- Tab Nav -->
<div class="container">
  <div class="d-block mb-4 mb-md-0 pt-3">
    <div class="row">
      <div class="col-md-5">
        <h5 class="mb-0">
          <span class="sidebar-icon material-icons-outlined">
            toggle_on
          </span>
          <span class="heading-text" style="font-size: 20px !important;">{{config}}</span>
        </h5>
        <p style="font-size: 13px !important;">Manage your application templates</p>
      </div>

      <div class="col-md-7">
        <ul class="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-text" role="tablist" style="float: right;">
          <li class="nav-item">
            <a class="nav-link mb-sm-3 mb-md-0 active" id="tabs-text-1-tab" data-bs-toggle="tab" href="#tabs-text-1"
              role="tab" aria-controls="tabs-text-1" aria-selected="true"
              (click)="config='Font Configuration'">Fonts</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-sm-3 mb-md-0" id="tabs-text-2-tab" data-bs-toggle="tab" href="#tabs-text-2" role="tab"
              aria-controls="tabs-text-2" aria-selected="false" (click)="config='Template Configuration'">Templates
              Sizes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mb-sm-3 mb-md-0" id="tabs-text-3-tab" data-bs-toggle="tab" href="#tabs-text-3" role="tab"
              aria-controls="tabs-text-3" aria-selected="false" (click)="config='Tags Management'">Tags</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="nav-wrapper position-relative mb-2 p-0">
    <!-- <ul class="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-text" role="tablist">
      <li class="nav-item">
        <a class="nav-link mb-sm-3 mb-md-0 active" id="tabs-text-1-tab" data-bs-toggle="tab" href="#tabs-text-1"
          role="tab" aria-controls="tabs-text-1" aria-selected="true">Fonts</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-sm-3 mb-md-0" id="tabs-text-2-tab" data-bs-toggle="tab" href="#tabs-text-2" role="tab"
          aria-controls="tabs-text-2" aria-selected="false">Templates Sizes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-sm-3 mb-md-0" id="tabs-text-3-tab" data-bs-toggle="tab" href="#tabs-text-3" role="tab"
          aria-controls="tabs-text-3" aria-selected="false">Tags</a>
      </li>
    </ul> -->
  </div>
  <!-- End of Tab Nav -->
  <!-- Tab Content -->
  <div class="card border-0">
    <div class="card-body p-0">
      <div class="tab-content" id="tabcontent1">
        <div class="tab-pane fade show active" id="tabs-text-1" role="tabpanel" aria-labelledby="tabs-text-1-tab">
          <app-fonts [MODE]="fontConfigMODE" [fontsData]="fontsInputData" [editData]="fontEditData"
            (modeChanged)="fontConfigModeChanged($event)" (outputData)="fontOutputData($event)"></app-fonts>
        </div>
        <div class="tab-pane fade" id="tabs-text-2" role="tabpanel" aria-labelledby="tabs-text-2-tab">
          <app-post-types [MODE]="postTypeConfigMODE" [postTypesData]="postTypesInputData" [editData]="postTypeEditData"
            (modeChanged)="postTypeConfigModeChanged($event)" (outputData)="postTypeOutputData($event)">
          </app-post-types>
        </div>
        <div class="tab-pane fade" id="tabs-text-3" role="tabpanel" aria-labelledby="tabs-text-3-tab">
          <app-tags [MODE]="tagConfigMODE" [tagsData]="tagsInputData" [editData]="tagEditData"
            (modeChanged)="tagConfigModeChanged($event)" (outputData)="tagOutputData($event)"></app-tags>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Tab Content -->
</div>