import { Component, OnInit } from '@angular/core';
import { NotyfService } from 'src/app/shared/services/notyf.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  public users = [];
  public filter = '';

  constructor(
    private udService: UserDetailsService,
    private notyf: NotyfService
  ) {}

  ngOnInit(): void {
    this.udService.getAllUsers().subscribe((result) => {
      console.log(result);
      this.users = result;
    });
  }

  setAsAdmin(userInfo: any) {
    if (confirm('Do you want to give this user Admin Privileges?')) {
      userInfo.userData.isAdmin = true;
      this.udService
        .UpdateUserData(userInfo.docId, userInfo.userData)
        .then((res) => {
          //console.log(res);
          this.notyf.success(userInfo.userData.displayName + 'is now an Admin');
        })
        .catch((error) => {
          //console.log(error);
          this.notyf.error('Action Failed');
        });
    }
  }

  removeAdmin(userInfo: any) {
    if (confirm("Do you want to remove this user's Admin Privileges?")) {
      userInfo.userData.isAdmin = false;
      this.udService
        .UpdateUserData(userInfo.docId, userInfo.userData)
        .then((res) => {
          //console.log(res);
          this.notyf.success(userInfo.userData.displayName + 'is now an Admin');
        })
        .catch((error) => {
          //console.log(error);
          this.notyf.error('Action Failed');
        });
    }
  }
}
