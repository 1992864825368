import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { NotyfService } from '../shared/services/notyf.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [
      Validators.pattern(/([A-Za-z0-9\!\@\#\$\%\^\&\*\?\.\_\+\=\-])\w+/),
      Validators.required,
    ]),
  });

  hint = false;
  passwordHide = true;
  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private notyf: NotyfService,
    public router: Router
  ) {
    this.renderer.addClass(document.body, 'side_menu_hide');
    this.renderer.removeClass(document.body, 'side_menu_show');
  }

  redirectToHome() {
    if (this.Email.value.length > 0 && this.Password.value.length > 0) {
      this.hint = true;
      this.authService
        .SignIn(this.Email.value, this.Password.value)
        .then((result) => {
          this.authService.SetUserData(result.user).then((value: any) => {
            this.hint = false;
            if (value.verify && value.status) {
              //debugger
              // sessionStorage.setItem('user', JSON.stringify(value));
              this.router.navigateByUrl('/home');
              this.notyf.info('Logged In');
            } else if (!value.verify) {
              this.notyf.info(
                'Your account under verification, If its taking more time please contact administration'
              );
            } else if (!value.status) {
              this.notyf.error(
                'Your account has been blocked by administrator'
              );
            }
          });
        })
        .catch((error) => {
          this.hint = false;
          this.notyf.error(error);
        });
    } else {
      this.notyf.error('Please enter valid email address and password');
    }
  }

  ngOnDestroy() {
    this.renderer.addClass(document.body, 'side_menu_show');
    this.renderer.removeClass(document.body, 'side_menu_hide');
  }

  public get Email() {
    return this.loginForm.get('email');
  }

  public get Password() {
    return this.loginForm.get('password');
  }
}
